const constants = {
  BEARER_TOKEN: 'INCIDENT_REPORTER_TOKEN',
  LOGGED_IN_USER_ID: 'INCIDENT_REPORTER_LOGGED_IN_USER_ID',
  REGISTERED_ORG_DETAILS: 'REGISTERED_ORG_DETAILS',
  CURRENT_USER_DETAILS: 'CURRENT_USER_DETAILS',
  CURRENT_SUBSCRIPTION: 'CURRENT_SUBSCRIPTION',
  DIVISIONS: 'DIVISIONS',
  SELECTED_ASSET_TYPE: 'SELECTED_ASSET_TYPE',
  HIGHEST_SUBSCRIPTION: 'HIGHEST_SUBSCRIPTION',
  PAYMENT_IN_PROGRESS_DETAILS: 'PAYMENT_IN_PROGRESS_DETAILS',
  PREFERRED_CURRENCY: 'PREFERRED_CURRENCY',
  IS_ORGANISATION_OWNER: 'IS_ORGANISATION_OWNER',
  INCIDENT_FILTER_PREFERENCES: 'INCIDENT_FILTER_PREFERENCES',
  HEATMAP_FILTER_PREFERENCES: 'HEATMAP_FILTER_PREFERENCES',
  FIELD_STAFF_MAP_FILTER_PREFERENCES: 'FIELD_STAFF_MAP_FILTER_PREFERENCES',
  USER_FILTER_PREFERENCES: 'USER_FILTER_PREFERENCES',
  DASHBOARD_FILTER_PREFERENCES: 'DASHBOARD_FILTER_PREFERENCES',
  APPOINTMENT_FILTER_PREFERENCES: 'APPOINTMENT_FILTER_PREFERENCES',
  USERS_FILTER_PREFERENCES: 'USERS_FILTER_PREFERENCES',
  ASSET_FILTER_PREFERENCES: 'ASSET_FILTER_PREFERENCES',
  BATCH_FILTER_PREFERENCES: 'BATCH_FILTER_PREFERENCES',
  SETUP_FILTER_PREFERENCES: 'SETUP_FILTER_PREFERENCES',
  REPORT1_FILTER_PREFERENCES: 'REPORT1_FILTER_PREFERENCES',
  REPORT2_FILTER_PREFERENCES: 'REPORT2_FILTER_PREFERENCES',
  REPORT3_FILTER_PREFERENCES: 'REPORT3_FILTER_PREFERENCES',
  REPORT4_FILTER_PREFERENCES: 'REPORT4_FILTER_PREFERENCES',
  INCIDENT_LIST_FOR_NAV: 'INCIDENT_LIST_FOR_NAV',
  USER_LIST_FOR_NAV: 'USER_LIST_FOR_NAV',
  INCIDENTS_API_CURRENT_FILTER: 'INCIDENTS_API_CURRENT_FILTER',
  USERS_API_CURRENT_FILTER: 'USERS_API_CURRENT_FILTER',
  NOTIFICATION_LIST_FOR_NAV: 'NOTIFICATION_LIST_FOR_NAV',
  NOTIFICATION_API_CURRENT_FILTER: 'NOTIFICATION_API_CURRENT_FILTER',
  APP_LANGUAGE: 'APP_LANGUAGE',
  USER_INCIDENT_SITES: 'USER_INCIDENT_SITES',
  PORTAL_USER_DATA: 'PORTAL_USER_DATA',
  ORG_OWNER_USER_ID: 'ORG_OWNER_USER_ID',
  DATA_TABLE_STATES: {
    INCIDENTS: 'DATA_TABLE_STATE_INCIDENTS',
    APPOINTMENTS: 'DATA_TABLE_STATE_APPOINTMENTS',
    USERS: 'DATA_TABLE_STATE_USERS',
    ASSETS: 'DATA_TABLE_STATE_ASSETS',
    STAFF: 'DATA_TABLE_STATE_STAFF',
    ORGANISATION: 'DATA_TABLE_STATE_ORGANISATION',
    PORTAL: 'DATA_TABLE_STATE_PORTAL',
    SETUP: 'DATA_TABLE_STATE_SETUP',
    BILLING: 'DATA_TABLE_STAFF_BILLING',
    NOTIFICATIONS: 'DATA_TABLE_STAFF_NOTIFICATIONS',
    SITES: 'DATA_TABLE_STAFF_SITES',
    BATCHES: 'DATA_TABLE_STATE_BATCHES',
    COURSES: 'DATA_TABLE_STATE_COURSES',
    SUPPORT: 'DATA_TABLE_STATE_SUPPORT',
  },
  HEATMAP_DEFAULTS: {
    ZOOM_LEVEL: 5,
  },
  FIELD_STAFF_MAP_DEFAULTS: {
    ZOOM_LEVEL: 13,
  },
  QUESTION_TYPES: {
    FREE_TEXT: 'FREETEXT',
    MCQ: 'MCQ',
  },
  CONTENT_TYPES: {
    URL: 'URL',
    FILE: 'File',
  },
  STORAGE_KEYS: {
    ORGANISATIONS: 'ORGANISATIONS',
    CURRENT_GLOBAL_ORGANISATION: 'CURRENT_GLOBAL_ORGANISATION',
    COUNTRIES: 'COUNTRIES',
    PET_TYPES: 'PET_TYPES',
    GENDER_TYPES: 'GENDER_TYPES',
    DIVISIONS: 'DIVISIONS',
    INCIDENT_LOCATIONS: 'INCIDENT_LOCATIONS',
    LOOKUP: 'LOOKUP',
  },
  GEOCODING_URL: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  MAPS_API_KEY: 'AIzaSyAJh0tQ71dPRiLjmUbhfVP-HQHVdiDOZYE',
  SUPPORTED_COUNTRIES: [
    {
      dialCode: '61',
      name: 'Australia',
      iso2: 'au',
    },
    {
      dialCode: '43',
      name: 'Austria (Österreich)',
      iso2: 'at',
    },
    {
      dialCode: '32',
      name: 'Belgium (België)',
      iso2: 'be',
    },
    {
      dialCode: '86',
      name: 'China (中国)',
      iso2: 'cn',
    },
    {
      dialCode: '45',
      name: 'Denmark (Danmark)',
      iso2: 'dk',
    },
    {
      dialCode: '358',
      name: 'Finland (Suomi)',
      iso2: 'fi',
    },
    {
      dialCode: '33',
      name: 'France',
      iso2: 'fr',
    },
    {
      dialCode: '49',
      name: 'Germany (Deutschland)',
      iso2: 'de',
    },
    {
      dialCode: '30',
      name: 'Greece (Ελλάδα)',
      iso2: 'gr',
    },
    {
      dialCode: '36',
      name: 'Hungary (Magyarország)',
      iso2: 'hu',
    },
    {
      dialCode: '91',
      name: 'India (भारत)',
      iso2: 'in',
    },
    {
      dialCode: '353',
      name: 'Ireland',
      iso2: 'ie',
    },
    {
      dialCode: '39',
      name: 'Italy (Italia)',
      iso2: 'it',
    },
    {
      dialCode: '356',
      name: 'Malta',
      iso2: 'mt',
    },
    {
      dialCode: '31',
      name: 'Netherlands (Nederland)',
      iso2: 'nl',
    },
    {
      dialCode: '47',
      name: 'Norway (Norge)',
      iso2: 'no',
    },
    {
      dialCode: '48',
      name: 'Poland (Polska)',
      iso2: 'pl',
    },
    {
      dialCode: '351',
      name: 'Portugal',
      iso2: 'pt',
    },
    {
      dialCode: '7',
      name: 'Russia (Россия)',
      iso2: 'ru',
    },
    {
      dialCode: '966',
      name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
      iso2: 'sa',
    },
    {
      dialCode: '34',
      name: 'Spain (España)',
      iso2: 'es',
    },
    {
      dialCode: '46',
      name: 'Sweden (Sverige)',
      iso2: 'se',
    },
    {
      dialCode: '971',
      name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
      iso2: 'ae',
    },
    {
      dialCode: '44',
      name: 'United Kingdom',
      iso2: 'gb',
    },
    {
      dialCode: '1',
      name: 'United States',
      iso2: 'us',
    },
  ],
  packageMonths: [
    {
      name: '1 month',
      value: 1,
    },
    {
      name: '2 months',
      value: 2,
    },
    {
      name: '3 months',
      value: 3,
    },
    {
      name: '4 months',
      value: 4,
    },
    {
      name: '5 months',
      value: 5,
    },
    {
      name: '6 months',
      value: 6,
    },
    {
      name: '7 months',
      value: 7,
    },
    {
      name: '8 months',
      value: 8,
    },
    {
      name: '9 months',
      value: 9,
    },
    {
      name: '10 months',
      value: 10,
    },
    {
      name: '11 months',
      value: 11,
    },
    {
      name: '1 year',
      value: 12,
    },
  ],
  currency: 'USD',
  currencyOptions: [
    {
      title: 'USD',
      value: 'USD',
    },
    {
      title: 'GBP',
      value: 'GBP',
    },
    {
      title: 'INR (Only for Indian customers)',
      value: 'INR',
    },
  ],
  imageExtensionsTypes: [
    {
      ext: '.apng',
    },
    {
      ext: '.png',
    },
    {
      ext: '.avif',
    },
    {
      ext: '.jpg',
    },
    {
      ext: '.gif',
    },
    {
      ext: '.jpeg',
    },
    {
      ext: '.jfif',
    },
    {
      ext: '.pjpeg',
    },
    {
      ext: '.pjp',
    },
    {
      ext: '.svg',
    },
    {
      ext: '.webp',
    },
    {
      ext: '.heic',
    },
    {
      ext: '.HEIC',
    },
    {
      ext: '.heif',
    },
    {
      ext: '.HEIF',
    },
  ],
  videoExtensionsTypes: [
    {
      ext: '.mp4',
    },
    {
      ext: '.mov',
    },
    {
      ext: '.wmv',
    },
    {
      ext: '.avi',
    },
    {
      ext: '.mkv',
    },
    {
      ext: '.webm',
    },
  ],
  audioExtensionsTypes: [
    {
      ext: '.3ga',
    },
    {
      ext: '.aac',
    },
    {
      ext: '.aiff',
    },
    {
      ext: '.ape',
    },
    {
      ext: '.arf',
    },
    {
      ext: '.gp4',
    },
    {
      ext: '.gp5',
    },
    {
      ext: '.mp3',
    },
    {
      ext: '.m4a',
    },
    {
      ext: '.aac',
    },
    {
      ext: '.mpeg',
    },
    {
      ext: '.wav',
    },
  ],
  fileExtensionsTypes: [
    {
      ext: '.msword',
    },
    {
      ext: '.doc',
    },
    {
      ext: '.DOC',
    },
    {
      ext: '.docx',
    },
    {
      ext: '.DOCX',
    },
    {
      ext: '.PDF',
    },
    {
      ext: '.pdf',
    },
    {
      ext: '.xls',
    },
    {
      ext: '.XLS',
    },
    {
      ext: '.xlsx',
    },
    {
      ext: '.XLSX',
    },
    {
      ext: '.ppt',
    },
    {
      ext: '.PPT',
    },
    {
      ext: '.pptx',
    },
    {
      ext: '.PPTX',
    },
    {
      ext: '.docm',
    },
    {
      ext: '.DOCM',
    },
    {
      ext: '.dotm',
    },
    {
      ext: '.DOTM',
    },
    {
      ext: '.xlsm',
    },
    {
      ext: '.XLSM',
    },
    {
      ext: '.XLTM',
    },
    {
      ext: '.xltm',
    },
    {
      ext: '.xlam',
    },
    {
      ext: '.XLAM',
    },
    {
      ext: '.PPTM',
    },
    {
      ext: '.pptm',
    },
    {
      ext: '.potm',
    },
    {
      ext: '.POTM',
    },
    {
      ext: '.PPAM',
    },
    {
      ext: '.ppam',
    },
    {
      ext: '.sldm',
    },
    {
      ext: '.SLDM',
    },
    {
      ext: '.odt',
    },
    {
      ext: '.ODT',
    },
    {
      ext: '.ods',
    },
    {
      ext: '.ODS',
    },
    {
      ext: '.txt',
    },
    {
      ext: '.TXT',
    },
    {
      ext: '.gif',
    },
    {
      ext: '.GIF',
    },
    {
      ext: '.tiff',
    },
    {
      ext: '.TIFF',
    },
    {
      ext: '.rar',
    },
    {
      ext: '.RAR',
    },
    {
      ext: '.zip',
    },
    {
      ext: '.csv',
    },
    {
      ext: '.CSV',
    },
    {
      ext: '.ODP',
    },
    {
      ext: '.odp',
    },
    {
      ext: '.dot',
    },
    {
      ext: '.DOT',
    },
    {
      ext: '.dotx',
    },
    {
      ext: '.DOTX',
    },
    {
      ext: '.XL',
    },
    {
      ext: '.xl',
    },
    {
      ext: '.xlsb',
    },
    {
      ext: '.XLSB',
    },
    {
      ext: '.xltx',
    },
    {
      ext: '.XLTX',
    },
    {
      ext: '.xlt',
    },
    {
      ext: '.XLT',
    },
    {
      ext: '.xla',
    },
    {
      ext: '.XLA',
    },
    {
      ext: '.xlm',
    },
    {
      ext: '.XLM',
    },
    {
      ext: '.xlw',
    },
    {
      ext: '.XLW',
    },
    {
      ext: '.uxdc',
    },
    {
      ext: '.UXDC',
    },
    {
      ext: '.ppsx',
    },
    {
      ext: '.PPSX',
    },
    {
      ext: '.pps',
    },
    {
      ext: '.PPS',
    },
    {
      ext: '.ppsm',
    },
    {
      ext: '.PPSM',
    },
    {
      ext: '.potx',
    },
    {
      ext: '.POTX',
    },
    {
      ext: '.pot',
    },
    {
      ext: '.POT',
    },
    {
      ext: '.TIF',
    },
    {
      ext: '.tif',
    },
    {
      ext: '.pcx',
    },
    {
      ext: '.PCX',
    },
    {
      ext: '.psd',
    },
    {
      ext: '.PSD',
    },
    {
      ext: '.CUT',
    },
    {
      ext: '.cut',
    },
    {
      ext: '.DDS',
    },
    {
      ext: '.dds',
    },
    {
      ext: '.ICO',
    },
    {
      ext: '.ico',
    },
    {
      ext: '.PBM',
    },
    {
      ext: '.pbm',
    },
    {
      ext: '.PGM',
    },
    {
      ext: '.TXT',
    },
    {
      ext: '.parquet',
    },
    {
      ext: '.PARQUET',
    },
    {
      ext: '.xml',
    },
    {
      ext: '.XML',
    },
  ],
  DOCUMENT_SIZE: {
    MAX_SIZE_IN_BYTES: 15728640, // 15MB
    VIDEO_SIZE_IN_BYTES: 26214400, // 25MB
    IMAGE_SIZE_IN_BYTES: 1048576, // 1MB
    BANNER_SIZE_IN_BYTES: 62500, // 500 kb
    MIN_SIZE_IN_BYTES: 5242880, // 5MB
  },
  notificationTypeList: [
    {
      Id: undefined,
      Title: 'Select notification type',
    },
    {
      Id: 1,
      Title: 'Push notification',
    },
    {
      Id: 3,
      Title: 'WhatsApp',
    },
    {
      Id: 4,
      Title: 'SMS (text message)',
    },
  ],
  notificationTypeListSendNotifications: [
    {
      Id: undefined,
      Title: 'Select notification type',
    },
    {
      Id: 1,
      Title: 'Push notification',
    },
    {
      Id: 3,
      Title: 'WhatsApp',
    },
  ],
  userGroupList: [
    {
      Id: undefined,
      Title: 'Select users group',
    },
    {
      Id: 1,
      Title: 'All users',
    },
    {
      Id: 2,
      Title: 'Mobile app users',
    },
    {
      Id: 3,
      Title: 'Non app users',
    },
  ],
  imageTypes: [
    {
      Id: 0,
      text: 'None',
    },
    {
      Id: 1,
      text: 'Image URL',
    },
    {
      Id: 2,
      text: 'Image file',
    },
  ],
  FileTypes: [
    {
      Id: 0,
      text: 'None',
    },
    // {
    //   Id: 1,
    //   text: 'Image URL'
    // },
    {
      Id: 2,
      text: 'File',
    },
  ],
  VALID_IMAGE_EXTENSIONS: [
    'apng',
    'png',
    'avif',
    'jpg',
    'gif',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'svg',
    'webp',
    'heic',
    'heif',
  ],
  INCIDENT_SEVERITY_OPTIONS: ['Low', 'Moderate', 'Major', 'Critical'],
  INCIDENT_STATUS_OPTIONS: [
    {
      Id: 1,
      Name: 'Open',
    },
    {
      Id: 2,
      Name: 'Resolved',
    },
    {
      Id: 3,
      Name: 'Closed',
    },
  ],
  BATCH_STATUS_OPTIONS: [
    {
      Id: 1,
      Name: 'Planned',
      Value: 'PLANNED',
    },
    {
      Id: 2,
      Name: 'In Progress',
      Value: 'INPROGRESS',
    },
    {
      Id: 3,
      Name: 'Completed',
      Value: 'COMPLETED',
    },
    {
      Id: 4,
      Name: 'Stopped',
      Value: 'STOPPED',
    },
  ],
  MAINTENANCE_FREQUENCY: [
    {
      Id: 0,
      Name: 'One_time',
      FormattedName: 'One time',
    },
    {
      Id: 1,
      Name: 'Daily',
      FormattedName: 'Daily',
    },
    {
      Id: 2,
      Name: 'monthly',
      FormattedName: 'Monthly',
    },
    {
      Id: 3,
      Name: 'Half_yearly',
      FormattedName: 'Half yearly',
    },
    {
      Id: 4,
      Name: 'yearly',
      FormattedName: 'Yearly',
    },
  ],
  TIME: [
    { value: '12:00 AM', label: '12:00 AM' },
    { value: '01:00 AM', label: '01:00 AM' },
    { value: '02:00 AM', label: '02:00 AM' },
    { value: '03:00 AM', label: '03:00 AM' },
    { value: '04:00 AM', label: '04:00 AM' },
    { value: '05:00 AM', label: '05:00 AM' },
    { value: '06:00 AM', label: '06:00 AM' },
    { value: '07:00 AM', label: '07:00 AM' },
    { value: '08:00 AM', label: '08:00 AM' },
    { value: '09:00 AM', label: '09:00 AM' },
    { value: '10:00 AM', label: '10:00 AM' },
    { value: '11:00 AM', label: '11:00 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '01:00 PM', label: '01:00 PM' },
    { value: '02:00 PM', label: '02:00 PM' },
    { value: '03:00 PM', label: '03:00 PM' },
    { value: '04:00 PM', label: '04:00 PM' },
    { value: '05:00 PM', label: '05:00 PM' },
    { value: '06:00 PM', label: '06:00 PM' },
    { value: '07:00 PM', label: '07:00 PM' },
    { value: '08:00 PM', label: '08:00 PM' },
    { value: '09:00 PM', label: '09:00 PM' },
    { value: '10:00 PM', label: '10:00 PM' },
    { value: '11:00 PM', label: '11:00 PM' },
  ],
  COUNTRY_CODES: [
    {
      'name': 'Afghanistan',
      'dial_code': '+93',
      'code': 'AF',
    },
    {
      'name': 'Aland Islands',
      'dial_code': '+358',
      'code': 'AX',
    },
    {
      'name': 'Albania',
      'dial_code': '+355',
      'code': 'AL',
    },
    {
      'name': 'Algeria',
      'dial_code': '+213',
      'code': 'DZ',
    },
    {
      'name': 'AmericanSamoa',
      'dial_code': '+1684',
      'code': 'AS',
    },
    {
      'name': 'Andorra',
      'dial_code': '+376',
      'code': 'AD',
    },
    {
      'name': 'Angola',
      'dial_code': '+244',
      'code': 'AO',
    },
    {
      'name': 'Anguilla',
      'dial_code': '+1264',
      'code': 'AI',
    },
    {
      'name': 'Antarctica',
      'dial_code': '+672',
      'code': 'AQ',
    },
    {
      'name': 'Antigua and Barbuda',
      'dial_code': '+1268',
      'code': 'AG',
    },
    {
      'name': 'Argentina',
      'dial_code': '+54',
      'code': 'AR',
    },
    {
      'name': 'Armenia',
      'dial_code': '+374',
      'code': 'AM',
    },
    {
      'name': 'Aruba',
      'dial_code': '+297',
      'code': 'AW',
    },
    {
      'name': 'Australia',
      'dial_code': '+61',
      'code': 'AU',
    },
    {
      'name': 'Austria',
      'dial_code': '+43',
      'code': 'AT',
    },
    {
      'name': 'Azerbaijan',
      'dial_code': '+994',
      'code': 'AZ',
    },
    {
      'name': 'Bahamas',
      'dial_code': '+1242',
      'code': 'BS',
    },
    {
      'name': 'Bahrain',
      'dial_code': '+973',
      'code': 'BH',
    },
    {
      'name': 'Bangladesh',
      'dial_code': '+880',
      'code': 'BD',
    },
    {
      'name': 'Barbados',
      'dial_code': '+1246',
      'code': 'BB',
    },
    {
      'name': 'Belarus',
      'dial_code': '+375',
      'code': 'BY',
    },
    {
      'name': 'Belgium',
      'dial_code': '+32',
      'code': 'BE',
    },
    {
      'name': 'Belize',
      'dial_code': '+501',
      'code': 'BZ',
    },
    {
      'name': 'Benin',
      'dial_code': '+229',
      'code': 'BJ',
    },
    {
      'name': 'Bermuda',
      'dial_code': '+1441',
      'code': 'BM',
    },
    {
      'name': 'Bhutan',
      'dial_code': '+975',
      'code': 'BT',
    },
    {
      'name': 'Bolivia, Plurinational State of',
      'dial_code': '+591',
      'code': 'BO',
    },
    {
      'name': 'Bosnia and Herzegovina',
      'dial_code': '+387',
      'code': 'BA',
    },
    {
      'name': 'Botswana',
      'dial_code': '+267',
      'code': 'BW',
    },
    {
      'name': 'Brazil',
      'dial_code': '+55',
      'code': 'BR',
    },
    {
      'name': 'British Indian Ocean Territory',
      'dial_code': '+246',
      'code': 'IO',
    },
    {
      'name': 'Brunei Darussalam',
      'dial_code': '+673',
      'code': 'BN',
    },
    {
      'name': 'Bulgaria',
      'dial_code': '+359',
      'code': 'BG',
    },
    {
      'name': 'Burkina Faso',
      'dial_code': '+226',
      'code': 'BF',
    },
    {
      'name': 'Burundi',
      'dial_code': '+257',
      'code': 'BI',
    },
    {
      'name': 'Cambodia',
      'dial_code': '+855',
      'code': 'KH',
    },
    {
      'name': 'Cameroon',
      'dial_code': '+237',
      'code': 'CM',
    },
    {
      'name': 'Canada',
      'dial_code': '+1',
      'code': 'CA',
    },
    {
      'name': 'Cape Verde',
      'dial_code': '+238',
      'code': 'CV',
    },
    {
      'name': 'Cayman Islands',
      'dial_code': '+ 345',
      'code': 'KY',
    },
    {
      'name': 'Central African Republic',
      'dial_code': '+236',
      'code': 'CF',
    },
    {
      'name': 'Chad',
      'dial_code': '+235',
      'code': 'TD',
    },
    {
      'name': 'Chile',
      'dial_code': '+56',
      'code': 'CL',
    },
    {
      'name': 'China',
      'dial_code': '+86',
      'code': 'CN',
    },
    {
      'name': 'Christmas Island',
      'dial_code': '+61',
      'code': 'CX',
    },
    {
      'name': 'Cocos (Keeling) Islands',
      'dial_code': '+61',
      'code': 'CC',
    },
    {
      'name': 'Colombia',
      'dial_code': '+57',
      'code': 'CO',
    },
    {
      'name': 'Comoros',
      'dial_code': '+269',
      'code': 'KM',
    },
    {
      'name': 'Congo',
      'dial_code': '+242',
      'code': 'CG',
    },
    {
      'name': 'Congo, The Democratic Republic of the Congo',
      'dial_code': '+243',
      'code': 'CD',
    },
    {
      'name': 'Cook Islands',
      'dial_code': '+682',
      'code': 'CK',
    },
    {
      'name': 'Costa Rica',
      'dial_code': '+506',
      'code': 'CR',
    },
    {
      'name': "Cote d'Ivoire",
      'dial_code': '+225',
      'code': 'CI',
    },
    {
      'name': 'Croatia',
      'dial_code': '+385',
      'code': 'HR',
    },
    {
      'name': 'Cuba',
      'dial_code': '+53',
      'code': 'CU',
    },
    {
      'name': 'Cyprus',
      'dial_code': '+357',
      'code': 'CY',
    },
    {
      'name': 'Czech Republic',
      'dial_code': '+420',
      'code': 'CZ',
    },
    {
      'name': 'Denmark',
      'dial_code': '+45',
      'code': 'DK',
    },
    {
      'name': 'Djibouti',
      'dial_code': '+253',
      'code': 'DJ',
    },
    {
      'name': 'Dominica',
      'dial_code': '+1767',
      'code': 'DM',
    },
    {
      'name': 'Dominican Republic',
      'dial_code': '+1849',
      'code': 'DO',
    },
    {
      'name': 'Ecuador',
      'dial_code': '+593',
      'code': 'EC',
    },
    {
      'name': 'Egypt',
      'dial_code': '+20',
      'code': 'EG',
    },
    {
      'name': 'El Salvador',
      'dial_code': '+503',
      'code': 'SV',
    },
    {
      'name': 'Equatorial Guinea',
      'dial_code': '+240',
      'code': 'GQ',
    },
    {
      'name': 'Eritrea',
      'dial_code': '+291',
      'code': 'ER',
    },
    {
      'name': 'Estonia',
      'dial_code': '+372',
      'code': 'EE',
    },
    {
      'name': 'Ethiopia',
      'dial_code': '+251',
      'code': 'ET',
    },
    {
      'name': 'Falkland Islands (Malvinas)',
      'dial_code': '+500',
      'code': 'FK',
    },
    {
      'name': 'Faroe Islands',
      'dial_code': '+298',
      'code': 'FO',
    },
    {
      'name': 'Fiji',
      'dial_code': '+679',
      'code': 'FJ',
    },
    {
      'name': 'Finland',
      'dial_code': '+358',
      'code': 'FI',
    },
    {
      'name': 'France',
      'dial_code': '+33',
      'code': 'FR',
    },
    {
      'name': 'French Guiana',
      'dial_code': '+594',
      'code': 'GF',
    },
    {
      'name': 'French Polynesia',
      'dial_code': '+689',
      'code': 'PF',
    },
    {
      'name': 'Gabon',
      'dial_code': '+241',
      'code': 'GA',
    },
    {
      'name': 'Gambia',
      'dial_code': '+220',
      'code': 'GM',
    },
    {
      'name': 'Georgia',
      'dial_code': '+995',
      'code': 'GE',
    },
    {
      'name': 'Germany',
      'dial_code': '+49',
      'code': 'DE',
    },
    {
      'name': 'Ghana',
      'dial_code': '+233',
      'code': 'GH',
    },
    {
      'name': 'Gibraltar',
      'dial_code': '+350',
      'code': 'GI',
    },
    {
      'name': 'Greece',
      'dial_code': '+30',
      'code': 'GR',
    },
    {
      'name': 'Greenland',
      'dial_code': '+299',
      'code': 'GL',
    },
    {
      'name': 'Grenada',
      'dial_code': '+1473',
      'code': 'GD',
    },
    {
      'name': 'Guadeloupe',
      'dial_code': '+590',
      'code': 'GP',
    },
    {
      'name': 'Guam',
      'dial_code': '+1671',
      'code': 'GU',
    },
    {
      'name': 'Guatemala',
      'dial_code': '+502',
      'code': 'GT',
    },
    {
      'name': 'Guernsey',
      'dial_code': '+44',
      'code': 'GG',
    },
    {
      'name': 'Guinea',
      'dial_code': '+224',
      'code': 'GN',
    },
    {
      'name': 'Guinea-Bissau',
      'dial_code': '+245',
      'code': 'GW',
    },
    {
      'name': 'Guyana',
      'dial_code': '+595',
      'code': 'GY',
    },
    {
      'name': 'Haiti',
      'dial_code': '+509',
      'code': 'HT',
    },
    {
      'name': 'Holy See (Vatican City State)',
      'dial_code': '+379',
      'code': 'VA',
    },
    {
      'name': 'Honduras',
      'dial_code': '+504',
      'code': 'HN',
    },
    {
      'name': 'Hong Kong',
      'dial_code': '+852',
      'code': 'HK',
    },
    {
      'name': 'Hungary',
      'dial_code': '+36',
      'code': 'HU',
    },
    {
      'name': 'Iceland',
      'dial_code': '+354',
      'code': 'IS',
    },
    {
      'name': 'India',
      'dial_code': '+91',
      'code': 'IN',
    },
    {
      'name': 'Indonesia',
      'dial_code': '+62',
      'code': 'ID',
    },
    {
      'name': 'Iran, Islamic Republic of Persian Gulf',
      'dial_code': '+98',
      'code': 'IR',
    },
    {
      'name': 'Iraq',
      'dial_code': '+964',
      'code': 'IQ',
    },
    {
      'name': 'Ireland',
      'dial_code': '+353',
      'code': 'IE',
    },
    {
      'name': 'Isle of Man',
      'dial_code': '+44',
      'code': 'IM',
    },
    {
      'name': 'Israel',
      'dial_code': '+972',
      'code': 'IL',
    },
    {
      'name': 'Italy',
      'dial_code': '+39',
      'code': 'IT',
    },
    {
      'name': 'Jamaica',
      'dial_code': '+1876',
      'code': 'JM',
    },
    {
      'name': 'Japan',
      'dial_code': '+81',
      'code': 'JP',
    },
    {
      'name': 'Jersey',
      'dial_code': '+44',
      'code': 'JE',
    },
    {
      'name': 'Jordan',
      'dial_code': '+962',
      'code': 'JO',
    },
    {
      'name': 'Kazakhstan',
      'dial_code': '+77',
      'code': 'KZ',
    },
    {
      'name': 'Kenya',
      'dial_code': '+254',
      'code': 'KE',
    },
    {
      'name': 'Kiribati',
      'dial_code': '+686',
      'code': 'KI',
    },
    {
      'name': "Korea, Democratic People's Republic of Korea",
      'dial_code': '+850',
      'code': 'KP',
    },
    {
      'name': 'Korea, Republic of South Korea',
      'dial_code': '+82',
      'code': 'KR',
    },
    {
      'name': 'Kuwait',
      'dial_code': '+965',
      'code': 'KW',
    },
    {
      'name': 'Kyrgyzstan',
      'dial_code': '+996',
      'code': 'KG',
    },
    {
      'name': 'Laos',
      'dial_code': '+856',
      'code': 'LA',
    },
    {
      'name': 'Latvia',
      'dial_code': '+371',
      'code': 'LV',
    },
    {
      'name': 'Lebanon',
      'dial_code': '+961',
      'code': 'LB',
    },
    {
      'name': 'Lesotho',
      'dial_code': '+266',
      'code': 'LS',
    },
    {
      'name': 'Liberia',
      'dial_code': '+231',
      'code': 'LR',
    },
    {
      'name': 'Libyan Arab Jamahiriya',
      'dial_code': '+218',
      'code': 'LY',
    },
    {
      'name': 'Liechtenstein',
      'dial_code': '+423',
      'code': 'LI',
    },
    {
      'name': 'Lithuania',
      'dial_code': '+370',
      'code': 'LT',
    },
    {
      'name': 'Luxembourg',
      'dial_code': '+352',
      'code': 'LU',
    },
    {
      'name': 'Macao',
      'dial_code': '+853',
      'code': 'MO',
    },
    {
      'name': 'Macedonia',
      'dial_code': '+389',
      'code': 'MK',
    },
    {
      'name': 'Madagascar',
      'dial_code': '+261',
      'code': 'MG',
    },
    {
      'name': 'Malawi',
      'dial_code': '+265',
      'code': 'MW',
    },
    {
      'name': 'Malaysia',
      'dial_code': '+60',
      'code': 'MY',
    },
    {
      'name': 'Maldives',
      'dial_code': '+960',
      'code': 'MV',
    },
    {
      'name': 'Mali',
      'dial_code': '+223',
      'code': 'ML',
    },
    {
      'name': 'Malta',
      'dial_code': '+356',
      'code': 'MT',
    },
    {
      'name': 'Marshall Islands',
      'dial_code': '+692',
      'code': 'MH',
    },
    {
      'name': 'Martinique',
      'dial_code': '+596',
      'code': 'MQ',
    },
    {
      'name': 'Mauritania',
      'dial_code': '+222',
      'code': 'MR',
    },
    {
      'name': 'Mauritius',
      'dial_code': '+230',
      'code': 'MU',
    },
    {
      'name': 'Mayotte',
      'dial_code': '+262',
      'code': 'YT',
    },
    {
      'name': 'Mexico',
      'dial_code': '+52',
      'code': 'MX',
    },
    {
      'name': 'Micronesia, Federated States of Micronesia',
      'dial_code': '+691',
      'code': 'FM',
    },
    {
      'name': 'Moldova',
      'dial_code': '+373',
      'code': 'MD',
    },
    {
      'name': 'Monaco',
      'dial_code': '+377',
      'code': 'MC',
    },
    {
      'name': 'Mongolia',
      'dial_code': '+976',
      'code': 'MN',
    },
    {
      'name': 'Montenegro',
      'dial_code': '+382',
      'code': 'ME',
    },
    {
      'name': 'Montserrat',
      'dial_code': '+1664',
      'code': 'MS',
    },
    {
      'name': 'Morocco',
      'dial_code': '+212',
      'code': 'MA',
    },
    {
      'name': 'Mozambique',
      'dial_code': '+258',
      'code': 'MZ',
    },
    {
      'name': 'Myanmar',
      'dial_code': '+95',
      'code': 'MM',
    },
    {
      'name': 'Namibia',
      'dial_code': '+264',
      'code': 'NA',
    },
    {
      'name': 'Nauru',
      'dial_code': '+674',
      'code': 'NR',
    },
    {
      'name': 'Nepal',
      'dial_code': '+977',
      'code': 'NP',
    },
    {
      'name': 'Netherlands',
      'dial_code': '+31',
      'code': 'NL',
    },
    {
      'name': 'Netherlands Antilles',
      'dial_code': '+599',
      'code': 'AN',
    },
    {
      'name': 'New Caledonia',
      'dial_code': '+687',
      'code': 'NC',
    },
    {
      'name': 'New Zealand',
      'dial_code': '+64',
      'code': 'NZ',
    },
    {
      'name': 'Nicaragua',
      'dial_code': '+505',
      'code': 'NI',
    },
    {
      'name': 'Niger',
      'dial_code': '+227',
      'code': 'NE',
    },
    {
      'name': 'Nigeria',
      'dial_code': '+234',
      'code': 'NG',
    },
    {
      'name': 'Niue',
      'dial_code': '+683',
      'code': 'NU',
    },
    {
      'name': 'Norfolk Island',
      'dial_code': '+672',
      'code': 'NF',
    },
    {
      'name': 'Northern Mariana Islands',
      'dial_code': '+1670',
      'code': 'MP',
    },
    {
      'name': 'Norway',
      'dial_code': '+47',
      'code': 'NO',
    },
    {
      'name': 'Oman',
      'dial_code': '+968',
      'code': 'OM',
    },
    {
      'name': 'Pakistan',
      'dial_code': '+92',
      'code': 'PK',
    },
    {
      'name': 'Palau',
      'dial_code': '+680',
      'code': 'PW',
    },
    {
      'name': 'Palestinian Territory, Occupied',
      'dial_code': '+970',
      'code': 'PS',
    },
    {
      'name': 'Panama',
      'dial_code': '+507',
      'code': 'PA',
    },
    {
      'name': 'Papua New Guinea',
      'dial_code': '+675',
      'code': 'PG',
    },
    {
      'name': 'Paraguay',
      'dial_code': '+595',
      'code': 'PY',
    },
    {
      'name': 'Peru',
      'dial_code': '+51',
      'code': 'PE',
    },
    {
      'name': 'Philippines',
      'dial_code': '+63',
      'code': 'PH',
    },
    {
      'name': 'Pitcairn',
      'dial_code': '+872',
      'code': 'PN',
    },
    {
      'name': 'Poland',
      'dial_code': '+48',
      'code': 'PL',
    },
    {
      'name': 'Portugal',
      'dial_code': '+351',
      'code': 'PT',
    },
    {
      'name': 'Puerto Rico',
      'dial_code': '+1939',
      'code': 'PR',
    },
    {
      'name': 'Qatar',
      'dial_code': '+974',
      'code': 'QA',
    },
    {
      'name': 'Romania',
      'dial_code': '+40',
      'code': 'RO',
    },
    {
      'name': 'Russia',
      'dial_code': '+7',
      'code': 'RU',
    },
    {
      'name': 'Rwanda',
      'dial_code': '+250',
      'code': 'RW',
    },
    {
      'name': 'Reunion',
      'dial_code': '+262',
      'code': 'RE',
    },
    {
      'name': 'Saint Barthelemy',
      'dial_code': '+590',
      'code': 'BL',
    },
    {
      'name': 'Saint Helena, Ascension and Tristan Da Cunha',
      'dial_code': '+290',
      'code': 'SH',
    },
    {
      'name': 'Saint Kitts and Nevis',
      'dial_code': '+1869',
      'code': 'KN',
    },
    {
      'name': 'Saint Lucia',
      'dial_code': '+1758',
      'code': 'LC',
    },
    {
      'name': 'Saint Martin',
      'dial_code': '+590',
      'code': 'MF',
    },
    {
      'name': 'Saint Pierre and Miquelon',
      'dial_code': '+508',
      'code': 'PM',
    },
    {
      'name': 'Saint Vincent and the Grenadines',
      'dial_code': '+1784',
      'code': 'VC',
    },
    {
      'name': 'Samoa',
      'dial_code': '+685',
      'code': 'WS',
    },
    {
      'name': 'San Marino',
      'dial_code': '+378',
      'code': 'SM',
    },
    {
      'name': 'Sao Tome and Principe',
      'dial_code': '+239',
      'code': 'ST',
    },
    {
      'name': 'Saudi Arabia',
      'dial_code': '+966',
      'code': 'SA',
    },
    {
      'name': 'Senegal',
      'dial_code': '+221',
      'code': 'SN',
    },
    {
      'name': 'Serbia',
      'dial_code': '+381',
      'code': 'RS',
    },
    {
      'name': 'Seychelles',
      'dial_code': '+248',
      'code': 'SC',
    },
    {
      'name': 'Sierra Leone',
      'dial_code': '+232',
      'code': 'SL',
    },
    {
      'name': 'Singapore',
      'dial_code': '+65',
      'code': 'SG',
    },
    {
      'name': 'Slovakia',
      'dial_code': '+421',
      'code': 'SK',
    },
    {
      'name': 'Slovenia',
      'dial_code': '+386',
      'code': 'SI',
    },
    {
      'name': 'Solomon Islands',
      'dial_code': '+677',
      'code': 'SB',
    },
    {
      'name': 'Somalia',
      'dial_code': '+252',
      'code': 'SO',
    },
    {
      'name': 'South Africa',
      'dial_code': '+27',
      'code': 'ZA',
    },
    {
      'name': 'South Sudan',
      'dial_code': '+211',
      'code': 'SS',
    },
    {
      'name': 'South Georgia and the South Sandwich Islands',
      'dial_code': '+500',
      'code': 'GS',
    },
    {
      'name': 'Spain',
      'dial_code': '+34',
      'code': 'ES',
    },
    {
      'name': 'Sri Lanka',
      'dial_code': '+94',
      'code': 'LK',
    },
    {
      'name': 'Sudan',
      'dial_code': '+249',
      'code': 'SD',
    },
    {
      'name': 'Suriname',
      'dial_code': '+597',
      'code': 'SR',
    },
    {
      'name': 'Svalbard and Jan Mayen',
      'dial_code': '+47',
      'code': 'SJ',
    },
    {
      'name': 'Swaziland',
      'dial_code': '+268',
      'code': 'SZ',
    },
    {
      'name': 'Sweden',
      'dial_code': '+46',
      'code': 'SE',
    },
    {
      'name': 'Switzerland',
      'dial_code': '+41',
      'code': 'CH',
    },
    {
      'name': 'Syrian Arab Republic',
      'dial_code': '+963',
      'code': 'SY',
    },
    {
      'name': 'Taiwan',
      'dial_code': '+886',
      'code': 'TW',
    },
    {
      'name': 'Tajikistan',
      'dial_code': '+992',
      'code': 'TJ',
    },
    {
      'name': 'Tanzania, United Republic of Tanzania',
      'dial_code': '+255',
      'code': 'TZ',
    },
    {
      'name': 'Thailand',
      'dial_code': '+66',
      'code': 'TH',
    },
    {
      'name': 'Timor-Leste',
      'dial_code': '+670',
      'code': 'TL',
    },
    {
      'name': 'Togo',
      'dial_code': '+228',
      'code': 'TG',
    },
    {
      'name': 'Tokelau',
      'dial_code': '+690',
      'code': 'TK',
    },
    {
      'name': 'Tonga',
      'dial_code': '+676',
      'code': 'TO',
    },
    {
      'name': 'Trinidad and Tobago',
      'dial_code': '+1868',
      'code': 'TT',
    },
    {
      'name': 'Tunisia',
      'dial_code': '+216',
      'code': 'TN',
    },
    {
      'name': 'Turkey',
      'dial_code': '+90',
      'code': 'TR',
    },
    {
      'name': 'Turkmenistan',
      'dial_code': '+993',
      'code': 'TM',
    },
    {
      'name': 'Turks and Caicos Islands',
      'dial_code': '+1649',
      'code': 'TC',
    },
    {
      'name': 'Tuvalu',
      'dial_code': '+688',
      'code': 'TV',
    },
    {
      'name': 'Uganda',
      'dial_code': '+256',
      'code': 'UG',
    },
    {
      'name': 'Ukraine',
      'dial_code': '+380',
      'code': 'UA',
    },
    {
      'name': 'United Arab Emirates',
      'dial_code': '+971',
      'code': 'AE',
    },
    {
      'name': 'United Kingdom',
      'dial_code': '+44',
      'code': 'GB',
    },
    {
      'name': 'United States',
      'dial_code': '+1',
      'code': 'US',
    },
    {
      'name': 'Uruguay',
      'dial_code': '+598',
      'code': 'UY',
    },
    {
      'name': 'Uzbekistan',
      'dial_code': '+998',
      'code': 'UZ',
    },
    {
      'name': 'Vanuatu',
      'dial_code': '+678',
      'code': 'VU',
    },
    {
      'name': 'Venezuela, Bolivarian Republic of Venezuela',
      'dial_code': '+58',
      'code': 'VE',
    },
    {
      'name': 'Vietnam',
      'dial_code': '+84',
      'code': 'VN',
    },
    {
      'name': 'Virgin Islands, British',
      'dial_code': '+1284',
      'code': 'VG',
    },
    {
      'name': 'Virgin Islands, U.S.',
      'dial_code': '+1340',
      'code': 'VI',
    },
    {
      'name': 'Wallis and Futuna',
      'dial_code': '+681',
      'code': 'WF',
    },
    {
      'name': 'Yemen',
      'dial_code': '+967',
      'code': 'YE',
    },
    {
      'name': 'Zambia',
      'dial_code': '+260',
      'code': 'ZM',
    },
    {
      'name': 'Zimbabwe',
      'dial_code': '+263',
      'code': 'ZW',
    },
  ],
};

export default constants;
