import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import PaymentService from '../billing/services/payment.service';
import EnvService from '../../env.service';
import constants from '../../common/constants';

@Component({
  selector: 'app-billing-summary-component',
  templateUrl: './billing-summary.component.html',
  styleUrls: ['./billing-summary.component.css'],
})
export default class BillingSummaryComponent implements OnInit, OnDestroy {
  @Input() priceDetails;

  @Input() isLandingPage;

  couponObj: Object;

  public paypalConfig?: IPayPalConfig;

  public vatPercent = 0;

  public isCouponApplied = false;

  public couponCode: string;

  public couponDiscount;

  public couponDiscountPercent;

  public maxDiscount;

  public isPaymentComplete = false;

  public isPaymentSuccess = false;

  public purchaseData = null;

  public currencies = constants.currencyOptions;

  public preferredCurrency = this.currencies[0].value;

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private env: EnvService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    if (localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS)) {
      this.priceDetails = JSON.parse(localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS));
      this.preferredCurrency = localStorage.getItem(constants.PREFERRED_CURRENCY);
    }
    if (this.preferredCurrency === 'INR') {
      this.priceDetails.Discount = this.priceDetails.DiscountINR.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceINR.toFixed(2);
      this.priceDetails.PricePerMonth = this.priceDetails.PricePerMonthINR.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalINR.toFixed(2);
    } else if (this.preferredCurrency === 'USD') {
      this.priceDetails.Discount = this.priceDetails.DiscountUSD.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceUSD.toFixed(2);
      this.priceDetails.PricePerMonth = this.priceDetails.PricePerMonthUSD.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalUSD.toFixed(2);
    } else {
      this.priceDetails.Discount = this.priceDetails.DiscountGBP.toFixed(2);
      this.priceDetails.Price = this.priceDetails.PriceGBP.toFixed(2);
      this.priceDetails.PricePerMonth = this.priceDetails.PricePerMonthGBP.toFixed(2);
      this.priceDetails.Total = this.priceDetails.TotalGBP.toFixed(2);
    }
    // To fix the issue with PayPal script - window closed error
    setTimeout(() => {
      this.initConfig();
    }, 2000);
  }

  ngOnDestroy() {
    this.clearStoredItems();
  }

  // eslint-disable-next-line class-methods-use-this
  clearStoredItems() {
    localStorage.removeItem(constants.PAYMENT_IN_PROGRESS_DETAILS);
    localStorage.removeItem(constants.PREFERRED_CURRENCY);
  }

  onCurrencyUpdated() {
    localStorage.setItem(constants.PAYMENT_IN_PROGRESS_DETAILS, JSON.stringify(this.priceDetails));
    localStorage.setItem(constants.PREFERRED_CURRENCY, this.preferredCurrency);
    this.priceDetails = {};
    window.location.reload();
  }

  private initConfig() {
    this.paypalConfig = {
      currency: this.preferredCurrency,
      clientId: this.env.paypalClientId,
      createOrderOnClient: () =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.preferredCurrency,
                value: Number(this.priceDetails.Total).toFixed(2),
                breakdown: {
                  item_total: {
                    currency_code: this.preferredCurrency,
                    value: Number(this.priceDetails.Total).toFixed(2),
                  },
                },
              },
              items: [
                {
                  name: this.priceDetails.PackageName,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: this.preferredCurrency,
                    value: Number(this.priceDetails.Total).toFixed(2),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onClientAuthorization: (data) => {
        // Get orgId from localstorage
        // let organisationId = null;
        // if (this.isLandingPage === false) {
        //   organisationId = JSON.parse(localStorage.getItem(constants.CURRENT_USER_DETAILS)).OrganisationId;
        // } else {
        //   organisationId = JSON.parse(localStorage.getItem(constants.REGISTERED_ORG_DETAILS)).organisationId;
        // }
        this.purchaseLicense(data);
      },
      onError: () => {
        this.toastr.error(this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.PAYPAL_ERROR'));
      },
    };
  }

  calculateDiscount(couponDiscountPercent, maxDiscount) {
    const discountAmount = (this.priceDetails.Price * couponDiscountPercent) / 100;
    if (maxDiscount > discountAmount) {
      this.couponDiscount = discountAmount.toFixed(2);
    } else {
      this.couponDiscount = maxDiscount.toFixed(2);
    }
  }

  validateCoupon() {
    this.paymentService.validateCoupon(this.couponCode).subscribe(
      (res) => {
        if (res.Data.Coupon === undefined) {
          this.toastr.error(
            this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.COUPON_CODE_INVALID'),
          );
          return;
        }
        const couponData = res.Data.Coupon;
        this.couponDiscountPercent = couponData.DiscountPercentage;

        let maxDiscount;

        if (this.preferredCurrency === 'INR') {
          maxDiscount = couponData.MaxDiscountINR;
        } else if (this.preferredCurrency === 'USD') {
          maxDiscount = couponData.MaxDiscountUSD;
        } else {
          maxDiscount = couponData.MaxDiscountGBP;
        }

        this.maxDiscount = maxDiscount;

        this.calculateDiscount(this.couponDiscountPercent, this.maxDiscount);
        this.priceDetails.Total -= this.couponDiscount;
        this.toastr.success(
          this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.COUPON_CODE_APPLIED'),
        );
        this.isCouponApplied = true;
      },
      () => {
        this.isCouponApplied = false;
        this.toastr.error(
          this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.COUPON_APPLY_ERROR'),
        );
      },
    );
  }

  removeCoupon() {
    this.couponCode = '';
    this.isCouponApplied = false;
    this.priceDetails.Total += parseFloat(this.couponDiscount);
    this.couponDiscount = 0;
    this.couponDiscountPercent = 0;
  }

  purchaseLicense(purchaseData) {
    this.purchaseData = {
      AmountPaid: Number(this.priceDetails.Total).toFixed(2),
      Coupon: this.couponCode,
      PackageId: this.priceDetails.PackageId,
      NumberOfMonths: this.priceDetails.NoOfMonths,
      PaymentReferenceNumber: purchaseData.id,
      PackageName: this.priceDetails.PackageName,
      CurrencyCode: this.preferredCurrency,
    };
    this.paymentService.purchaseLicense(this.purchaseData).subscribe(
      (res) => {
        this.isPaymentComplete = true;
        if (res.Status === 'Success') {
          this.isPaymentSuccess = true;
        } else {
          this.isPaymentSuccess = false;
        }
      },
      () => {
        this.isPaymentComplete = true;
        this.isPaymentSuccess = false;
      },
    );
  }
}
