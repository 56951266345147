<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span>{{ 'LABELS.APPOINTMENTS.CONFIGURE' | translate }}</span>
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <form #appointmentsConfigForm="ngForm">
        <div class="input-group mb-3" *ngIf="branchList?.length >= 1">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-map-marker"></i></span>
          </div>
          <select
            name="Branch"
            class="form-control"
            required
            [(ngModel)]="config.LocationId"
            (change)="updateConfigChecker($event, config.OrganisationId, config.LocationId)"
          >
            <option [value]="undefined" disabled selected>
              {{ 'LABELS.APPOINTMENTS.SELECT_BRANCH' | translate }}
            </option>
            <option *ngFor="let branch of branchList" [value]="branch.Id">
              {{ branch.LocationName }}
            </option>
          </select>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-briefcase"></i></span>
          </div>
          <div class="btn-group btn-group-toggle">
            <label class="btn-info checkbox-flat no-border-left" ngbButtonLabel>
              <input type="checkbox" name="sun" ngbButton [(ngModel)]="config.WorkingDays[0]" />
              Sun
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="mon" ngbButton [(ngModel)]="config.WorkingDays[1]" />
              Mon
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="tue" ngbButton [(ngModel)]="config.WorkingDays[2]" />
              Tue
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="wed" ngbButton [(ngModel)]="config.WorkingDays[3]" />
              Wed
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="thu" ngbButton [(ngModel)]="config.WorkingDays[4]" />
              Thu
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="fri" ngbButton [(ngModel)]="config.WorkingDays[5]" />
              Fri
            </label>
            <label class="btn-info checkbox-flat" ngbButtonLabel>
              <input type="checkbox" name="sat" ngbButton [(ngModel)]="config.WorkingDays[6]" />
              Sat
            </label>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-clock-o"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            name="startTime"
            [(ngModel)]="config.StartTime"
            placeholder="Starting time"
            required
            #startTime="ngModel"
            [ngxTimepicker]="startTimePicker"
            (ngModelChange)="compareStartTimeSlots(config)"
          />
          <div class="alert-danger">
            <span *ngIf="showStartTimeSlotError">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.OPEN_TIME_SLOT_ERROR_MESSAGE' | translate }}
            </span>
          </div>
          <ngx-material-timepicker #startTimePicker [minutesGap]="5"></ngx-material-timepicker>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-clock-o"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            name="endTime"
            [(ngModel)]="config.EndTime"
            placeholder="Closing time"
            required
            #endTime="ngModel"
            [ngxTimepicker]="endTimePicker"
            (ngModelChange)="compareEndTimeSlots(config)"
          />
          <div class="alert-danger">
            <span *ngIf="showEndTimeSlotError">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.CLOSE_TIME_SLOT_ERROR_MESSAGE' | translate }}
            </span>
          </div>
          <ngx-material-timepicker #endTimePicker [minutesGap]="5"></ngx-material-timepicker>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-spinner"></i></span>
          </div>
          <select
            name="slotDuration"
            class="form-control"
            #slotDuration="ngModel"
            required
            [(ngModel)]="config.SlotDuration"
          >
            <option [value]="null" disabled selected>
              {{ 'LABELS.APPOINTMENTS.SLOT_DURATION' | translate }}
            </option>
            <option [value]="'15'">15</option>
            <option [value]="'30'">30</option>
            <option [value]="'45'">45</option>
            <option [value]="'60'">60</option>
          </select>
          <div
            class="alert-danger"
            *ngIf="slotDuration.invalid && (slotDuration.dirty || slotDuration.touched)"
          >
            <span *ngIf="slotDuration.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.SLOT_DURATION_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-list-ul"></i></span>
          </div>
          <input
            type="number"
            min="0"
            class="form-control"
            name="appointmentsLimit"
            [(ngModel)]="config.AppointmentsLimitPerSlot"
            placeholder="Number of appointments allowed per slot"
            required
            #appointmentsLimit="ngModel"
          />
          <div
            class="alert-danger"
            *ngIf="
              appointmentsLimit.invalid && (appointmentsLimit.dirty || appointmentsLimit.touched)
            "
          >
            <span *ngIf="appointmentsLimit.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.APPOINTMENTS_LIMIT_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <label class="switch-container">
          <input
            type="checkbox"
            name="autoApproval"
            [(ngModel)]="config.AutoApproval"
          />&nbsp;&nbsp;{{ 'LABELS.APPOINTMENTS.AUTO_APPROVAL' | translate }}
          <span class="slider round"></span>
        </label>
      </form>
    </div>
  </div>
  <div class="row" style="justify-content: flex-end; margin-right: 4px">
    <div>
      <button class="btn btn-danger" type="button" (click)="goBack()">
        <i class="fa fa-close" aria-hidden="true"></i>
        {{ 'LABELS.SETUP.BUTTONS.CANCEL' | translate }}
      </button>
    </div>
    &nbsp;
    <div class="col-sm-12 m-b-xs content-right-align" style="display: contents">
      <button
        type="submit"
        class="btn btn-success"
        (click)="saveConfig()"
        [disabled]="
          appointmentsConfigForm.invalid ||
          showStartTimeSlotError === true ||
          showEndTimeSlotError === true
        "
      >
        {{ 'LABELS.SETUP.BUTTONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</div>
