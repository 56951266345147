import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import AuthService from '../../auth/auth.service';
import constants from '../../common/constants';

@Component({
  selector: 'app-success-component',
  templateUrl: 'success.component.html',
})
export default class SuccessComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
  ) {
    // logic goes here
  }

  public organisationDetails = {
    OrganisationUid: '',
    OrganisationName: '',
    Email: '',
    isClosedOrganisation: '',
  };

  ngOnInit() {
    const params = JSON.parse(localStorage.getItem(constants.REGISTERED_ORG_DETAILS));
    this.organisationDetails.OrganisationUid = params.uniqueId;
    this.organisationDetails.OrganisationName = params.organisationName;
    this.organisationDetails.Email = params.email;
    this.organisationDetails.isClosedOrganisation = params.isClosedOrganisation;
  }

  showLoginForm() {
    this.auth.activeForm = 'login';
    this.router.navigate(['login']);
  }
}
