<!-- navigation bar start -->
<header class="bg-white sticky-top">
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <a href="index.html" title="IncidentReporter365">
        <picture class="align-center">
          <source srcset="assets/landing-page/images/logo.webp" type="image/webp" />
          <img
            src="assets/landing-page/images/logo.png"
            alt="incidentreporter365 logo"
            class="logo"
          />
        </picture>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#header-nav1"
        aria-label="Menu Toggler"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div id="header-nav1" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item" [routerLink]="['']" fragment="about">
            <a href="#about" class="nav-link smooth-scroll">{{
              'HOME.MENU.HOW_IT_WORKS' | translate
            }}</a>
          </li>
          <li class="nav-item" [routerLink]="['']" fragment="features">
            <a class="nav-link smooth-scroll" href="#features">{{
              'HOME.MENU.FEATURES' | translate
            }}</a>
          </li>
          <li class="nav-item" [routerLink]="['']" fragment="benefits">
            <a class="nav-link smooth-scroll" href="#benefits">{{
              'HOME.MENU.BENEFITS' | translate
            }}</a>
          </li>
          <li class="nav-item" [routerLink]="['']" fragment="demo">
            <a href="#demo" class="nav-link smooth-scroll">{{ 'HOME.MENU.DEMO' | translate }}</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link custom-cursor"
              (click)="redirectToExternalURL('https://blog.incidentreporter365.com/')"
              >{{ 'HOME.MENU.BLOG' | translate }}</a
            >
          </li>
          <li (click)="redirectToLogin()">
            <button class="login-btn ml-lg-3">
              {{ 'HOME.MENU.LOGIN' | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- for modals in home page  -->
<div class="home-modal" *ngIf="auth.activeForm === 'successMessageComponent'">
  <app-success-component></app-success-component>
</div>
<div class="home-modal" *ngIf="auth.activeForm === 'billingSummaryComponent'">
  <div class="card" style="margin: auto; width: 100%">
    <div class="card-body p-4">
      <app-billing-summary-component
        [priceDetails]="selectedPackagePriceDetails"
        [isLandingPage]="true"
      >
      </app-billing-summary-component>
    </div>
  </div>
</div>
<!-- main content starts  -->
<main>
  <!-- hero section  -->
  <!-- <section>
    <picture class="align-center d-none d-sm-block">
      <source srcset="assets/landing-page/images/bg/IR365_Banner.webp" type="image/webp" />
      <img
        src="assets/landing-page/images/bg/IR365_Banner.png"
        alt="incidentreporter365 banner"
        class="img-fluid"
      />
    </picture>
    <picture class="align-center d-sm-none">
      <source srcset="assets/landing-page/images/bg/Mobile_Banner_Image.webp" type="image/webp" />
      <img
        src="assets/landing-page/images/bg/Mobile_Banner_Image.png"
        alt="incidentreporter365 banner"
        class="img-fluid"
      />
    </picture>
  </section> -->
  <section [ngStyle]="{'display': getContainerStyle()}">
    <div class="desktop-first-image">
      <img src="assets\landing-page\images\bg\IR365_To_Banner.png" alt="IR365 to OQSHA Image">
    </div>
    <div class="mobile-first-image">
      <img src="assets\landing-page\images\bg\Mobile_IR365_To_Banner.png" alt="IR365 to OQSHA Image">
    </div>
    <div class="second-content">
      <div class="logo">
        <img src="assets\landing-page\images\bg\oqsha-logo-transparent.png" alt="OQSHA Logo">
      </div>
      <ul class="points">
        <li>Incident Reporter 365 is now rebranded as OQSHA with enchanced feature set.</li>
        <li>If you are already an Incident Reported 365 user, you can continue with your existing credentials</li>
        <li>Click here to experience OQSHA.COM</li>
      </ul>
      <a href="https://staging.oqsha.com/#/home" style="text-decoration: none;">
        <button class="action-button">oqsha.com
          <img src="assets\landing-page\images\bg\next-btn.png" alt="Next button">
        </button>
      </a>
    </div>
  </section>  
  <!-- featured  -->
  <section id="hse" class="section">
    <div class="container">
      <h1 class="section-title featured-title">{{ 'HOME.MENU.HSE' | translate }}</h1>
      <div class="row">
        <div class="col-lg-9 mx-auto hse-container">
          <div
            class="owl-carousel owl-theme owl-dark"
            data-autoplay="true"
            data-autoheight="true"
            data-loop="true"
            data-items="1"
          >
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image1.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image1.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image2.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image2.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image3.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image3.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image4.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image4.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image5.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image5.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image6.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image6.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image7.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image7.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image8.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image8.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image9.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image9.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image10.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image10.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source
                  srcset="assets/landing-page/images/hse_screens/hse_image11.webp"
                  type="image/webp"
                />
                <img
                  src="assets/landing-page/images/hse_screens/hse_image11.jpg"
                  alt="IR365 in HSE Birmingham"
                  class="img-fluid"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- why  -->
  <section id="why" class="section">
    <div class="container">
      <h1 class="section-title">{{ 'HOME.MENU.WHYIR365' | translate }}</h1>
      <div class="row">
        <div class="col-md-6">
          <picture>
            <source srcset="assets/img/icons/why_IR365.webp" type="image/webp" />
            <img
              src="assets/img/icons/why_IR365.png"
              alt="why incidentreporter365"
              class="img-fluid mb-5"
              style="border-radius: 20px"
            />
          </picture>
        </div>
        <div class="col-md-6">
          <div class="why-content text-justify text-lg-left">
            <p>{{ 'HOME.WHYIR356.WHY_DESC_1' | translate }}</p>
            <p>{{ 'HOME.WHYIR356.WHY_DESC_2' | translate }}</p>
            <p>{{ 'HOME.WHYIR356.WHY_DESC_3' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Features -->
  <section id="features" class="section pt-5">
    <div class="container">
      <h1 class="section-title mb-5">{{ 'HOME.MENU.FEATURES' | translate }}</h1>
      <div class="row no-gutters">
        <div class="col-md-6">
          <ul class="features-list">
            <li *ngFor="let feature of features">
              <img alt="Expand" src=" assets/img/icons/Bullet-Point-Features.png" />
              <p>{{ feature | translate }}</p>
            </li>
          </ul>
        </div>
        <div class="col-md-6 mt-5">
          <div
            class="owl-carousel owl-theme portfolio"
            data-nav="true"
            data-loop="true"
            data-margin="30"
            data-slideby="1"
            data-stagepadding="18"
            data-items-xs="1"
            data-items-sm="1"
            data-items-md="1"
            data-items-lg="1"
            data-autoplay="true"
          >
            <div class="portfolio-img">
              <picture>
                <source srcset="assets/img/features/Banner_App_User.webp" type="image/webp" />
                <img
                  src="assets/img/features/Banner_App_User.png"
                  alt="features of incidentreporter365"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source srcset="assets/img/features/Banner_Appointment.webp" type="image/webp" />
                <img
                  src="assets/img/features/Banner_Appointment.png"
                  alt="features of incidentreporter365"
                  class="img-fluid"
                />
              </picture>
            </div>
            <div class="portfolio-img">
              <picture>
                <source srcset="assets/img/features/Banner_Heatmap.webp" type="image/webp" />
                <img
                  src="assets/img/features/Banner_Heatmap.png"
                  alt="features of incidentreporter365"
                  class="img-fluid"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Benefits -->
  <section id="benefits" class="section pt-5">
    <div class="container benefits-container">
      <h1 class="section-title">{{ 'HOME.MENU.BENEFITS' | translate }}</h1>
      <div class="row pt-5 justify-content-md-center">
        <div class="col-md-4">
          <div class="card">
            <img
              class="img-fluid"
              src="assets/img/benefits/Digital-Records.png"
              alt="Digital Accident book"
            />
            <div class="card-body">
              <p>{{ 'HOME.BENEFITS.BENEFIT_1' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img
              class="img-fluid"
              src="assets/img/benefits/Compliance-Reporting.png"
              alt="Compliance reporting"
            />
            <div class="card-body">
              <p>{{ 'HOME.BENEFITS.BENEFIT_2' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="card">
            <img
              class="img-fluid"
              src="assets/img/benefits/Real-time-Dashboard.png"
              alt="Incident analytics"
            />
            <div class="card-body">
              <p>{{ 'HOME.BENEFITS.BENEFIT_3' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img
              class="img-fluid"
              src="assets/img/benefits/Faster-Response.png"
              alt="Rapid reporting & resolution"
            />
            <div class="card-body">
              <p>{{ 'HOME.BENEFITS.BENEFIT_4' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <img
              class="img-fluid"
              src="assets/img/benefits/Organised-Workflow.png"
              alt="Better Health & Safety environment"
            />
            <div class="card-body">
              <p>{{ 'HOME.BENEFITS.BENEFIT_5' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- how it works  -->
  <section id="about" class="section">
    <div class="container">
      <h1 class="section-title">{{ 'HOME.MENU.HOW_IT_WORKS' | translate }}</h1>
      <div class="row">
        <div class="col-md-8">
          <ul class="timeline">
            <li class="content">
              <span class="checkmark">
                <div class="checkmark_stem"></div>
                <div class="checkmark_kick"></div>
              </span>
              <span>
                <p class="how-it-works-title">{{ 'HOME.PROCESS.PROCESS_SELECT' | translate }}</p>
                <p class="how-it-works-desc">
                  {{ 'HOME.PROCESS.PROCESS_SELECT_ORGANISATION' | translate }}
                </p>
              </span>
            </li>
            <li class="content">
              <span class="checkmark" style="top: auto">
                <div class="checkmark_stem"></div>
                <div class="checkmark_kick"></div>
              </span>
              <span>
                <p class="how-it-works-title">{{ 'HOME.PROCESS.PROCESS_SNAP' | translate }}</p>
                <p class="how-it-works-desc">{{ 'HOME.PROCESS.PROCESS_SNAP_DESC' | translate }}</p>
              </span>
            </li>
            <li>
              <span class="checkmark" style="top: auto">
                <div class="checkmark_stem"></div>
                <div class="checkmark_kick"></div>
              </span>
              <span>
                <p class="how-it-works-title">{{ 'HOME.PROCESS.PROCESS_SUBMIT' | translate }}</p>
                <p class="how-it-works-desc">
                  {{ 'HOME.PROCESS.PROCESS_SUBMIT_DESC' | translate }}
                </p>
              </span>
            </li>
          </ul>
          <button class="demo-btn mb-5 mb-md-0" [routerLink]="['']" fragment="demo">
            <a href="#demo" class="smooth-scroll">
              {{ 'HOME.PROCESS.TRYDEMO' | translate }}
            </a>
          </button>
        </div>
        <div class="col-md-4">
          <div
            class="owl-carousel owl-theme"
            data-nav="true"
            data-loop="true"
            data-margin="30"
            data-slideby="1"
            data-stagepadding="18"
            data-items-xs="1"
            data-items-sm="1"
            data-items-md="1"
            data-items-lg="1"
            data-autoplay="true"
          >
            <picture>
              <source srcset="assets/landing-page/images/screens/1.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/1.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/2.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/2.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/3.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/3.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/4.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/4.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/5.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/5.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/6.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/6.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
            <picture>
              <source srcset="assets/landing-page/images/screens/7.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/screens/7.png"
                alt="how incidentreporter365 works"
                class="img-fluid"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- testimonial  -->
  <section id="testimonials" class="section mobile-hidden pb-0">
    <div class="container">
      <h1 class="section-title">{{ 'HOME.TESTIMONIALS.TESTIMONIALS_TITLE' | translate }}</h1>
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div
            class="owl-carousel owl-theme owl-dark"
            data-autoplay="true"
            data-autoheight="true"
            data-loop="true"
            data-items="1"
          >
            <div class="item testimonial-content">
              <p class="description">“{{ 'HOME.TESTIMONIALS.TESTIMONIALS_DESC2' | translate }}”</p>
              <strong class="author">{{
                'HOME.TESTIMONIALS.TESTIMONIALS_AUTHOR2' | translate
              }}</strong>
              <p class="department">
                {{ 'HOME.TESTIMONIALS.TESTIMONIALS_DEPARTMENT2' | translate }}
              </p>
            </div>
            <div class="item testimonial-content">
              <p class="description">“{{ 'HOME.TESTIMONIALS.TESTIMONIALS_DESC' | translate }}”</p>
              <strong class="author">{{
                'HOME.TESTIMONIALS.TESTIMONIALS_AUTHOR' | translate
              }}</strong>
              <p class="department">
                {{ 'HOME.TESTIMONIALS.TESTIMONIALS_DEPARTMENT' | translate }}
              </p>
            </div>
            <div class="item testimonial-content">
              <p class="description">“{{ 'HOME.TESTIMONIALS.TESTIMONIALS_DESC1' | translate }}”</p>
              <strong class="author">{{
                'HOME.TESTIMONIALS.TESTIMONIALS_AUTHOR1' | translate
              }}</strong>
              <p class="department">
                {{ 'HOME.TESTIMONIALS.TESTIMONIALS_DEPARTMENT1' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- demo  -->
  <section id="demo" class="section">
    <div class="container">
      <div class="row" no-gutters *ngIf="auth.activeForm === 'login'">
        <div class="col-md-6">
          <picture class="align-center d-none d-md-block">
            <source srcset="assets/img/icons/Demo.webp" type="image/webp" />
            <img src="assets/img/icons/Demo.png" alt="incidentreporter365 demo" />
          </picture>
        </div>
        <div class="col-md-6 text-center text-lg-left">
          <h2 class="section-title demo-title">
            {{ 'HOME.MENU.REQUEST_DEMO' | translate }}
          </h2>
          <p class="demo-description">
            {{ 'HOME.CONTACTUS.CONTACT_DETAILS_INFO' | translate }}
          </p>
          <form
            id="contact-form"
            #contactForm="ngForm"
            ngNativeValidate
            (submit)="submitDemoRequest(contactForm)"
            class="form-border-light"
            autocomplete="off"
          >
            <div class="row my-3">
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    name="name"
                    type="text"
                    #name="ngModel"
                    class="form-control"
                    required
                    placeholder="Name *"
                    [(ngModel)]="user.Name"
                  />
                  <div
                    *ngIf="!isValidName()"
                    class="alert alert-danger"
                    style="height: 24px; padding: 0px"
                  >
                    <p>{{ 'HOME.MENU.NAME_ERROR' | translate }}</p>
                  </div>
                  <div
                    *ngIf="name.touched && !name.value"
                    class="alert alert-danger"
                    style="height: 24px; padding: 0px"
                  >
                    <p>{{ 'HOME.MENU.NAME_REQUIRED' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    name="email"
                    type="email"
                    #email="ngModel"
                    class="form-control"
                    required
                    placeholder="Email *"
                    [(ngModel)]="user.Email"
                  />
                  <div
                    *ngIf="!isValidEmail()"
                    class="alert alert-danger"
                    style="height: 24px; padding: 0px"
                  >
                    <p>{{ 'HOME.MENU.EMAIL_ERROR' | translate }}</p>
                  </div>
                  <div
                    *ngIf="email.touched && !email.value"
                    class="alert alert-danger"
                    style="height: 24px; padding: 0px"
                  >
                    <p>{{ 'HOME.MENU.EMAIL_REQUIRED' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <div class="form-group">
                    <select
                      name="country"
                      class="form-control"
                      #country="ngModel"
                      [ngClass]="{ 'placeholder-option': !user.Country }"
                      [(ngModel)]="user.Country"
                      placeholder="Country"
                      required
                    >
                      <option value="" class="placeholder-option" selected>
                        {{ 'HOME.CONTACTUS.COUNTRY' | translate }} *
                      </option>
                      <option *ngFor="let country of countryList" [value]="country.CountryName">
                        {{ country.CountryName }}
                      </option>
                    </select>
                    <div
                      *ngIf="country.touched && country.invalid"
                      class="alert alert-danger custom-padding"
                    >
                      <p>{{ 'HOME.MENU.COUNTRY_REQUIRED' | translate }}</p>
                    </div>
                  </div>

                  <input
                    type="text"
                    name="industry"
                    class="form-control"
                    [(ngModel)]="user.IndustryType"
                    placeholder="{{ 'HOME.MENU.INDUSTRY_PLACE_HOLDER' | translate }}"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <select
                    name="countryCode"
                    class="form-control"
                    #countryCode="ngModel"
                    [(ngModel)]="user.CountryCode"
                    placeholder="Country code"
                    [ngClass]="{ 'placeholder-option': !user.CountryCode }"
                    required
                  >
                    <option value="" class="placeholder-option" disabled>
                      {{ 'HOME.CONTACTUS.COUNTRY_CODE' | translate }} *
                    </option>
                    <option *ngFor="let country of countryCodes" value="{{ country.dial_code }}">
                      {{ country.name }} ({{ country.dial_code }})
                    </option>
                  </select>
                  <div
                    *ngIf="countryCode.touched && countryCode.invalid"
                    class="alert alert-danger custom-padding"
                  >
                    <p>{{ 'HOME.MENU.COUNTRY_CODE_ERROR' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="tel"
                    name="phone"
                    #phone="ngModel"
                    class="form-control"
                    style="padding: 6px 12px 6px 0"
                    [(ngModel)]="user.PhoneNumber"
                    placeholder="{{ 'HOME.MENU.PLACE_HOLDER' | translate }} *"
                    pattern="^[1-9][0-9]{8,9}$"
                  />
                  <div *ngIf="!isValidPhoneNumber()" class="alert alert-danger custom-padding">
                    <p>{{ 'HOME.MENU.MOBILE_NUMBER_ERROR' | translate }}</p>
                  </div>
                  <div
                    *ngIf="phone.touched && !phone.value"
                    class="alert alert-danger custom-padding"
                  >
                    <p>{{ 'HOME.MENU.PHONE_NUMBER_REQUIRED' | translate }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <textarea
                name="message"
                class="form-control"
                placeholder="Your message........ "
                [(ngModel)]="user.Message"
                style="height: 37px; margin-top: -10px; white-space: pre-line"
              ></textarea>
            </div>
            <re-captcha
              [(ngModel)]="contactForm.captcha"
              name="captcha"
              required
              siteKey="6LcHBtIZAAAAABOhAosarmLCINL4JMjGs5RuTAv8"
              #captchaControl="ngModel"
              class="re-captcha"
            >
            </re-captcha>
            <p class="mt-3 mb-0">
              <button
                [disabled]="contactForm.invalid"
                id="submit-btn"
                class="demo-btn ml-0 mt-3"
                type="submit"
              >
                {{ 'HOME.PROCESS.PROCESS_SUBMIT' | translate }}
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- footer  -->
  <app-web-footer></app-web-footer>
</main>
