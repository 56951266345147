import { Component } from '@angular/core';
import { Router } from '@angular/router';
import SetTitleService from '../../containers/default-layout/services/set-title.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export default class ReportsComponent {
  constructor(
    private titleService: SetTitleService,
    private router: Router,
  ) {
    const initialSate = {
      heading: 'Reports',
      content: [
        {
          subHead: 'Monthly Reports',
          points: [
            'User will be able to see the monthly trend of the incidents for the selected duration. The report shows minimum and maximum resolution time in the selected duration for the selected incident type.',
          ],
        },
        {
          subHead: 'Overall Average Resolution Time',
          points: [
            'The reports will be showing average resolution time for all the incident types. Users are able to choose the incident type as per their need and check the trend.',
            'User will be able to see trend of the incidents. The report shows minimum and maximum resolution time in the selected duration for the selected incident type.',
          ],
        },
      ],
    };
    this.titleService.setTitle(
      '<i class="fa fa-area-chart" aria-hidden="true"></i>',
      'DASHBOARD_MENU.REPORTS',
      '<button class="heading-btn"><i class="fa fa-question-circle"></i></button>',
      initialSate,
    );
  }

  goToGraphPage(graphType) {
    if (graphType === 'monthly_resolution') {
      this.router.navigate(['reports/monthly_resolution']);
    } else if (graphType === 'overall_resolution') {
      this.router.navigate(['reports/overall_resolution']);
    } else {
      this.router.navigate([`reports/${graphType}`]);
    }
  }
}
