<footer id="footer" class="section footer-text-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 text-lg-left">
        <div class="mb-2">
          <a class="logo mb-4" href="index.html" title="Incidentreporter365">
            <picture>
              <source srcset="assets/landing-page/images/logo.webp" type="image/webp" />
              <img
                src="assets/landing-page/images/logo.png"
                alt="incidentreporter365 logo"
                class="nav-bar-logo"
              />
            </picture>
          </a>
        </div>
        <ul class="list-style-1">
          <li>
            <p class="footer-heading">Follow Us On</p>
          </li>
          <li class="">
            <p class="">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/incidentreporter365/?viewAsMember=true"
                class="social-media-icon ml-0"
                aria-label="Incidentreporter365 LinkedIn"
              >
                <i class="fa fa-linkedin icon pl-1"></i>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/ReportIncidents"
                class="social-media-icon"
                aria-label="Incidentreporter365 Twitter"
              >
                <i class="fa fa-twitter icon pl-1"></i>
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/incidentreporter365"
                class="social-media-icon"
                aria-label="Incidentreporter365 Facebook"
              >
                <i class="fa fa-facebook icon pl-1"></i>
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/incidentreporter365/"
                class="social-media-icon"
                aria-label="Incidentreporter365 Instagram"
              >
                <i class="fa fa-instagram icon pl-1"></i>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/watch?v=D9I9K5q0cNc"
                class="social-media-icon"
                aria-label="Incidentreporter365 Youtube"
              >
                <span class="icon pl-1 pr-1" style="font-family: FontAwesome">&#xf16a;</span>
              </a>
            </p>
          </li>
        </ul>
        <ul class="list-style-1 mt-5">
          <li>
            <p class="footer-heading">Legal Info</p>
          </li>
          <li class="icon-image">
            <p class="mb-0 d-inline-block">
              <a
                class="footer-text"
                target="_blank"
                href="https://incidentreporter365.com/privacy-policy.html"
                >Privacy Policy</a
              >
            </p>
          </li>
          <li class="icon-image">
            <p class="mb-0 d-inline-block">
              <a
                class="footer-text"
                target="_blank"
                href="https://incidentreporter365.com/legal-info.html#termsOfUse"
                >Terms Of Use</a
              >
            </p>
          </li>
        </ul>
      </div>

      <div class="col-lg-3 text-lg-left">
        <ul class="list-style-1">
          <li>
            <p class="footer-heading">DOWNLOADS</p>
          </li>

          <div class="content-left-align">
            <div>
              <a (click)="openPlayStore()" style="margin-left: -7px">
                <img
                  src="assets/img/googleplay.png"
                  alt="Download IR365 from playstore"
                  style="width: 230px; height: 100px; margin-right: 10px"
              /></a>
            </div>
          </div>

          <div class="content-left-align">
            <div>
              <a (click)="openAppStore()" style="margin-left: -7px">
                <img
                  src="assets/img/appstore.png"
                  alt="Download IR365 from App Store"
                  style="width: 230px; height: 100px"
              /></a>
            </div>
          </div>
        </ul>
      </div>

      <div class="col-lg-3 text-lg-left">
        <ul class="list-style-1">
          <li>
            <p class="footer-heading">CONTACT</p>
          </li>
          <li class="d-flex">
            <i class="mail mt-2"></i>
            <p class="mb-0">
              <a
                data-content="contact@incidentreporter365.com"
                href="mailto:contact@incidentreporter365.com"
                data-type="mail"
                class="footer-text"
              >
                contact@incidentreporter365.com</a
              >
            </p>
          </li>
          <li class="d-flex">
            <i class="phone"></i>&nbsp;
            <p class="mb-0">
              <a class="footer-text" href="tel:4402031514830">(UK) +44 (0) 20 3151 4830</a>
            </p>
          </li>
          <li class="d-flex">
            <i class="phone"></i>&nbsp;
            <p class="mb-0">
              <a class="footer-text" href="tel:914023401007">(India) +91 40 2340 1007</a>
            </p>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 text-lg-left">
        <address>
          <ul class="list-style-1">
            <li>
              <p class="footer-heading">{{ 'HOME.OFFICES.OFFICE_HEADING' | translate }}</p>
            </li>
            <li>
              <p class="text-4 font-weight-700 mb-0 footer-text">
                {{ 'HOME.OFFICES.OFFICE_LOCATION_NAME' | translate }}
              </p>
            </li>
            <li>
              <p class="footer-text">
                {{ 'HOME.OFFICES.OFFICE_ADDRESS_UK' | translate }} <br />{{
                  'HOME.OFFICES.OFFICE_ADDRESS_UK1' | translate
                }}
              </p>
            </li>
            <li>
              <p class="text-4 font-weight-700 mb-0 footer-text">
                {{ 'HOME.OFFICES.OFFICE_LOCATION_NAME_INDIA' | translate }}
              </p>
            </li>
            <li>
              <p class="footer-text">
                {{ 'HOME.OFFICES.OFFICE_ADDRESS_INDIA' | translate }}
              </p>
            </li>
            <li>
              <p class="text-4 font-weight-700 mb-0 footer-text">
                {{ 'HOME.OFFICES.OFFICE_LOCATION_NAME_CANADA' | translate }}
              </p>
            </li>
            <li>
              <p class="footer-text">
                {{ 'HOME.OFFICES.OFFICE_ADDRESS_CANADA' | translate }}
              </p>
            </li>
          </ul>
        </address>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12 text-center" style="border-top: 1px solid">
        <p class="text-3 footer-text">
          &copy; {{ year }} |
          {{ 'HOME.COPYWRITE.COPYWRITE_TITLE' | translate }}
          <a class="footer-text" target="_blank" href="https://osmosys.co/">{{
            'HOME.COPYWRITE.COMPANY_NAME' | translate
          }}</a
          >.
        </p>
      </div>
    </div>
  </div>
</footer>
