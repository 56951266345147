import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-web-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export default class FooterComponent implements OnInit {
  installFrom = '';

  deviceType = 'ok';

  year: number = moment().get('year');

  constructor(private deviceDetector: DeviceDetectorService) {
    // logic goes here
  }

  static openApp() {
    const deepLink = 'incidentreporter365://';
    window.location.href = deepLink.toString();
  }

  ngOnInit(): void {
    this.deviceType = this.deviceDetector.getDeviceInfo().device;
    if (this.deviceType === 'Android') {
      this.installFrom = 'Play Store';
    } else {
      this.installFrom = 'App Store';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  openPlayStore() {
    FooterComponent.openApp();
    setTimeout(() => {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.osmosys.incidentreporter&hl=en';
    }, 3000);
  }

  // eslint-disable-next-line class-methods-use-this
  openAppStore() {
    FooterComponent.openApp();
    setTimeout(() => {
      window.location.href = 'https://apps.apple.com/in/app/incident-reporter-365/id1513567560';
    }, 3000);
  }
}
