import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import EnvService from '../../../env.service';
import HelpModalDialogComponent from '../../../components/help-modal-dialog/help-modal-dialog.component';
import { BasicApiModel } from '../../../interfaces/global.interface';

interface DefaultNavigationModel extends BasicApiModel {
  Data?: {
    Id: number,
    ModuleName: string,
    IsMandatory: number,
    IsVisible: number
  }[]
}
@Injectable({
  providedIn: 'root',
})
export default class SetTitleService {
  constructor(
    private translate: TranslateService,
    private env: EnvService,
    private httpClient: HttpClient,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
  ) {
    // logic goes here
  }

  setTitle(icon, title, help?, initialSate?) {
    let pageTitle = '';
    if (title !== '') {
      pageTitle = this.translate.instant(title);
    }
    let htmlString;
    if (help) {
      htmlString = `${icon} <span>${pageTitle}</span>${help}`;
    } else {
      htmlString = `${icon} <span>${pageTitle}</span>`;
    }
    const finalTitle = document.querySelector('.heading-title');
    finalTitle.innerHTML = htmlString;
    const element = document.querySelector<HTMLElement>('.heading-btn');
    const iconEle = document.querySelector<HTMLElement>('.fa-question-circle');
    if (iconEle) {
      iconEle.style.margin = '4px 0px 0px 0px';
      iconEle.style.fontSize = '15px';
    }
    if (element) {
      element.style.border = 'none';
      element.style.color = '#3f6097';
      element.style.background = 'transparent';
      const self = this;
      element.onclick = () => {
        self.modalRef = self.modalService.show(HelpModalDialogComponent, {
          initialState: initialSate,
          class: 'modal-lg',
        });
        // self.modalRef.setClass()
      };
    }
  }

  getDefaultNavigation() {
    const API_URL = `${this.env.apiUrl}/Modules`;
    return this.httpClient
      .get(API_URL)
      .map((data: DefaultNavigationModel) => data)
      .catch((error: DefaultNavigationModel) => Observable.throw(error));
  }
}
