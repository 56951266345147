/* eslint-disable no-param-reassign */
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 *
 * @param monthNo starting from 1, ex: 1 for Jan, 12 for Dec
 * @returns
 */
export function getMonthNameFromNo(monthNo: number) {
  if (monthNo < 1 || monthNo > 12) {
    throw new Error(`Month No can't be outside range of 1-12. Given month is: ${monthNo}`);
  }

  const index = monthNo - 1; // reduce one to get correct index based value
  return months[index];
}

/**
 *
 * @param monthName starting from 1, ex: Jan, Feb, Dec etc
 * @returns 1 for Jan, 12 for Dec etc
 */
export function getMonthNoFromName(monthName: string) {
  const index = months.indexOf(monthName);
  if (index === -1)
    throw new Error(
      `Month Name isn't found in the list ${JSON.stringify(months)}, given month is ${monthName}`,
    );
  return index + 1; // +1 because, month no is greate than index
}

export function getUserNames(userlist) {
  userlist = userlist.filter((item) => {
    // SHIFTER TO FASTER API-It doen't return User status
    // if (item.Status === 'Active') {
    if (item.DisplayName.trim() === '' && item.MobileNumber !== '') {
      item.DisplayName = `${item.CountryCode} ${item.MobileNumber}`;
    }
    if (item.DisplayName === '' && item.MobileNumber === '') {
      item.DisplayName = item.UserUid;
    }
    return item;
    // }
  });
  return userlist;
}

export function getUserListForDropdowns(userlist) {
  return userlist.map((x) => {
    let itemName;
    if (x.FieldStaffName && x.FieldStaffName.trim() !== '') {
      itemName = x.FieldStaffName;
    } else if (x.CountryCode && x.MobileNumber) {
      itemName = `${x.CountryCode} ${x.MobileNumber}`;
    } else {
      itemName = x.UserUid;
    }
    return {
      id: x.UserId,
      itemName,
    };
  });
}

export function getUserListForDropdownsQuick(userlist) {
  return userlist.map((x) => {
    let itemName;
    if (x.DisplayName && x.DisplayName.trim() !== '') {
      itemName = x.DisplayName;
    } else if (x.CountryCode && x.MobileNumber) {
      itemName = `${x.CountryCode} ${x.MobileNumber}`;
    } else {
      itemName = x.Id;
    }
    return {
      id: x.Id,
      itemName,
    };
  });
}

export function getUserListsForNotificationModal(userlist) {
  return userlist.map((x) => {
    let itemName;
    if (x.DisplayName && x.DisplayName.trim() !== '') {
      itemName = x.DisplayName;
    } else if (x.CountryCode && x.MobileNumber) {
      itemName = `${x.CountryCode} ${x.MobileNumber}`;
    } else {
      itemName = x.UserUid;
    }
    return {
      id: x.Id,
      itemName,
    };
  });
}

// Calculate the maximum allowed date based on the selected fromDate
export function calculateMaxDate(fromDate): Date {
  let maxDate = new Date(fromDate);
  maxDate.setMonth(maxDate.getMonth() + 11);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  maxDate = maxDate > currentDate ? currentDate : maxDate; // Setting maxDate to the maximum allowed date
  return maxDate;
}

// Calculate the minimum allowed date based on the selected toDate
export function calculateMinDate(toDate): Date {
  const minDate = new Date(toDate);
  minDate.setMonth(minDate.getMonth() - 11); // Subtract 11 months to stay within 12-month range
  return minDate;
}
