import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import AppointmentsService from '../../services/appointments-http.service';
import constants from '../../../../common/constants';
import Organisation from '../../../organisations/models/organisation.model';
import OrganisationService from '../../../organisations/services/organisation-http.service';

@Component({
  selector: 'app-appointments-configuration',
  templateUrl: './appointments-configuration.component.html',
  styleUrls: ['./appointments-configuration.component.css'],
})
export default class AppointmentsConfigurationComponent implements OnInit {
  public event: EventEmitter<Object> = new EventEmitter();

  config;

  branchList: {
    Id: string;
    LocationName: string;
  }[];

  changedValue: number;

  locationName: {
    LocationName: string;
  }[];

  public UsersIncidentSiteList;

  isOrgOwner: string;

  showStartTimeSlotError = false;

  showEndTimeSlotError = false;

  globallySelectedOrg: Organisation;

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private appointmentsService: AppointmentsService,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.globallySelectedOrg = OrganisationService.getCurrentGlobalOrganisation();
    this.isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    this.UsersIncidentSiteList = JSON.parse(localStorage.getItem(constants.USER_INCIDENT_SITES));
    this.config = {};
    this.config.AutoApproval = true;
    this.config.SlotDuration = null;
    this.config.WorkingDays = [];
    this.loadOrganisationsList();
  }

  close() {
    this.bsModalRef.hide();
  }

  loadOrganisationsList() {
    this.config.OrganisationId = this.globallySelectedOrg.Id.toString();
    this.getIncidentLocations();
  }

  updateConfigChecker(event, locId) {
    this.locationName = this.branchList.filter(
      (ele) => parseInt(ele.Id, 10) === parseInt(locId, 10),
    );
    this.config.LocationName = this.locationName[0].LocationName;
    this.changedValue = parseInt(event.target.value, 10);
    this.bindConfig(this.changedValue);
  }

  getIncidentLocations() {
    const resp = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (
      this.UsersIncidentSiteList &&
      this.UsersIncidentSiteList.length !== 0 &&
      this.isOrgOwner !== 'true'
    ) {
      this.branchList = this.UsersIncidentSiteList.slice();
    } else {
      this.branchList = resp;
    }
    if (this.branchList !== null && this.branchList.length > 0) {
      this.config.LocationId = this.branchList[0].Id;
    }
    this.bindConfig(this.config.LocationId);
  }

  saveConfig() {
    const settings = { ...this.config };
    settings.StartTime = moment(settings.StartTime, 'hh:mm a').utc().format('HH:mm:ss');
    settings.EndTime = moment(settings.EndTime, 'hh:mm a').utc().format('HH:mm:ss');
    let sumOfWorkingDays = 0;
    settings.WorkingDays.forEach((element, index) => {
      if (element) {
        sumOfWorkingDays += 2 ** index;
      }
    });
    settings.WorkingDays = sumOfWorkingDays;
    if (settings.WorkingDays === 0) {
      this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.SELECT_WORKING_DAYS'));
      return;
    }
    if (settings.Id) {
      this.appointmentsService.updateAppointmentsConfig(settings).subscribe(
        (res) => {
          if (res.Status === 'Fail' || res.Status === 'Error') {
            this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_FAIL'));
            return;
          }
          this.close();
          this.bindConfig(this.config.LocationId);
          this.event.emit();
          this.toastr.success(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_SUCCESS'));
        },
        () => {
          this.bindConfig(this.config.LocationId);
          this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_FAIL'));
        },
      );
    } else {
      this.appointmentsService.saveAppointmentsConfig(settings).subscribe(
        (res) => {
          if (res.Status === 'Fail' || res.Status === 'Error') {
            this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_FAIL'));
            return;
          }
          this.close();
          this.bindConfig(this.config.LocationId);
          this.event.emit();
          this.toastr.success(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_SUCCESS'));
        },
        () => {
          this.bindConfig(this.config.LocationId);
          this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.SAVE_CONFIG_FAIL'));
        },
      );
    }
  }

  compareEndTimeSlots(config) {
    let startTime = config.StartTime.toUpperCase();
    let endTime = config.EndTime.toUpperCase();
    if (endTime.split(':')[0].length === 1) {
      endTime = `0${endTime}`;
    }
    if (startTime.split(':')[0].length === 1) {
      startTime = `0${startTime}`;
    }
    const timeCompare = endTime === startTime;
    if (timeCompare) {
      this.showEndTimeSlotError = true;
    } else {
      this.showStartTimeSlotError = false;
      this.showEndTimeSlotError = false;
    }
  }

  compareStartTimeSlots(config) {
    let startTime = config.StartTime.toUpperCase();
    let endTime = config.EndTime.toUpperCase();
    if (endTime.split(':')[0].length === 1) {
      endTime = `0${endTime}`;
    }
    if (startTime.split(':')[0].length === 1) {
      startTime = `0${startTime}`;
    }
    const timeCompare = endTime === startTime;
    if (timeCompare) {
      this.showStartTimeSlotError = true;
    } else {
      this.showStartTimeSlotError = false;
      this.showEndTimeSlotError = false;
    }
  }

  bindConfig(locationId) {
    this.config.LocationId = locationId;
    this.appointmentsService.getAppointmentsConfig(locationId).subscribe(
      (res) => {
        const appointmentSettings = res.Data.AppointmentSettings;
        if (appointmentSettings !== null && appointmentSettings.length > 0) {
          const filteredSettings = appointmentSettings.filter(
            (obj) => obj.OrganisationId === this.globallySelectedOrg.Id,
          );
          if (filteredSettings.length > 0) {
            const currentOrgSettings = filteredSettings[0];
            this.config.Id = currentOrgSettings.Id;
            this.config.StartTime = moment
              .utc(currentOrgSettings.StartTime, 'HH:mm:ss')
              .local()
              .format('hh:mm a');
            this.config.EndTime = moment
              .utc(currentOrgSettings.EndTime, 'HH:mm:ss')
              .local()
              .format('hh:mm a');
            this.config.AutoApproval = currentOrgSettings.AutoApproval;
            this.config.SlotDuration = currentOrgSettings.SlotDuration;
            this.config.AppointmentsLimitPerSlot = currentOrgSettings.AppointmentsLimitPerSlot;
            if (currentOrgSettings.WorkingDays) {
              this.config.WorkingDays = [];
              for (let i = 0; i < 7; i += 1) {
                // eslint-disable-next-line no-bitwise
                const val = (currentOrgSettings.WorkingDays & (2 ** i)) > 0;
                if (val) {
                  this.config.WorkingDays.push(true);
                } else {
                  this.config.WorkingDays.push(false);
                }
              }
            }
          }
        }
      },
      () => {
        // logic goes here
      },
    );
  }

  goBack() {
    this.close();
  }
}
