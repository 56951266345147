<div class="modal-body" style="text-align: center">
  <div class="row">
    <div class="col-12">
      <div class="dialog-icon">
        <i class="fa-lg mt-4" [class]="icon"> </i>
      </div>
      <div>
        <h3>
          {{ title }}&nbsp;<span *ngIf="subject" style="font-weight: bold">{{ subject }}</span>
        </h3>
        <h5 class="text-muted" [innerHTML]="subText"></h5>
      </div>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-sm-12 m-b-xs">
      <button type="submit" class="btn btn-primary btn-lg btn-block" (click)="onConfirm()">
        {{ yesText }}
      </button>
      <button
        type="submit"
        class="btn btn-secondary btn-lg btn-block"
        (click)="onCancel()"
        *ngIf="noText"
      >
        {{ noText }}
      </button>
    </div>
  </div>
</div>
