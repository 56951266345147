<div class="container-fluid">
  <div class="nav-row pr-4">
    <div class="d-flex">
      <div class="nav-brand">
        <img
          src="../../../assets/img/brand/incident_reporter_logo.png"
          alt="incident reporter 365"
          class="logo"
        />
      </div>
      <button (click)="OnToggle()" class="hamburger-toggle">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
    </div>
    <button class="btn btn-primary" (click)="backToHome()">
      <i class="fa fa-chevron-left mr-1" aria-hidden="true"></i>
      <span class="d-none d-sm-inline">Back to Home</span>
    </button>
  </div>
  <div>
    <div>
      <nav id="sidebarMenu" class="d-lg-block sidebar bg-white">
        <div class="sidebar-sticky">
          <h2 class="ml-4 mt-4">7 Releases</h2>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.5.0']"
              routerLinkActive="active"
              (click)="onClick('v450')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.5.0</h5>
                <span class="date">021/09/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.4.0']"
              routerLinkActive="active"
              (click)="onClick('v440')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.4.0</h5>
                <span class="date">06/09/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.3.0']"
              routerLinkActive="active"
              (click)="onClick('v430')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.3.0</h5>
                <span class="date">18/08/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.2.0']"
              routerLinkActive="active"
              (click)="onClick('v420')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.2.0</h5>
                <span class="date">01/08/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.1.0']"
              routerLinkActive="active"
              (click)="onClick('v410')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.1.0</h5>
                <span class="date">20/07/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v4.0.0']"
              routerLinkActive="active"
              (click)="onClick('v400')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 4.0.0</h5>
                <span class="date">08/06/2023</span>
              </div>
            </a>
          </div>

          <div class="list-group list-group-flush mx-3 mt-4">
            <a
              [routerLink]="['/release-versions/v3.9.0']"
              routerLinkActive="active"
              (click)="onClick('v390')"
              class="list-group-item list-group-item-action py-2 ripple"
              aria-current="true"
            >
              <div class="link d-flex">
                <h5>Release 3.9.0</h5>
                <span class="date">05/04/2023</span>
              </div>
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="content-container sidebar-padding" #container>
      <section id="v450" class="release-section">
        <h2>Release 4.5.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 21st Sept 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Incident preferences⭐</h6>
                <p>
                  In the new release ,user can save their incidents and report directly from the
                  preferences.
                </p>
                <a href="../../../assets/img/features/Preferences.png">
                  <img
                    style="width: 100px; height: 180px; object-fit: contain"
                    src="../../../assets/img/features/Preferences.png"
                    alt="Training"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2.Support💬</h6>
                <p>
                  In our latest release, users can now raise tickets to track and resolve issues,
                  and they can also reach out via email for support.
                </p>
                <a href="../../../assets/img/features/email-support.jpg">
                  <img
                    style="width: 100px; height: 180px; object-fit: contain"
                    src="../../../assets/img/features/email-support.jpg"
                    alt="Training"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="v440" class="release-section">
        <h2>Release 4.4.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 6th Sept 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Help points 💁</h6>
                <p>
                  Now you can see help points related to functionality of a particular module. Just
                  click on the question mark icon beside heading of the module to see key points
                  related to that module in a popup.
                </p>
                <a href="../../../assets/img/features/Help_Points.png">
                  <img
                    style="width: 300px; height: 180px"
                    src="../../../assets/img/features/Help_Points.png"
                    alt="Help"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Upload banner 🖼️</h6>
                <p>
                  We have added a feature to upload organisation banner in edit organisation page.
                  Upload banner of your choice for your organisation so that it can be added on all
                  the PDFs that are generated from portal.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Courses 📖</h6>
                <p>
                  In the new release , users can access and review the courses they have enrolled in
                </p>
                <a href="../../../assets/img/features/Courses.png">
                  <img
                    style="width: 100px; height: 180px; object-fit: contain"
                    src="../../../assets/img/features/Courses.png"
                    alt="Training"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section id="v430" class="release-section">
        <h2>Release 4.3.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 18th Aug 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Asset filter 🛠️</h6>
                <p>Now you can filter the assets based on asset type.</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Schedule maintenance 📅</h6>
                <p>Maintenance of assets becomes easy with our new maintenance schedule feature.</p>
                <a href="../../../assets/img/features/Maintenance.png">
                  <img
                    style="width: 300px; height: 180px"
                    src="../../../assets/img/features/Maintenance.png"
                    alt="Maintenance"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Training Module 🎓</h6>
                <p>
                  This module is designed to help organizations in employee development, skill
                  enhancement, and knowledge dissemination. With the Training Module, we're
                  committed to helping organizations cultivate a culture of continuous learning and
                  growth, ensuring they stay competitive in today's rapidly evolving landscape.
                </p>
                <a href="../../../assets/img/features/Training.png">
                  <img
                    style="width: 300px; height: 180px"
                    src="../../../assets/img/features/Training.png"
                    alt="Training"
                  />
                </a>
              </div>
            </div>
          </div>
          <hr *ngIf="forMobile && forPortal" />
          <div class="releaseNote" *ngIf="forMobile">
            <h5>Mobile Updates</h5>
            <div class="features_list row">
              <div class="col-lg-6">
                <div class="feature">
                  <h6>1. Access without location 🌍</h6>
                  <p>
                    Location permission is not mandatory now unless you want to report an incident.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Feed visible for APP users</h6>
                <p>
                  In the new release , the APP users can access the incidents of the organisations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section id="v420" class="release-section">
        <h2>Release 4.2.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 1st Aug 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Adding sites and allowing for all the users</h6>
                <p>
                  In the new release , the user can assign newily created site to all the users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Feed visible for APP users</h6>
                <p>
                  In the new release , the APP users can access the incidents of the organisations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>
      <section id="v410" class="release-section">
        <h2>Release 4.1.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 20th July 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Industry Type in Registration Page🏭</h6>
                <p>
                  We've added an industry type selection to the registration page to create default
                  incident types based on the chosen industry. we can now customize incident types
                  to match specific industries. This will make the demo experience more relevant and
                  helpful for users.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2.Deleting notifications 🗑️</h6>
                <p>
                  In our latest release, we have implemented a new feature that allows users to
                  delete notifications. This functionality provides a convenient way to remove
                  notifications within the portal.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>3.Updates in Incident History📜</h6>
                <p>
                  Any changes made in the edit incidents page, like reported user, time, location,
                  and other details, will be automatically updated in the incident history. This
                  keeps the history accurate and up-to-date.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>4.Notifications📂</h6>
                <p>
                  We've upgraded the notification feature, allowing users to send various types of
                  notifications and attach files in different formats.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1.Notifications📂</h6>
                <p>
                  We've upgraded the notification feature, allowing users to send various types of
                  notifications and attach files in different formats.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2.Incident Statistics and Filtering</h6>
                <p>
                  In our latest release, we've added comprehensive incident statistics to the
                  dashboard. Users can now see the total number of incidents, along with counts for
                  open and resolved incidents.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>3.Introducing WhatsApp OTP Support 📲</h6>
                <p>
                  We've added a new feature that allows users to receive their OTP through WhatsApp
                  for the login.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section id="v400" class="release-section">
        <h2>Release 4.0.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 08th June 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Confirmation popup before sending notifications ✅</h6>
                <p>
                  The upcoming release will introduce a new functionality that aims to enhance the
                  user experience. Prior to sending notifications, users will be prompted with a
                  confirmation popup displaying all the entered data. This added step is designed to
                  prevent the inadvertent sending of incorrect notifications by ensuring users have
                  the opportunity to review and confirm the information they have provided.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Introducing a new user type filter! ✨</h6>
                <p>
                  A new user type filter has been added to the Users module which lets users filter
                  out the data in the Users table. With this new feature, users can now display
                  specifc users based on the following types - app users, field staffs and portal
                  users. This allows users to handle data more effectively and monitor and manage
                  user permissions in an efficient manner.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>3. A new feature to add multiple sites in assets 🎰</h6>
                <p>
                  We are excited to share that a new featire has been added which lets users create
                  an asset for more than one sites. An asset which could previously be added to only
                  one site can now be added to multiple sites with the multiselect dropdown
                  functionality. Any of your old assets can also be updates to have more than one
                  sites assigned to them. Check out this brand new update now!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>4. New updates in dashboard module 📊</h6>
                <p>
                  With the latest portal update, the total statistics in the dashboard module which
                  shows total app users count, portal users count, open incidents and resolved
                  incidents is now connected with sites. This allows users to check the total number
                  of users and status of the incidents in a particular site or multiple sites, all
                  at once. Users can now get information about multiple sites from the dashboard.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>
                  5. Introducing image attachments and associated documents for more modules 🖼️
                </h6>
                <p>
                  Our latest release brings a new and exciting update that lets the IR365 user to
                  attach images and important related documents in two new modules - sites module
                  and assets module. Got any sites images or reference asset images to easily
                  differenciate between the assets, any documentation about management of assets, or
                  any required site related documentation to link with you organisation sites -
                  IncidentReporter365 has got you covered.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Introducing more filters in the app ✅</h6>
                <p>
                  Our latest release introduces another filter for fieldstaffs of you organization.
                  Fieldstaffs can now filter incidents in the organizations they are a part of -
                  check updates based on incident status, incident type, who the incident was
                  assigned to and much more! This feature enables field staffs to quickly filter
                  incidents as the management becomes much more efficient and saves the overall time
                  to resolve the incident. Update the latest IncidentReporter365 app and check out
                  this feature.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Confirmation of mobile number before sending OTP 📳</h6>
                <p>
                  In the upcoming release, we are implementing an additional layer of security by
                  introducing an alert popup to verify users' mobile numbers before sending a
                  one-time password (OTP). This proactive measure is intended to prevent any
                  inadvertent sending of messages to incorrect numbers, ensuring that the OTP is
                  delivered to the intended recipient.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>3. View organisations from your country on top 🏭</h6>
                <p>
                  In the forthcoming release, we have implemented a new feature that aims to enhance
                  the user experience by prioritizing organisations based on the country code. Users
                  will now be presented with organisations that share the same country code as a top
                  priority, followed by other organisations subsequently. This sorting mechanism
                  ensures a streamlined and efficient workflow for users, allowing them to easily
                  locate and engage with relevant organisations within their designated country.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>4. Display name is changed to First name and Last name 🧑</h6>
                <p>
                  In the latest update, new users logging in with their mobile numbers will be
                  prompted to set their profile name. This will be facilitated through an alert
                  popup, providing users with the opportunity to specify their desired profile name.
                  This added functionality ensures that users can personalize their profiles and
                  establish a distinct identity within the platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </section>

      <section id="v390" class="release-section">
        <h2>Release 3.9.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 05th April 2023</p>
        </div>
        <h3>🚀 What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Now incident types and sites are linked! 🔗</h6>
                <p>
                  Announcing our latest feature - site-specific incident types in
                  IncidentReporter365. With this feature, users can link incident types with
                  specific sites, allowing them to see incident types based on the site selected in
                  various areas of the application. This feature provides greater flexibility and
                  accuracy in incident reporting and management, with portal users able to filter
                  incidents and see dashboard reports based on the specific incident types linked to
                  a particular site. Users can also select all or specific sites to link with a
                  particular incident type. Portal users can view the list of linked incident types
                  in the site screen, providing a clear overview of the site-specific incident
                  types. Additionally, users can filter the heatmap and provide user access based on
                  the site-specific incident types. This new feature is now available in our latest
                  release.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>2. Enhanced checklist functionality ✨</h6>
                <p>
                  We are excited to announce a new feature that allows users to select multiple
                  options when filling out MCQ-type questions in both the incident and investigation
                  checklists. With this enhancement, users can now select more than one option in
                  the checklist questions they fill out while reporting an incident, and field staff
                  can select multiple answers while filling out the investigation checklist. This
                  new feature improves the flexibility and accuracy of the reporting and
                  investigation processes, providing users with more options and allowing them to
                  provide more detailed information.
                </p>
                <p>
                  When an MCQ-type question is being used, we have added a checkbox option for
                  multiselect to enable this new feature. This feature is now available in our
                  latest release!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>3. Introducing a new status filter for incidents table ✅</h6>
                <p>
                  Our latest release introduces a new status filter for incident tables in
                  IncidentReporter365. This feature enables users to quickly filter incidents by
                  status (open, resolved, or closed) and export the filtered data. With this new
                  feature, incident management becomes more efficient, saving users time and effort.
                  Experience the ease of managing incidents with our new status feature today!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>4. Introducing assignment of asset types for old & new assets 📌</h6>
                <p>
                  A brand new feature 'Asset types' has been introduced in the IR portal. With this,
                  users can create new asset categories or add existing asset categories to their
                  organization assets and then assign them to the respective incident types. The
                  portal will automatically filter the user's assets while reporting an incident,
                  based on their ncident type selection. This makes resporting of incidents and
                  management of assets much easier and hassle free. Try the new update now!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>5. Important feature update regarding incident description field ⚠️</h6>
                <p>
                  An important thing to note is - the incident description field that used to be in
                  report incident page and edit incident page has been moved to checklists. All the
                  old incident description will now be visible as a checklist question and answer.
                  If users want to have incident description for some incident type - they can add
                  it from the setup module. This will allow users to remove incident description
                  field if it is not required for a certain incident type.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>6. New ability it edit organization data and change organization owner.</h6>
                <p>
                  Another important and extreamly useful update that everyone has been waiting for
                  is here. Our IR365 users now have the ability to update organization details.
                  Users can add or modify details like - Organization Name, Organisation type,
                  Address, Organisation icon, Owner of the organisation and of course the geofence
                  of your organization. Explore all the new changes now!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>
                  5. The new release brings, not one, but two login methods for our IR365 Users! 3️⃣
                </h6>
                <p>
                  The new 3.9 release brings about another amazing update - before users could only
                  login into the IR365 app with Mobile number and the OTP received on their mobile
                  device. But with the new release, users will be able to login into the IR app
                  using a unique user id & password. Try out this new feature with this latest
                  release.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal" />
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list row">
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Introducing a new status filter in feed for field staffs ✅</h6>
                <p>
                  Our latest release introduces a new status filter for the feed in
                  IncidentReporter365 app. This feature enables field staffs to quickly filter
                  incidents by status (open, resolved, or closed). With this new feature, incident
                  management becomes more efficient, saving users time and effort. Experience the
                  ease of managing incidents with our new status feature today!
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <div class="feature">
                  <h6>2. Enhanced checklist functionality ✨</h6>
                  <p>
                    Announcing a new feature that allows users to select multiple options when
                    filling out MCQ-type questions in both the incident and investigation
                    checklists. With this enhancement, users can now select more than one option in
                    the checklist questions they fill out while reporting an incident, and field
                    staff can select multiple answers while filling out the investigation checklist.
                  </p>
                  <p>
                    This feature is available for MCQ question for which multiselect is enabled from
                    portal. This feature is now available in our latest release.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>1. Now incident types and sites are linked! 🔗</h6>
                <p>
                  Announcing our latest feature - site-specific incident types in
                  IncidentReporter365. With this feature, app users will be able to select incident
                  types based on the sites they selected while reporting an incident through
                  IncidentReporter365 app.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="feature">
                <h6>4. Introducing Dashboard in IR365 App 📊</h6>
                <p>
                  With the new release, dashboard has now been added to IR356 mobile application as
                  well for both android and IOS users. With this features, fieldstaff users can now
                  get an insight on the reported incidents with incident status and incident count
                  graphs. Now fieldstaff users can manage incidents more efficiently and
                  effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section id="v380" class="release-section mt-5">
        <h2>Release 3.8.0</h2>
        <div class="releaseInfo d-flex">
          <img src="../../../assets/img/avatars/Avatar.png" alt="" />
          <p class="ml-3">Released on 14th March 2023</p>
        </div>
        <h3>🚀What's New</h3>
        <div class="releaseNote" *ngIf="forPortal">
          <h5>Portal Updates</h5>
          <div class="features_list">
            <div class="feature">
                <h6>1.Feature XYZ</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a libero dignissim, egestas ante eu, pellentesque sapien. Phasellus nec dui ac tellus tincidunt faucibus. Curabitur sodales dignissim urna, id dignissim orci laoreet vitae. Integer at magna eu nisl consequat pellentesque eu nec odio.</p>
                <a href="../../../assets/img/features/Feature.png">
                  <img src="../../../assets/img/features/Feature.png" alt="feature">
                </a>
            </div>
            <div class="feature">
                <h6>2.Feature XYZ</h6>
                <p>Etiam blandit sem arcu, sit amet cursus arcu porta ac. Etiam auctor tellus at lectus molestie dignissim. Curabitur venenatis ut sem in tempor. Praesent porttitor nisi at erat pharetra lobortis. Cras magna lacus, vehicula eu luctus a, vehicula at magna. Proin nulla nibh, tristique in quam sed, sollicitudin ornare mauris. Sed faucibus, neque et congue pretium, sem lacus sodales nisi, sed iaculis erat mi a neque.</p>
                <a href="../../../assets/img/features/Feature.png">
                <img src="../../../assets/img/features/Feature.png" alt="">
                </a>
            </div>
            <div class="feature">
                <h6>3.Feature XYZ</h6>
                <p>Nunc tincidunt arcu orci, sit amet commodo lorem lacinia a. Aenean non tristique quam. Ut vitae efficitur nibh, ultrices viverra augue. Etiam porta, turpis sed tempor tristique, justo quam ultrices quam, eget accumsan quam arcu in nisi. Nunc scelerisque imperdiet odio ac ornare. Aliquam congue sagittis eros, quis scelerisque dolor suscipit at. Sed non laoreet felis. Nullam egestas pretium nibh, vitae mattis est pretium at.</p>
                <a href="../../../assets/img/features/Feature.png">
                  <img src="../../../assets/img/features/Feature.png" alt="">
                </a>
            </div>
          </div>
        </div>
        <hr *ngIf="forMobile && forPortal">
        <div class="releaseNote" *ngIf="forMobile">
          <h5>Mobile Updates</h5>
          <div class="features_list">
            <div class="feature">
                <h6>1.Feature XYZ</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a libero dignissim, egestas ante eu, pellentesque sapien. Phasellus nec dui ac tellus tincidunt faucibus. Curabitur sodales dignissim urna, id dignissim orci laoreet vitae. Integer at magna eu nisl consequat pellentesque eu nec odio.</p>
                <a href="../../../assets/img/features/Feature.png">
                  <img src="../../../assets/img/features/Feature.png" alt="feature">
                </a>
            </div>
            <div class="feature">
                <h6>2.Feature XYZ</h6>
                <p>Etiam blandit sem arcu, sit amet cursus arcu porta ac. Etiam auctor tellus at lectus molestie dignissim. Curabitur venenatis ut sem in tempor. Praesent porttitor nisi at erat pharetra lobortis. Cras magna lacus, vehicula eu luctus a, vehicula at magna. Proin nulla nibh, tristique in quam sed, sollicitudin ornare mauris. Sed faucibus, neque et congue pretium, sem lacus sodales nisi, sed iaculis erat mi a neque.</p>
                <a href="../../../assets/img/features/Feature.png">
                <img src="../../../assets/img/features/Feature.png" alt="">
                </a>
            </div>
            <div class="feature">
                <h6>3.Feature XYZ</h6>
                <p>Nunc tincidunt arcu orci, sit amet commodo lorem lacinia a. Aenean non tristique quam. Ut vitae efficitur nibh, ultrices viverra augue. Etiam porta, turpis sed tempor tristique, justo quam ultrices quam, eget accumsan quam arcu in nisi. Nunc scelerisque imperdiet odio ac ornare. Aliquam congue sagittis eros, quis scelerisque dolor suscipit at. Sed non laoreet felis. Nullam egestas pretium nibh, vitae mattis est pretium at.</p>
                <a href="../../../assets/img/features/Feature.png">
                  <img src="../../../assets/img/features/Feature.png" alt="">
                </a>
            </div>
          </div>
        </div>
      </section>

      -->
    </div>
  </div>
</div>
