import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import OrganisationService from '../../organisations/services/organisation-http.service';
import { AssociatedDocumentAPI, DivisionList, SetupAPI } from '../models/setup.model';
import { Country } from '../../../interfaces/global.interface';

@Injectable({
  providedIn: 'root',
})
export default class SetupService {
  currentDivision = null;

  currentIntegration = null;

  currentPortalUser = null;

  currentFieldStaff = null;

  currentAppUser = null;

  currentUser = null;

  assetDetails = null;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  static getQueryParams(name, values) {
    let str = '&';
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  static downloadFile(filePath) {
    const a = document.createElement('a');
    a.href = filePath;
    a.download = filePath.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  getDivisionList(sites?) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Divisions`;
    if (orgId) {
      API_URL += `?OrgId=${orgId}`;
    }
    if (!Array.isArray(sites)) {
      API_URL += `&SiteId=${sites}`;
    }
    if (Array.isArray(sites) && sites.length > 0) {
      const SiteId = sites.map((x) => x.id);
      API_URL += SetupService.getQueryParams('SiteId', SiteId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: DivisionList) => data)
      .catch((error) => Observable.throw(error));
  }

  getAssetTypes() {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    // let API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Assets/AssetTypes`;
    const API_URL = `${this.env.apiUrl}/Assets/AssetTypes?OrgId=${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  getDivisionListByOrg(sites) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions?OrgId=${orgId}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const SiteId = sites.map((x) => x.id);
      API_URL += SetupService.getQueryParams('SiteId', SiteId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: DivisionList) => data)
      .catch((error) => Observable.throw(error));
  }

  getDivisionById(divisionId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${divisionId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  createDivision(division) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions`;
    return this.httpClient
      .post(API_URL, division)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  updateDivision(division, divisionId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${divisionId}`;
    return this.httpClient
      .put(API_URL, division)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  getOrganisationsList() {
    const API_URL = `${this.env.apiUrl}/Organisations`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  exportCategories() {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Divisions?Export=true&OrgId=${orgId}`;

    // TODO: Uncomment the following code when API accepts visible columns
    // if (Array.isArray(visibleColumns) && visibleColumns.length > 0) {
    //   API_URL += this.getQueryParams('Column', visibleColumns);
    // }
    return this.httpClient
      .get(API_URL)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  deleteSetUp(id) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${id}`;
    return this.httpClient
      .delete(API_URL)
      .map((data: SetupAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  uploadAssociatedDocument(formData, divisionId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions/${divisionId}/AssociatedDocument`;
    return this.httpClient
      .post(API_URL, formData)
      .map((data: AssociatedDocumentAPI) => data.Data)
      .catch((error) => Observable.throw(error));
  }

  getCountries() {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient
      .get(API_URL)
      .map((data: Country) => data)
      .catch((error) => Observable.throw(error));
  }
}
