import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';
import EnvService from '../../../env.service';

import constants from '../../../common/constants';
import OrganisationService from '../../organisations/services/organisation-http.service';
import { FieldStaffDataWithLocId } from '../../setup/models/setup.model';
import { HeatMapAPI } from '../../heatmap/models/heatmap.model';
import { IncidentGraphData } from '../models/incident.model';
import { PortalUsersList } from '../../users/models/portal-user.model';

@Injectable({
  providedIn: 'root',
})
export default class IncidentService {
  currentIncidentTypes: string = null;

  currentIncident: string = null;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  static processIncidentsForNav(apiURL, pageNumber, pageLength, incidents, totalRecordCount) {
    localStorage.setItem(constants.INCIDENTS_API_CURRENT_FILTER, apiURL);
    const incidentList = [];
    incidents.forEach((incident) => {
      incidentList.push({
        Id: incident.Id,
        OrganisationId: incident.OrganisationId,
      });
    });
    const incidentsForNav = {
      incidentList,
      totalRecordCount,
      pageNumber,
      pageLength,
      numOfPages:
        totalRecordCount % pageLength === 0
          ? totalRecordCount / pageLength
          : Math.ceil(totalRecordCount / pageLength),
    };
    localStorage.setItem(constants.INCIDENT_LIST_FOR_NAV, JSON.stringify(incidentsForNav));
  }

  static getQueryParams(name, values) {
    let str = '&';
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  static getQuerySingleParam(name, value) {
    let str = '&';
    str += `${name}=${value}`;
    return str;
  }

  static downloadFile(filePath) {
    const a = document.createElement('a');
    a.href = filePath;
    a.target = '_blank';
    a.download = filePath.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // TODO: Review and Remove these
  // getIncidentsList() {
  //   const API_URL = `${this.env.apiUrl}/Incidents`;
  //   return this.httpClient.get(API_URL).map((data) => {
  //     return data;
  //   }).catch((error) => {
  //     return Observable.throw(error);
  //   });
  // }

  /**
   * Called by the datatable whenever a page or page size changes
   * or a column is reoredered.
   * @param {any} params
   * @param {any} dtCallback
   */
  getIncidentsListPagination(params, pageNumber, dtCallback, requestObject) {
    const API_URL = `${this.env.apiUrl}/Incidents`;
    // if (Array.isArray(divisions) && divisions.length > 0) {
    //   const divisionIds = divisions.map(x => x.id);
    //   API_URL += this.getQueryParams('DivisionId', divisionIds);
    // }
    // if (Array.isArray(sites) && sites.length > 0) {
    //   const LocationId = sites.map(x => x.id);
    //   API_URL += this.getQueryParams('LocationId', LocationId);
    // }
    // if (Array.isArray(fieldstaff) && fieldstaff.length > 0) {
    //   const FieldStaffId = fieldstaff.map(x => x.id);
    //   API_URL += this.getQueryParams('AssignedTo', FieldStaffId);
    // }
    // if (incidentStatus && incidentStatus !== "") {
    //   API_URL += `${'&'}${'IncidentStatus'}=${incidentStatus}`;
    // }
    return this.httpClient
      .post(API_URL, requestObject)
      .map((data: { Data: { Incidents: string[], TotalNumberOfRecords: string } }) => {
        IncidentService.processIncidentsForNav(
          API_URL,
          pageNumber,
          params.length,
          data.Data.Incidents,
          data.Data.TotalNumberOfRecords,
        );
        dtCallback({
          recordsTotal: data.Data.TotalNumberOfRecords || 0,
          recordsFiltered: data.Data.TotalNumberOfRecords || 0,
          data: [],
        });
        return data;
      })
      .catch((error) => Observable.throw(error));
  }

  getIncidentById(incidentId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentHistoryById(incidentId, pageNumber, pageLimit, similarIncidentHistory) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/IncidentHistory?PageNumber=${pageNumber}&PageLimit=${pageLimit}&SimilarIncidentHistory=${similarIncidentHistory}`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  createHistoryItem(formData, incidentId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/IncidentHistory`;
    return this.httpClient
      .post(API_URL, formData)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  createIncident(incident) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents`;
    return this.httpClient
      .post(API_URL, incident)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  editUpdateIncident(incident, incidentId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}`;
    return this.httpClient
      .put(API_URL, incident)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  deleteIncident(Id) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${Id}`;
    return this.httpClient
      .delete(API_URL)
      .map((data: {
        Status: string;
      }) => data)
      .catch((error) => Observable.throw(error));
  }

  exportIncidents(
    visibleColumns,
    sites,
    divisions,
    reportedby,
    assignedTo,
    StartDate,
    EndDate,
    sortedColumn,
    sortedOrder,
    searchTerm,
    incidentStatus,
  ) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Incidents?Export=true&OrgId=${orgId}`;
    if (Array.isArray(visibleColumns) && visibleColumns.length > 0) {
      API_URL += IncidentService.getQueryParams('Column', visibleColumns);
    }
    if (Array.isArray(sites) && sites.length > 0) {
      API_URL += IncidentService.getQueryParams('LocationId', sites);
    }
    if (Array.isArray(divisions) && divisions.length > 0) {
      API_URL += IncidentService.getQueryParams('DivisionId', divisions);
    }
    if (Array.isArray(assignedTo) && assignedTo.length > 0) {
      API_URL += IncidentService.getQueryParams('AssignedTo', assignedTo);
    }
    if (StartDate && StartDate !== undefined) {
      API_URL += `&StartDate=${StartDate}`;
    }
    if (EndDate && EndDate !== undefined) {
      API_URL += `&EndDate=${EndDate}`;
    }
    if (incidentStatus && incidentStatus !== '') {
      API_URL += `${'&'}${'IncidentStatus'}=${incidentStatus}`;
    }
    API_URL += `&UserId=${reportedby}&SortingColumn=${sortedColumn}&SortingOrder=${sortedOrder}&SearchTerm=${searchTerm}`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  exportSingleIncident(incidentId) {
    const API_URL = `${this.env.apiUrl}/Incident/${incidentId}?Export=true`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getHeatmapData(sites, divId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Reports/Heatmap?OrgId=${orgId}&DivId=${divId}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += IncidentService.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: HeatMapAPI) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentStatusByDivData(sites, toDate, fromDate, divisions) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Reports/IncidentStatusReport?OrgId=${orgId}&FromDate=${fromDate}&ToDate=${toDate}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += IncidentService.getQueryParams('LocationId', LocationId);
    }
    if (Array.isArray(divisions) && divisions.length > 0) {
      API_URL += IncidentService.getQueryParams('DivisionId', divisions);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: IncidentGraphData) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentCountData(sites, fromDate, toDate, divisions) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Reports/IncidentCountReport?OrgId=${orgId}&StartDate=${fromDate}&EndDate=${toDate}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += IncidentService.getQueryParams('LocationId', LocationId);
    }
    if (Array.isArray(divisions) && divisions.length > 0) {
      API_URL += IncidentService.getQueryParams('DivisionId', divisions);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: IncidentGraphData) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentsForNavigation(isPrev, pageLength) {
    const url = localStorage.getItem(constants.INCIDENTS_API_CURRENT_FILTER);
    const formattedURL = new URL(url);
    const currentPageNumber = formattedURL.searchParams.get('PageNumber');
    const newPageNumber = isPrev ? +currentPageNumber - 1 : +currentPageNumber + 1;
    formattedURL.searchParams.set('PageNumber', newPageNumber.toString());
    return this.httpClient
      .get(formattedURL.toString())
      .map((data: { Data: { Incidents: string[]; TotalNumberOfRecords: string } }) => {
        IncidentService.processIncidentsForNav(
          formattedURL.toString(),
          newPageNumber,
          pageLength,
          data.Data.Incidents,
          data.Data.TotalNumberOfRecords,
        );
        return true;
      })
      .catch((error) => Observable.throw(error));
  }

  getAppUserList(siteId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/users?OrgId=${orgId}`;
    if (Array.isArray(siteId) && siteId.length > 0) {
      API_URL += IncidentService.getQueryParams('LocationId', siteId);
    }
    if (!Array.isArray(siteId) && siteId !== null && siteId !== '' && siteId !== undefined) {
      API_URL += IncidentService.getQuerySingleParam('LocationId', siteId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: PortalUsersList) => data)
      .catch((error) => Observable.throw(error));
  }

  // TODO: Use the existing function "getUserListForFilter" and manipululate it to take site
  // getAppUserListQuick(siteId) {
  //   const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
  //   let API_URL = `${this.env.apiUrl}/AppFilterUsers?Role=subscriber&OrgId=${orgId}`;
  //   if (siteId !== null && siteId !== '' && siteId !== undefined) {
  //     const LocationId = siteId;
  //     API_URL += this.getQuerySingleParam('LocationId', LocationId);
  //   }
  //   return this.httpClient.get(API_URL).map((data) => {
  //     return data;
  //   }).catch((error) => {
  //     return Observable.throw(error);
  //   });
  // }

  // eslint-disable-next-line default-param-last
  getUserListForFilter(role = 'subscriber', siteId?) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/AppFilterUsers?Role=${role}&OrgId=${orgId}`;
    if (Array.isArray(siteId) && siteId.length > 0) {
      API_URL += IncidentService.getQueryParams('LocationId', siteId);
    }
    if (!Array.isArray(siteId) && siteId !== null && siteId !== '' && siteId !== undefined) {
      API_URL += IncidentService.getQuerySingleParam('LocationId', siteId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  // TODO: Use the existing function "getUserListForFilter" and manipululate it to take site
  // getFieldStaffDataQuick(siteId) {
  //   const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
  //   let API_URL = `${this.env.apiUrl}/AppFilterUsers?Role=fieldstaff&OrgID=${orgId}`;
  //   if (Array.isArray(siteId) && siteId.length > 0) {
  //     API_URL += this.getQueryParams('LocationId', siteId);
  //   }
  //   return this.httpClient.get(API_URL).map((data) => {
  //     return data;
  //   }).catch((error) => {
  //     return Observable.throw(error);
  //   });
  // }

  getUserList(siteId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/users?OrgId=${orgId}`;
    if (siteId !== null && siteId !== '' && siteId !== undefined) {
      const LocationId = siteId;
      API_URL += IncidentService.getQuerySingleParam('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getAssetsByOrg(siteId, AssetTypeId?) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Assets?OrgId=${orgId}`;
    if (siteId !== null && siteId !== '' && siteId !== undefined) {
      const LocationId = siteId;
      API_URL += IncidentService.getQuerySingleParam('LocationId', LocationId);
    }
    if (AssetTypeId) {
      API_URL += IncidentService.getQueryParams('AssetTypeId', AssetTypeId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  removeSimilarIncident(incidentId, similarIncidentId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Incidents/${incidentId}/RemoveSimilarIncident/${similarIncidentId}`;
    return this.httpClient
      .put(API_URL, {})
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  updateAssignedTo(request, id) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/incidents/${id}/AssignedTo`;
    return this.httpClient
      .put(API_URL, request)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getFieldStaffData(id) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    id = parseInt(id, 10); // eslint-disable-line no-param-reassign
    const API_URL = `${this.env.apiUrl}/FieldStaff?OrgID=${orgId}${id ? `&DivisionID=${id}` : ''}`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getFieldStaffDataWithLocId(divisionId, sites) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    divisionId = parseInt(divisionId, 10); // eslint-disable-line no-param-reassign
    let API_URL = `${this.env.apiUrl}/FieldStaff?OrgID=${orgId}${divisionId ? `&DivisionID=${divisionId}` : ''
      }`;
    if (sites && !Array.isArray(sites)) {
      API_URL += IncidentService.getQuerySingleParam('LocationId', sites);
    }
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += IncidentService.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: FieldStaffDataWithLocId) => data)
      .catch((error) => Observable.throw(error));
  }

  uploadIncidentMedia(mediaData, incidentId) {
    const API_URL = `${this.env.apiUrl}/Incidents/${incidentId}/UploadMedia`;
    return this.httpClient
      .post(API_URL, mediaData)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  ImportIncident(userData) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/ImportIncidents`;
    return this.httpClient
      .post(API_URL, userData)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }

  getCountries() {
    const countries = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.COUNTRIES));
    if (countries !== undefined && countries !== null) {
      return of(countries);
    }
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient
      .get(API_URL)
      .map((data) => {
        localStorage.setItem(constants.STORAGE_KEYS.COUNTRIES, JSON.stringify(data));
        return data;
      })
      .catch((error) => Observable.throw(error));
  }
}
