<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <img src="assets/img/500.svg" alt="500" style="max-width: 300px" class="mb-4" />
        <h1 class="display-4">500</h1>
        <h4 class="pt-3">{{ 'ERROR_PAGES.500_TITLE' | translate }}</h4>
        <p class="text-muted">{{ 'ERROR_PAGES.500_SUB_TITLE' | translate }}</p>
        <button class="btn btn-primary" (click)="OnClick()">
          {{ 'ERROR_PAGES.BACK_TO_HOME' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
