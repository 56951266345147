import { Component, OnInit, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import EnvService from '../env.service';
import ChangeOnwerService from './changeOwnerService';
import constants from '../common/constants';
import OrganisationService from '../views/organisations/services/organisation-http.service';
import { getUserNames } from '../utils/helper';

@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  styleUrls: ['./change-owner.component.scss'],
})
export default class ChangeOwnerComponent implements OnInit {
  public event: EventEmitter<unknown> = new EventEmitter();

  modalRef: BsModalRef;

  portalUsers;

  currentOrgId;

  selectedUserId: string;

  public selectedOwner;

  userApiCallInProgress: boolean;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private env: EnvService,
    private bsModalRef: BsModalRef,
    private changeOwner: ChangeOnwerService,
    private orgService: OrganisationService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.currentOrgId = this.changeOwner.currentOrgId;
    this.selectedOwner = localStorage.getItem(constants.ORG_OWNER_USER_ID);
    this.getAppUserList();
  }

  onOwnerSelect(selectedValue: string): void {
    this.selectedUserId = selectedValue;
  }

  submit() {
    this.changeOwner.setSelectedUser(this.selectedUserId);
    this.close();
    this.event.emit();
  }

  async getAppUserList() {
    this.userApiCallInProgress = true;
    try {
      const resp = await this.orgService
        .getUserListForFilter('admin', this.currentOrgId)
        .toPromise();
      this.portalUsers = resp.Data.Users;
      this.portalUsers = getUserNames(this.portalUsers);

      this.userApiCallInProgress = false;
      return this.portalUsers;
    } catch (e) {
      this.userApiCallInProgress = false;
      this.toastr.error(
        this.translate.instant('LABELS.Incidents.FORM_ERRORS.UNCAUGHT_GET_USER_LIST_ERROR'),
      );
    }
    this.userApiCallInProgress = false;
    return null;
  }

  close() {
    this.bsModalRef.hide();
  }
}
