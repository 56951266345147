<div class="attachment-card">
  <img [src]="'../../../../assets/img/icons/' + fileType + '-attachment.png'" />

  <div class="content">
    <!-- TODO: Had to give different conditions here because different api returns different property for attachment name. From api side this has to be uniform -->
    <ng-container *ngIf="attachmentType === 'existing'">
      <span *ngIf="file.FileName?.length > maxFileNameLength"
        >{{ file.FileName | slice: 0 : fileSliceLimit }} ...
        {{
          file.FileName | slice: file.FileName.length - fileSliceLimit : file.FileName.length
        }}</span
      >
      <span *ngIf="file.FileName?.length <= maxFileNameLength">{{ file.FileName }}</span>
    </ng-container>
    <ng-container *ngIf="attachmentType === 'existingIncidentAttachment'">
      <span *ngIf="file.MediaUrl?.length > maxFileNameLength"
        >{{ file.MediaUrl | slice: 0 : fileSliceLimit }} ...
        {{
          file.MediaUrl | slice: file.MediaUrl.length - fileSliceLimit : file.MediaUrl.length
        }}</span
      >
      <span *ngIf="file.MediaUrl?.length <= maxFileNameLength">{{ file.MediaUrl }}</span>
    </ng-container>
    <ng-container *ngIf="attachmentType === 'existingIncidentHistoryAttachment'">
      <span *ngIf="file.Path?.length > maxFileNameLength"
        >{{ file.Path | slice: 0 : fileSliceLimit }} ...
        {{ file.Path | slice: file.Path.length - fileSliceLimit : file.Path.length }}</span
      >
      <span *ngIf="file.Path?.length <= maxFileNameLength">{{ file.Path }}</span>
    </ng-container>
    <ng-container *ngIf="attachmentType === 'uploaded'">
      <span *ngIf="file.name?.length > maxFileNameLength"
        >{{ file.name | slice: 0 : fileSliceLimit }} ...
        {{ file.name | slice: file.name.length - fileSliceLimit : file.name.length }}</span
      >
      <span *ngIf="file.name?.length <= maxFileNameLength">{{ file.name }}</span>
    </ng-container>
    <div class="view-message">
      <small *ngIf="attachmentType !== 'uploaded'">Click to view attachment</small>
    </div>
  </div>
</div>
