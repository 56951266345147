import { Component, OnInit, Input } from '@angular/core';
import EnvService from '../../../env.service';
import constants from '../../../common/constants';
import AuthService from '../../../auth/auth.service';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
})
export default class PaymentFailureComponent implements OnInit {
  @Input() purchaseData;

  @Input() isLandingPage;

  public supportEmail;

  public organisationDetails;

  constructor(
    private env: EnvService,
    private auth: AuthService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.supportEmail = this.env.supportEmail;
    localStorage.removeItem(constants.PAYMENT_IN_PROGRESS_DETAILS);
    localStorage.removeItem(constants.PREFERRED_CURRENCY);
    if (this.isLandingPage === true) {
      localStorage.removeItem(constants.BEARER_TOKEN);
      this.organisationDetails = JSON.parse(localStorage.getItem(constants.REGISTERED_ORG_DETAILS));
    }
  }

  showLoginForm() {
    this.auth.activeForm = 'login';
  }
}
