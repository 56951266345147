import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';
import constants from '../../../common/constants';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import OrganisationService from '../../organisations/services/organisation-http.service';
import { ExportApiModel } from '../../appointments/appointments.interface';
import { Attachment } from '../../asset/models/assets.interface';
import Notifications from '../models/notifications';
import { BasicApiModel } from '../../../interfaces/global.interface';

interface NotificationsModel extends BasicApiModel {
  Data?: {
    TotalNumberOfRecords: number,
    Notifications: {
      Id: number,
      OwnerId: number,
      OrganisationId: number,
      Description: string,
      PortalNotificationType: string,
      NotificationStatus: string,
      CreatedOn: string | Date,
      ModifiedOn: string | Date,
      NotifiedUsersCount: number,
      NoOfViews: number,
      Attachments: File[] | Attachment[],
      FileUrl: string,
      FileType: string
    }[] | Notifications[] | Notification
  }
}
interface SendNotificationModel extends BasicApiModel {
  Data: {
    Notification: {
      Id: number,
      OwnerId: number,
      OrganisationId: number,
      Description: string,
      PortalNotificationType: string,
      NotificationStatus: string,
      CreatedOn: string | Date,
      ModifiedOn: string | Date,
      NotifiedUsersCount: number,
      NoOfViews: number,
      PetParentId: number[]
    }
  }
}
@Injectable({
  providedIn: 'root',
})
export default class NotificationService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  static getQueryParams(name, values) {
    let str = '&';
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  static downloadFile(filePath) {
    const a = document.createElement('a');
    a.href = filePath;
    a.download = filePath.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static processIncidentsForNav(apiURL, pageNumber, pageLength, notifications, totalRecordCount) {
    localStorage.setItem(constants.NOTIFICATION_API_CURRENT_FILTER, apiURL);
    const notificationList = [];
    notifications.forEach((notification) => {
      notificationList.push({
        Id: notification.Id,
        OrganisationId: notification.OrganisationId,
      });
    });
    const notificationsForNav = {
      notificationList,
      totalRecordCount,
      pageNumber,
      pageLength,
      numOfPages:
        totalRecordCount % pageLength === 0
          ? totalRecordCount / pageLength
          : Math.ceil(totalRecordCount / pageLength),
    };
    localStorage.setItem(constants.NOTIFICATION_LIST_FOR_NAV, JSON.stringify(notificationsForNav));
  }

  sendNotification(notification) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Notifications`;
    return this.httpClient
      .post(API_URL, notification)
      .map((data: SendNotificationModel) => data)
      .catch((error: SendNotificationModel) => Observable.throw(error));
  }

  deleteNotification(notificationId) {
    const API_URL = `${this.env.apiUrl}/Notifications/${notificationId}`;
    return this.httpClient
      .delete(API_URL)
      .map((data: BasicApiModel) => data)
      .catch((error: BasicApiModel) => Observable.throw(error));
  }

  getNotificationList(params, pageNumber, dtCallback, requestObject) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Notifications`;
    // eslint-disable-next-line no-param-reassign
    requestObject.OrgId = [Number(orgId)];
    return this.httpClient
      .post(API_URL, requestObject)
      .map((data: NotificationsModel) => {
        NotificationService.processIncidentsForNav(
          API_URL,
          pageNumber,
          params.length,
          data.Data.Notifications,
          data.Data.TotalNumberOfRecords,
        );
        dtCallback({
          recordsTotal: data.Data.TotalNumberOfRecords || 0,
          recordsFiltered: data.Data.TotalNumberOfRecords || 0,
          data: [],
        });
        return data;
      })
      .catch((error) => Observable.throw(error));
  }

  exportNotifications() {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Notifications?Export=true&OrgId=${orgId}`;

    // TODO: Uncomment the following code when API accepts visible columns
    // if (Array.isArray(visibleColumns) && visibleColumns.length > 0) {
    //   API_URL += this.static ('Column', visibleColumns);
    // }
    return this.httpClient
      .get(API_URL)
      .map((data: ExportApiModel) => data)
      .catch((error: ExportApiModel) => Observable.throw(error));
  }
}
