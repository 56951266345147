import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export default class ConfirmationComponent {
  constructor(
    public router: Router,
    private translate: TranslateService,
  ) {
    // logic goes here
  }

  goBack() {
    this.router.navigate(['home']);
  }
}
