import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';

  public apiUrlStatsProcessor = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  public paypalClientId = '';

  public supportEmail = '';

  public heatmapIntensityPercent = 10;

  public YouTubeUrl = '';

  public demoSlots = [];

  public defaultLang = '';

  public version = '';
}
