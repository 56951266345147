<div class="content">
  <div style="text-align: center; margin-bottom: 10px">
    <img
      style="height: 10vh"
      src="../../../../assets/img/caution-red.png"
      alt="incidentreporter365 logo"
      class="logo"
    />
  </div>
  <div>
    <h1 style="text-align: center">{{ 'LABELS.MAINTENANCE.HEADING' | translate }}</h1>

    <p>
      {{ 'LABELS.MAINTENANCE.LINE1' | translate }}
      <br />
      {{ 'LABELS.MAINTENANCE.LINE2' | translate }}
      <a href="https://twitter.com/ReportIncidents">Twitter</a>
      <br />
      {{ 'LABELS.MAINTENANCE.LINE3' | translate }}
    </p>
    <p>
      - {{ 'LABELS.MAINTENANCE.THE' | translate }} IncidentReporter365
      {{ 'LABELS.MAINTENANCE.TEAM' | translate }}
    </p>
  </div>
</div>
