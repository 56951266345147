<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span>{{ 'LABELS.APPOINTMENTS.CREATE_APPOINTMENT' | translate }}</span>
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <form #createAppointmentForm="ngForm">
        <div class="input-group mb-3" *ngIf="clinicsList?.length >= 1">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-map-marker"></i></span>
          </div>
          <select
            name="Clinic"
            class="form-control"
            required
            name="location"
            [(ngModel)]="appointment.LocationId"
            #location="ngModel"
            (change)="onSiteChange(appointment.LocationId)"
          >
            <option [value]="undefined" disabled selected>Select the site</option>
            <option *ngFor="let clinic of this.clinicsList" [value]="clinic.Id">
              {{ clinic.LocationName }}
            </option>
          </select>
          <div
            class="alert-danger"
            *ngIf="location.invalid && (location.dirty || location.touched)"
          >
            <span *ngIf="location.errors?.required">
              {{ 'COMMON.SITE_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-user-o"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            name="firstName"
            [(ngModel)]="appointment.UserName"
            placeholder="Name"
            required
            #firstName="ngModel"
          />
          <div
            class="alert-danger"
            *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
          >
            <span *ngIf="firstName.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.NAME_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-globe"></i></span>
          </div>
          <select
            name="countrycode"
            #countryCode="ngModel"
            class="form-control"
            [(ngModel)]="appointment.CountryID"
            required
          >
            <option [value]="undefined" disabled selected>
              {{ 'LABELS.APPOINTMENTS.COUNTRY_CODE' | translate }}
            </option>
            <option *ngFor="let countryCode of this.countryCodes" [value]="countryCode.Id">
              {{ countryCode.CountryName }} ({{ countryCode.PhoneCode }})
            </option>
          </select>
          <div
            class="alert-danger"
            *ngIf="countryCode.invalid && (countryCode.dirty || countryCode.touched)"
          >
            <span *ngIf="countryCode.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.COUNTRY_CODE_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-phone"></i></span>
          </div>
          <input
            type="text"
            class="form-control"
            name="phoneNumber"
            [(ngModel)]="appointment.MobileNumber"
            placeholder="Mobile number"
            #phoneNumber="ngModel"
            [pattern]="phoneNumberPattern"
            minlength="7"
            maxlength="15"
            required
          />
          <div
            class="alert-danger"
            *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
          >
            <span *ngIf="phoneNumber.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.PHONE_NUMBER_REQUIRED' | translate }}
            </span>
            <span *ngIf="phoneNumber.errors?.pattern">
              {{ 'LABELS.PortalUsers.VALIDATION.MOBILE_NUMBER_INVALID' | translate }}
            </span>
            <span *ngIf="phoneNumber.errors?.minlength && !phoneNumber.errors?.pattern">
              {{ 'LABELS.PortalUsers.VALIDATION.MOBILE_NUMBER_PATTERN' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-sticky-note-o"></i></span>
          </div>
          <textarea
            maxlength="500"
            class="form-control"
            name="purpose"
            [(ngModel)]="appointment.Purpose"
            placeholder="Purpose of appointment"
            required
            #purpose="ngModel"
          ></textarea>
          <div class="alert-danger" *ngIf="purpose.invalid && (purpose.dirty || purpose.touched)">
            <span *ngIf="purpose.errors?.required">
              {{ 'LABELS.APPOINTMENTS.FORM_ERRORS.PURPOSE_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-exclamation-triangle"></i></span>
          </div>
          <select
            name="AssociateIncidents"
            class="form-control"
            [(ngModel)]="appointment.IncidentId"
          >
            <option [value]="null" disabled selected>
              {{ 'LABELS.INTEGRATIONS.SELECT_RELATED_INCIDENT' | translate }}
            </option>
            <option *ngFor="let incident of openIncidents" [value]="incident.Id">
              {{ ['#' + incident.IncidentUid]
              }}{{
                incident.IncidentDescription
                  ? ' - ' +
                    incident.IncidentDescription.substring(0, 40) +
                    (incident.IncidentDescription.length > 40 ? '...' : '')
                  : ''
              }}
            </option>
          </select>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-calendar"></i></span>
          </div>
          <input
            type="text"
            [(ngModel)]="appointment.date"
            class="form-control osm-form-control date-ctrl"
            [bsConfig]="{
              dateInputFormat: 'DD-MMM-YYYY',
              containerClass: 'theme-dark-blue',
              showWeekNumbers: false,
              adaptivePosition: true
            }"
            [minDate]="minDate"
            name="fromDate"
            bsDatepicker
            (ngModelChange)="loadSlots()"
            placeholder="Select date"
            readonly
          />
        </div>
        <div class="input-group row mb-3 slots-container">
          <div
            *ngFor="let slot of slots"
            class="col col-2 slot"
            [ngClass]="{ active: slot.selected, blocked: !slot.Availability }"
            (click)="selectSlot(slot)"
          >
            {{ getFormattedTimeSlot(slot) }}
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row" style="justify-content: flex-end; margin-right: 4px">
    <div>
      <button class="btn btn-danger" type="button" (click)="goBack()">
        <i class="fa fa-close" aria-hidden="true"></i>
        {{ 'LABELS.SETUP.BUTTONS.CANCEL' | translate }}
      </button>
    </div>
    &nbsp;
    <div class="col-sm-12 m-b-xs content-right-align" style="display: contents">
      <button
        type="submit"
        class="btn btn-success"
        (click)="createAppointment()"
        [disabled]="
          apiCallInProgress ||
          createAppointmentForm.invalid ||
          (appointment.UserName && appointment.UserName.trim().length === 0) ||
          (appointment.Purpose && appointment.Purpose.trim().length === 0) ||
          (!isSlotSelected && isWorkingDay) ||
          prevDate !== appointment.date
        "
      >
        {{ 'LABELS.SETUP.BUTTONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</div>
