import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class ChangeOnwerService {
  private currentOrganisationIdSource = new BehaviorSubject<unknown>(null);

  private selectedUser = new BehaviorSubject<string>('');

  currentOrganisationId$ = this.currentOrganisationIdSource.asObservable();

  currentOrgId: number;

  setSelectedUser(userId: string) {
    this.selectedUser.next(userId);
  }

  getSelectedUser() {
    return this.selectedUser.asObservable();
  }

  setCurrentOrganisationId(id: string | number) {
    this.currentOrganisationIdSource.next(id);
  }

  getCurrentOrganisationId() {
    return this.currentOrganisationIdSource.value;
  }
}
