/**
 * This loader service adds the custom scripts like googleapis/hubspot script to header tag triggering them.
 * Call the loadGoogleMap() OnInit in components where we have to make use of google map component.
 */
import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

@Injectable()
export default class ThirdPartyScriptLoader {
  private static mapLoaderPromise;

  private static HubSpotLoaderPromise;

  static loadGoogleMap() {
    if (!ThirdPartyScriptLoader.mapLoaderPromise) {
      ThirdPartyScriptLoader.mapLoaderPromise = new Promise((resolve) => {
        // eslint-disable-next-line no-underscore-dangle
        window.__onGapiLoaded = () => {
          resolve(window.gapi);
        };
        const node = document.createElement('script');
        node.src =
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAJh0tQ71dPRiLjmUbhfVP-HQHVdiDOZYE&libraries=visualization,geometry,places&callback=__onGapiLoaded';
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return ThirdPartyScriptLoader.mapLoaderPromise;
  }

  static loadHubSpot() {
    if (!ThirdPartyScriptLoader.HubSpotLoaderPromise) {
      ThirdPartyScriptLoader.HubSpotLoaderPromise = new Promise((resolve) => {
        const node = document.createElement('script');
        node.src = 'https://js.hs-scripts.com/7207449.js';
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
        resolve(true);
      });
    }
    return ThirdPartyScriptLoader.HubSpotLoaderPromise;
  }
}
