/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
// This component is not being used anywhere, will be removed in the next major release if not needed.
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';

@Injectable({
  providedIn: 'root',
})
export default class IntegrationsHttpService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  getIntegrations() {
    const API_URL = `${this.env.apiUrl}/Integrations`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => { console.log(data); return data; })
      .catch((error: any) => Observable.throw(error));
  }

  createIntegration(integration, orgId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Integrations`;
    return this.httpClient
      .post(API_URL, integration)
      .map((data: any) => { console.log(data); return data; })
      .catch((error: any) => Observable.throw(error));
  }

  updateIntegration(integration, orgId, integrationId) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Integrations/${integrationId}`;
    return this.httpClient
      .put(API_URL, integration)
      .map((data: any) => { console.log(data); return data; })
      .catch((error: any) => Observable.throw(error));
  }
}
