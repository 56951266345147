/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';
import Organisation from '../models/organisation.model';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import constants from '../../../common/constants';
import User from '../models/user.model';
import Setup from '../../setup/models/setup.model';

@Injectable()
export default class OrganisationService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  static setGlobalOrganisations(orgList) {
    localStorage.setItem(constants.STORAGE_KEYS.ORGANISATIONS, JSON.stringify(orgList));
    localStorage.setItem(
      constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION,
      JSON.stringify(orgList[0]),
    );
  }

  static getCurrentGlobalOrganisation() {
    const currentGlobalOrg = JSON.parse(
      localStorage.getItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION),
    );
    const userOwnedOrgs = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
    return currentGlobalOrg || userOwnedOrgs[0];
  }

  static getUserOwnedOrganisations() {
    return JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
  }

  setCurrentOrganisation(org) {
    this.setCurrentOrganisation = org;
    localStorage.setItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION, JSON.stringify(org));
  }

  public getOrganizationModules(orgId) {
    const API_URL = `${this.env.apiUrl}/Organisation/${orgId}/Modules`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: Object }) => data)
      .catch((error) => Observable.throw(error));
  }

  getUserListForFilter(orgId, role = 'subscriber') {
    const API_URL = `${this.env.apiUrl}/AppFilterUsers?Role=${role}&OrgId=${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Users: [] }, Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  getOrganisationsList() {
    const API_URL = `${this.env.apiUrl}/Organisations`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Organisations: Array<{ Id: number }> } }) => data)
      .catch((error) => Observable.throw(error));
  }

  getOrganisationById(organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Organisation: Organisation }, Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  getUserDetails(userId, organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Users/${userId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { UserOrganisation: User }, Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateVisibility(isVisible, FeedStatus) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateVisiblity`;
    return this.httpClient
      .put(API_URL, {
        Visibility: isVisible,
        FeedStatus,
      })
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  getDivisionByOrg(organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Divisions: Array<Setup> } }) => data)
      .catch((error) => Observable.throw(error));
  }

  getDivisionListByUserId(userId, siteId?) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Divisions?UserId=${userId}`;
    if (siteId) {
      API_URL += `&SiteId=${siteId}`;
    }
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Divisions: Array<Setup> } }) => data)
      .catch((error) => Observable.throw(error));
  }

  // getDivisionList() {
  //   const API_URL = `${this.env.apiUrl}/Divisions`;
  //   return this.httpClient.get(API_URL).map((data: any) => {
  //     return data;
  //   }).catch((error) => {
  //     return Observable.throw(error);
  //   });
  // }

  getIncidentSiteByOrg(organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Locations: [] } }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateGeoFence(geoFencingData) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateGeoFence`;
    return this.httpClient
      .put(API_URL, geoFencingData)
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentLocations(organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Locations: [] } }) => data)
      .catch((error) => Observable.throw(error));
  }

  getTimezone() {
    const API_URL = `${this.env.apiUrl}/Dashboard/Timezones`;
    return this.httpClient
      .get(API_URL)
      .map((data: any) => data)
      .catch((error: any) => Observable.throw(error));
  }

  createOraganisationSite(IncidentLocations) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/IncidentLocations`;
    return this.httpClient
      .post(API_URL, IncidentLocations)
      .map((data: { Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateFeedSettings(isVisible, FeedStatus) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}//UpdateVisiblity`;
    return this.httpClient
      .put(API_URL, {
        Visibility: isVisible,
        FeedStatus,
      })
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  addIncidentsClosedStatus(isTrue) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}//UpdateEnableIncidentToClose?EnableIncidentToClose=${isTrue}`;
    return this.httpClient
      .put(API_URL, {
        EnableIncidentToClose: isTrue,
      })
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateResolveIncidentSettings(orgId, CanResolveIncidents) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateCanResolveIncidents`;
    return this.httpClient
      .put(API_URL, {
        CanResolveIncidents,
      })
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  getIncidentSeverityConfig(organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Severities`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { SeverityConfig: Array<{ SeverityName }> }, Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateIncidentSeverityConfig(severityConfig, organisationId?) {
    const orgId = organisationId || OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateSeverity`;
    return this.httpClient
      .put(API_URL, severityConfig)
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateEnableAssignedToSettings(orgId, EnableAssignedTo) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/UpdateEnableAssignedTo?EnableAssignedTo=${EnableAssignedTo}`;
    return this.httpClient
      .put(API_URL, {
        EnableAssignedTo,
      })
      .map((data: { Data: [], Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  updateOrganisation(currentOrganisationId, orgData) {
    const API_URL = `${this.env.apiUrl}/Dashboard/UpdateOrganisation/${currentOrganisationId}`;
    return this.httpClient
      .put(API_URL, orgData)
      .map((data: { Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  // update icon ....service
  updateOrganisationIcon(formData, id, token) {
    const httpHeaders = new HttpHeaders().append('Authorization', `BEARER ${token}`);
    const options = {
      headers: httpHeaders,
    };
    const API_URL = `${this.env.apiUrl}/Organisations/${id}/UpdatePicture`;
    return this.httpClient
      .put(API_URL, formData, options)
      .map((data: { Data: { Data: [], Status: string } }) => data.Data)
      .catch((error) => Observable.throw(error));
  }

  updateOrganisationBanner(formData, id, token) {
    const httpHeaders = new HttpHeaders().append('Authorization', `BEARER ${token}`);
    const options = {
      headers: httpHeaders,
    };
    const API_URL = `${this.env.apiUrl}/Organisations/${id}/UpdateOrganisationBanner`;
    return this.httpClient
      .put(API_URL, formData, options)
      .map((data: { Data: { Data: [], Status: string } }) => data.Data)
      .catch((error) => Observable.throw(error));
  }
}
