/* eslint-disable no-underscore-dangle */
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AclService } from 'ng2-acl/dist';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import ThirdPartyScriptLoader from './thirdPartyScript-loader.service';
import ServerstatusService from './views/maintenance/services/serverstatus.service';
import constants from './common/constants';
import EnvService from './env.service';
import PaymentService from './views/billing/services/payment.service';
import { CustomWindow } from './interfaces/global.interface';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [ThirdPartyScriptLoader],
})
export default class AppComponent implements OnInit {
  aclData = {};

  deviceInfo: DeviceInfo;

  deepLink: String = '';

  incidentIdInit;

  orgIdInit;

  customWindow: CustomWindow;

  constructor(
    private router: Router,
    translate: TranslateService,
    private paymentService: PaymentService,
    private env: EnvService,
    private aclService: AclService,
    private serverStatus: ServerstatusService,
    private thirdPartyScriptLoader: ThirdPartyScriptLoader,
    private deviceDetector: DeviceDetectorService,
  ) {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    const appLang = userLang || this.env.defaultLang;
    translate.setDefaultLang(appLang);
    translate.use(appLang);

    this.customWindow = window as unknown as CustomWindow;

    // Analytics tracking
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // HubSpot tracking
        const hsq = this.customWindow._hsq;
        this.customWindow._hsq = hsq || [];

        hsq.push(['setPath', evt.urlAfterRedirects]);
        hsq.push(['trackPageView']);
      }
    });
  }

  ngOnInit() {
    this.getServerStatus();
    const initUrl = window.location.href;
    if (initUrl.includes('incidents/view/')) {
      const [, , , , , , orgIdInit, incidentIdInit] = initUrl.split('/');
      this.orgIdInit = orgIdInit;
      this.incidentIdInit = incidentIdInit;
      this.deepLink = `incidentreporter365://dl?type=incident&orgId=${this.orgIdInit}&IncidentId=${this.incidentIdInit}`;
    }

    if (
      initUrl.split('/')[3] === '' ||
      initUrl.split('/')[4].includes('home') ||
      initUrl.split('/')[4].includes('release-versions')
    ) {
      // handle case
    } else {
      const isMobile = this.deviceDetector.isMobile();
      if (isMobile) {
        if (this.deepLink !== '') {
          window.location.href = this.deepLink.toString();
        } else {
          window.location.href = 'incidentreporter365://';
        }
        this.router.navigate(['downloads']);
      }
    }

    ThirdPartyScriptLoader.loadHubSpot();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getPricingData();
    this.aclService.setStorageType('localStorage');
  }

  getServerStatus() {
    this.serverStatus.getServerStatus().subscribe((res) => {
      if (res.Data.Health === false) {
        this.router.navigate(['maintenance']);
      }
    });
  }

  getPricingData() {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE) || this.env.defaultLang;
    this.paymentService.getPricingData(userLang).subscribe(
      (res) => {
        const packages = res.Data.Resource[0].Packages;
        packages.forEach((item) => {
          const packageName = item.PackageName.replace(/ /g, '_');
          this.aclData[packageName] = [];

          // Dashboard
          const dashboardFeature = item.Features.filter(
            (feature) => feature.FeatureName === 'Dashboard reports',
          );
          if (dashboardFeature.length > 0 && dashboardFeature[0].Enabled) {
            this.aclData[packageName].push('dashboard');
          }

          // Heat Map
          const heatMapFeature = item.Features.filter(
            (feature) => feature.FeatureName === 'Heatmap',
          );
          if (heatMapFeature.length > 0 && heatMapFeature[0].Enabled) {
            this.aclData[packageName].push('heatMap');
          }

          // Live Chat
          const liveChatFeature = item.Features.filter(
            (feature) => feature.FeatureName === 'Chat support',
          );
          if (liveChatFeature.length > 0 && liveChatFeature[0].Enabled) {
            this.aclData[packageName].push('liveChat');
          }

          // GeoFencing
          const geoFencingFeature = item.Features.filter(
            (feature) => feature.FeatureName === 'Geofencing (organisation visibility)',
          );
          if (
            geoFencingFeature.length > 0 &&
            geoFencingFeature[0].FeatureThreshold === 'Customizable'
          ) {
            this.aclData[packageName].push('GeoFencingCustomization');
          }
        });
        this.aclService.setAbilities(this.aclData);
        if (localStorage.getItem(constants.CURRENT_SUBSCRIPTION)) {
          const currentPackage = JSON.parse(localStorage.getItem(constants.CURRENT_SUBSCRIPTION));
          this.aclService.attachRole(currentPackage.PackageName);
        }
      },
      () => {
        // handle error
      },
    );
  }
}
