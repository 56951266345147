import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import AppointmentsService from '../../services/appointments-http.service';
import constants from '../../../../common/constants';
import SharedService from '../../../../services/shared-service/shared.service';
import Organisation from '../../../organisations/models/organisation.model';
import OrganisationService from '../../../organisations/services/organisation-http.service';
import { Slot } from '../../appointments.interface';

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.css'],
})
export default class CreateAppointmentComponent implements OnInit {
  public event: EventEmitter<Object> = new EventEmitter();

  appointment: {
    UserName: string;
    CountryID?: string;
    MobileNumber: string;
    date: string | Date;
    Purpose: string;
    IncidentId: string;
    LocationId?: string;
    OrganisationId?: string;
  } = {
      UserName: '',
      MobileNumber: '',
      date: '',
      Purpose: '',
      IncidentId: '',
    };

  slots: Slot[];

  minDate: Date = new Date();

  countryCodes;

  // public phoneNumberPattern = '^[0-9]{7,}$';
  public phoneNumberPattern = '^(?!0{6,})[0-9]+$';

  orgWorkingDays: number = null;

  openIncidents;

  clinicsList: {
    Id: string;
  }[];

  locationName;

  public UsersIncidentSiteList;

  isOrgOwner: string;

  isSlotSelected = false;

  apiCallInProgress = false;

  globallySelectedOrg: Organisation;

  prevDate: string | Date;

  isWorkingDay = true;

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private toastr: ToastrService,
    private appointmentsService: AppointmentsService,
    private sharedService: SharedService,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  static addZeroBefore(n) {
    return (n < 10 ? '0' : '') + n;
  }

  ngOnInit(): void {
    this.globallySelectedOrg = OrganisationService.getCurrentGlobalOrganisation();
    this.isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    this.UsersIncidentSiteList = JSON.parse(localStorage.getItem(constants.USER_INCIDENT_SITES));
    this.loadOrganisationsList();
    this.getCountryCodes();
  }

  loadOrganisationsList() {
    if (this.globallySelectedOrg) {
      this.appointment.OrganisationId = this.globallySelectedOrg.Id.toString();
      this.appointment.IncidentId = null;
      this.getOpenIncidentsOfOrganization();
      this.getIncidentLocations();
    }
  }

  getIncidentLocations() {
    const resp = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (
      this.UsersIncidentSiteList &&
      this.UsersIncidentSiteList.length !== 0 &&
      this.isOrgOwner !== 'true'
    ) {
      this.clinicsList = this.UsersIncidentSiteList.slice();
    } else {
      this.clinicsList = resp;
    }
    if (this.clinicsList.length <= 1) {
      this.appointment.LocationId = this.clinicsList[0].Id;
      this.getAppointmentSettings(this.appointment.OrganisationId, this.appointment.LocationId);
    }
    this.getAppointmentSettings(this.appointment.OrganisationId, this.appointment.LocationId);
  }

  onSiteChange(locId) {
    this.locationName = this.clinicsList.filter(
      (ele) => parseInt(ele.Id, 10) === parseInt(locId, 10),
    );
    this.locationName = this.locationName[0].LocationName;
    this.appointment.date = undefined;
    this.slots = [];
    this.getAppointmentSettings(this.appointment.OrganisationId, this.appointment.LocationId);
  }

  getAppointmentSettings(orgId, LocationId) {
    this.appointmentsService.getAppointmentsConfig(LocationId).subscribe(
      (res) => {
        const appointmentSettings = res.Data.AppointmentSettings;
        if (appointmentSettings !== null && appointmentSettings.length > 0) {
          const filteredSettings = appointmentSettings.filter(
            (obj) => obj.OrganisationId === parseInt(orgId, 10),
          );
          if (filteredSettings.length > 0) {
            const currentOrgSettings = filteredSettings[0];
            if (currentOrgSettings.WorkingDays) {
              this.orgWorkingDays = currentOrgSettings.WorkingDays;
            }
          }
        }
      },
      () => {
        // handle error
      },
    );
  }

  loadSlots() {
    if (!this.appointment.date) {
      return;
    }
    const date = moment(this.appointment.date).format('YYYY-MM-DD');
    const day = moment(date).day();
    const currentDate = moment().format('YYYY-MM-DD');
    if (this.orgWorkingDays) {
      // eslint-disable-next-line no-bitwise
      if ((this.orgWorkingDays & (2 ** day)) === 0) {
        this.slots = [];
        const weekDays = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
        const nonWorkingDays = [];
        for (let i = 0; i < 7; i += 1) {
          // eslint-disable-next-line no-bitwise
          const val = (this.orgWorkingDays & (2 ** i)) > 0;
          if (!val) {
            nonWorkingDays.push(weekDays[i]);
            this.isWorkingDay = false;
          }
        }
        const msg = this.translate
          .instant('TOASTR.APPOINTMENTS.NOT_WORKING_DAY')
          .replace('{days}', nonWorkingDays.join(', '));
        this.toastr.info(msg);
        return;
      }

      this.prevDate = this.appointment.date;
    }
    this.appointmentsService.getSlotsByDate(date, this.appointment.LocationId).subscribe(
      (res) => {
        if (res.Status === 'Fail' || res.Status === 'Error') {
          this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.APPOINTMENTS_NOT_ALLOWED'));
          return;
        }
        this.slots = res.Data.AvailableSlots;
        if (currentDate === date) {
          this.getAvailableSlots();
        }
      },
      () => {
        // handle error
      },
    );
  }

  close() {
    this.bsModalRef.hide();
  }

  getAvailableSlots() {
    this.slots.forEach((element: Slot) => {
      const now = new Date();
      const time = `${CreateAppointmentComponent.addZeroBefore(
        now.getUTCHours(),
      )}:${CreateAppointmentComponent.addZeroBefore(
        now.getUTCMinutes(),
      )}:${CreateAppointmentComponent.addZeroBefore(now.getUTCSeconds())}`;
      if (element.SlotStartTime < time) {
        // eslint-disable-next-line no-param-reassign
        element.Availability = false;
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getFormattedTimeSlot(slot) {
    const startTime = moment.utc(slot.SlotStartTime, 'HH:mm:ss').local().format('hh:mm A');
    return startTime;
  }


  selectSlot(slot) {
    if (!slot.Availability) {
      return;
    }
    this.isSlotSelected = true;
    this.slots.forEach((element: Slot) => {
      // eslint-disable-next-line no-param-reassign
      element.selected = false;
    });
    // eslint-disable-next-line no-param-reassign
    slot.selected = true;
  }

  createAppointment() {
    const selectedSlot = this.getSelectedSlot();
    if (selectedSlot === null) {
      this.toastr.info(this.translate.instant('TOASTR.APPOINTMENTS.SELECT_TIME_SLOT'));
      return;
    }
    const appointment = {
      UserName: this.appointment.UserName,
      CountryID: this.appointment.CountryID,
      MobileNumber: this.appointment.MobileNumber,
      AppointmentStartTime: `${moment(this.appointment.date).format('YYYY-MM-DD')}T${selectedSlot.SlotStartTime
        }Z`,
      AppointmentEndTime: `${moment(this.appointment.date).format('YYYY-MM-DD')}T${selectedSlot.SlotEndTime
        }Z`,
      Purpose: this.appointment.Purpose,
      IncidentId: this.appointment.IncidentId ? parseInt(this.appointment.IncidentId, 10) : '',
      LocationId: this.appointment.LocationId,
      LocationName: this.locationName,
    };
    this.apiCallInProgress = true;
    this.appointmentsService
      .createAppointment(appointment)
      .subscribe(
        (res) => {
          if (res.Status === 'Fail' || res.Status === 'Error') {
            this.toastr.error(
              this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_FAIL'),
            );
            return;
          }
          this.close();
          this.event.emit();
          this.toastr.success(
            this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_SUCCESS'),
          );
        },
        () => {
          this.toastr.error(this.translate.instant('TOASTR.APPOINTMENTS.CREATE_APPOINTMENT_FAIL'));
        },
      )
      .add(() => {
        this.apiCallInProgress = false;
      });
  }

  getSelectedSlot() {
    const selectedSlot = this.slots.filter((slot: Slot) => slot.selected === true);
    if (selectedSlot.length > 0) {
      return selectedSlot[0];
    }
    return null;
  }

  getCountryCodes() {
    this.sharedService.getCountries().subscribe(
      (res) => {
        this.countryCodes = res.Data.Countries;
      },
      () => {
        // TODO: Collect all posbile errors and toast them accordingly
      },
    );
  }

  getOpenIncidentsOfOrganization() {
    this.appointmentsService.getOpenIncidents().subscribe(
      (res) => {
        this.openIncidents = res.Data.Incidents;
      },
      () => {
        // handle error
      },
    );
  }

  goBack() {
    this.close();
  }
}
