<ngx-loading-bar color="#6d9cbd" height="2px"></ngx-loading-bar>
<!-- LOG IN  -->
<div class="container mt-5">
  <div class="">
    <div class="">
      <div class="login-container">
        <div class="login-container-content float-right background" style="width: 100%">
          <div class="login-form">
            <a routerLink="/home" class="nav-link montserrat-regular">
              <i class="fa fa-chevron-left icon" style="margin-right: 5px" aria-hidden="true"></i>
              {{ 'HOME.MENU.HOME' | translate }}
            </a>
            <ul class="points" style="padding-inline: 1.25rem; padding-block: .5rem;">
              <li>Incident Reporter 365 is now rebranded as OQSHA with enchanced feature set.</li>
              <li>If you are already an Incident Reported 365 user, you can continue with your existing credentials</li>
              <li>Click here to experience OQSHA.COM</li>
            </ul>
            <a href="https://staging.oqsha.com/#/login?source=Home" style="text-decoration: none;">
              <button class="action-button">
                <p>
                  Sign in
                </p>
                <img src="assets\landing-page\images\bg\next-btn.png" alt="Next button">
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
