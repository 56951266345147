import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import PaymentService from '../services/payment.service';
import Package from '../models/package.model';
import Feature from '../models/feature.model';
import constants from '../../../common/constants';
import EnvService from '../../../env.service';

@Component({
  selector: 'app-pricing-grid',
  templateUrl: './pricing-grid.component.html',
  styleUrls: ['./pricing-grid.component.scss'],
})
export default class PricingGridComponent implements OnInit {
  @Input() isLandingPage;

  @Output() buyPackage = new EventEmitter();

  public packages: Array<Package>;

  public features: Array<Feature>;

  public pricingData: string[] | number[] | boolean[];

  public months = constants.packageMonths;

  public currentPackage;

  freeTrialPackage = {};

  public couponData = [];

  selectedCurrency = 'INR';

  constructor(
    private paymentService: PaymentService,
    private env: EnvService,
  ) {
    // logic goes here
  }

  static getPackageNames(packages: Array<Package>) {
    const packageNames = packages.map((p) => p.PackageName);
    return packageNames;
  }

  static getPricingTableData(features: Array<Feature>, packages: Array<Package>) {
    const data = [];
    features.forEach((feature) => {
      const row = {
        featureName: feature.FeatureName,
      };
      packages.forEach((pkg) => {
        const pkgFeature = pkg.Features.filter((f) => f.Id === feature.Id);
        if (pkgFeature.length > 0) {
          row[pkg.PackageName] =
            pkgFeature[0].FeatureThreshold !== undefined
              ? pkgFeature[0].FeatureThreshold
              : pkgFeature[0].Enabled;
        }
      });

      data.push(row);
    });

    return data;
  }

  ngOnInit(): void {
    this.currentPackage = JSON.parse(localStorage.getItem(constants.HIGHEST_SUBSCRIPTION));
    this.getPricingData();
  }

  getPricingData() {
    const userLang = localStorage.getItem(constants.APP_LANGUAGE) || this.env.defaultLang;
    this.paymentService.getPricingData(userLang).subscribe(
      (res) => {
        this.packages = res.Data.Resource[0].Packages;
        this.couponData = res.Data.Resource[0].Coupons || [];
        [this.freeTrialPackage] = this.packages;
        // Remove free trial account.
        this.packages.splice(0, 1);
        this.features = this.packages.length > 0 ? this.packages[0].Features : [];
        this.features = this.features.map((f) => Object.assign(new Feature(), f));
        this.pricingData = PricingGridComponent.getPricingTableData(this.features, this.packages);
      },
      () => {
        // handle error
      },
    );
  }

  onBuyClick(event: { path: { firstElementChild: { selectedIndex: string | number; }; }[]; }, pkg) {
    if (pkg.Id !== 1) {
      const selectedNumMonths = constants.packageMonths[event.path[1].firstElementChild.selectedIndex].value;
      // eslint-disable-next-line no-param-reassign
      pkg.NumOfMonths = selectedNumMonths;
    }
    this.buyPackage.emit(pkg);
  }

  startFreeTrial() {
    this.onBuyClick(null, this.freeTrialPackage);
  }
}
