// This component is not used anywhere. Keeping it for reference, will be removed if necessary
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import SetupService from '../../../setup/services/setup-http.service';
import Organisation from '../../../organisations/models/organisation.model';
import IntegrationsHttpService from '../../services/integrations-http.service';
import constants from '../../../../common/constants';

@Component({
  selector: 'app-create-edit-integration',
  templateUrl: './create-edit-integration.component.html',
})
export default class CreateEditIntegrationComponent implements OnInit {
  public title;

  public organisationsList: Array<Organisation>;

  public currentIntegration;

  public organisationId: number = null;

  public event: EventEmitter<unknown> = new EventEmitter();

  public integration;

  public keyValues = {};

  public integrationTypes = [
    {
      name: 'Dynamics',
      apiFields: ['CRM_URL', 'CRM_USER_NAME', 'CRM_USER_PASSWORD'],
      models: ['crmUrl', 'crmUserName', 'crmPassword'],
    },
  ];

  constructor(
    private bsModalRef: BsModalRef,
    private translate: TranslateService,
    private setupService: SetupService,
    private toastr: ToastrService,
    private integrationHttpService: IntegrationsHttpService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.currentIntegration = this.setupService.currentIntegration;
    if (this.currentIntegration) {
      this.title = 'LABELS.INTEGRATIONS.EDIT';
    } else {
      this.title = 'LABELS.INTEGRATIONS.ADD';
    }
    this.loadOrganisationsList();
    this.organisationId = this.currentIntegration ? this.currentIntegration.OrganisationId : null;
    this.integration.IntegrationType = this.currentIntegration
      ? this.currentIntegration.IntegrationType
      : this.integrationTypes[0].name;
    this.integration.Status = !!(
      this.currentIntegration && this.currentIntegration.Status === 'Active'
    );
    if (this.currentIntegration) {
      this.bindIntegrationSettings();
    }
  }

  loadOrganisationsList() {
    try {
      const resp = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
      this.organisationsList = resp;
      if (this.organisationsList !== null && this.organisationsList.length > 0) {
        this.organisationId = this.organisationsList[0].Id;
      }
    } catch (err) {
      if (err.status !== 403) {
        this.toastr.error(
          this.translate.instant('TOASTR.ORGANISATIONS.UNCAUGHT_GET_ORG_LIST_ERROR'),
        );
      }
    }
  }

  createIntegration() {
    this.appendIntegrationSettings();
    this.integrationHttpService.createIntegration(this.integration, this.organisationId).subscribe(
      (res) => {
        if (res.Status === 'Fail' || res.Status === 'Error') {
          if (res.ErrorCode === 'INTEGRATION_EXISTS') {
            this.toastr.error(this.translate.instant('TOASTR.INTEGRATIONS.INTEGRATION_EXISTS'));
            return;
          }
          this.catchErrors(res);
          return;
        }
        this.close();
        this.event.emit();
        this.toastr.success(
          this.translate.instant('TOASTR.INTEGRATIONS.CREATE_INTEGRATION_SUCCESS'),
        );
      },
      () => {
        // TODO : Make the error messages dynamic after the error codes has been set
        this.toastr.error(this.translate.instant('TOASTR.INTEGRATIONS.CREATE_INTEGRATION_FAIL'));
      },
    );
  }

  updateIntegration() {
    this.appendIntegrationSettings();
    const integration = { ...this.integration };
    if (integration.Status === false || integration.Status === 'Inactive') {
      integration.Status = 'Inactive';
    } else {
      integration.Status = 'Active';
    }
    this.integrationHttpService
      .updateIntegration(integration, this.organisationId, this.currentIntegration.Id)
      .subscribe(
        (res) => {
          if (res.Status === 'Fail' || res.Status === 'Error') {
            this.catchErrors(res);
            return;
          }
          this.close();
          this.event.emit();
          this.toastr.success(
            this.translate.instant('TOASTR.INTEGRATIONS.UPDATE_INTEGRATION_SUCCESS'),
          );
        },
        () => {
          // TODO : Make the error messages dynamic after the error codes has been set
          this.toastr.error(this.translate.instant('TOASTR.INTEGRATIONS.UPDATE_INTEGRATION_FAIL'));
        },
      );
  }

  appendIntegrationSettings() {
    this.integration.IntegrationSettings = [];
    const integrationTypeSettings = this.integrationTypes.filter(
      (elem) => elem.name === this.integration.IntegrationType,
    )[0];
    integrationTypeSettings.models.forEach((element, index) => {
      this.integration.IntegrationSettings.push({
        KeyName: integrationTypeSettings.apiFields[index],
        KeyValue: this.keyValues[element],
      });
    });
  }

  bindIntegrationSettings() {
    this.integration.IntegrationSettings = [];
    const integrationTypeSettings = this.currentIntegration.IntegrationSettings;
    const integrationTypeIndex = this.integrationTypes.findIndex(
      (x) => x.name === this.currentIntegration.IntegrationType,
    );

    integrationTypeSettings.forEach((element) => {
      const integrationTypeObj = this.integrationTypes[integrationTypeIndex];
      const keyIndex = integrationTypeObj.apiFields.findIndex((x) => x === element.KeyName);
      this.keyValues[integrationTypeObj.models[keyIndex]] = element.KeyValue;
    });
  }

  catchErrors(res) {
    // ToDo : Toast the error messages
    const key = Object.keys(res.Data)[0];
    this.toastr.error(res.Data[key]);
  }

  close() {
    this.bsModalRef.hide();
  }
}
