import constants from '../../../common/constants';

export default class Register {
  public CountryCode: string;

  public Id: number;

  public MobileNumber: number;

  public FirstName: string;

  public LastName: string;

  public OrganisationName: string;

  public OrganisationUid: string;

  public IsClosedOrganisation: boolean;

  public Email: string;

  public Address: string;

  public Center: {
    Lat?: number;
    Lng?: number;
  };

  public GeoFence: {
    lat: number;
    lng: number;
  }[];

  public ZoomLevel: number;

  public IsCloseOrganisation: boolean;

  public IndustryTypeId: number;

  public Token: string;

  public TimezoneId: number;

  constructor() {
    this.Id = null;
    this.CountryCode = '';
    this.MobileNumber = null;
    this.FirstName = '';
    this.LastName = '';
    this.OrganisationUid = '';
    this.OrganisationName = '';
    this.IsClosedOrganisation = false;
    this.Email = '';
    this.ZoomLevel = constants.HEATMAP_DEFAULTS.ZOOM_LEVEL;
    this.IsCloseOrganisation = false;
    this.IndustryTypeId = null;
    this.Token = null;
    this.TimezoneId = null;
  }
}
