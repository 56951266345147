import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SPINNER } from 'ngx-ui-loader';
import { BsModalService } from 'ngx-bootstrap/modal';
import Register from './models/register.model';
import RegisterService from './services/register-http.service';
import TermsAndCondtionsComponent from './modals/terms-and-condtions/terms-and-condtions.component';
import constants from '../../common/constants';
import httpErrorCodes from '../../common/http_error_codes';
import AuthService from '../../auth/auth.service';

@Component({
  selector: 'app-registration-component',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css'],
})
export default class RegisterComponent implements OnInit {
  public register: Register;

  public spinners = SPINNER;

  public registrationInProgress = false;

  selectedFile: File = null;

  isAgreed;

  supportedCountryList = [];

  distance = 32186.9; // 20 miles in meters

  directions = 359;

  showGeoCodeNotAvailable = false;

  IndustryTypes: {
    Id: number;
    IndustryType: string;
  }[];

  orgTimezone = [];

  constructor(
    private router: Router,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private auth: AuthService,
  ) {
    // logic goes here
  }

  ngOnInit() {
    this.register = new Register();
    this.register.IsClosedOrganisation = false;
    this.spinners = SPINNER;
    this.registrationInProgress = false;
    this.getCountryList();
    this.getIndustryTypes();
    this.getOrganisationTimezone();
    // this.initAutocomplete();
  }

  getIndustryTypes() {
    this.registerService.getIndustryType().subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          this.catchErrors(res);
          return;
        }
        this.IndustryTypes = res.Data.industryTypes;
      },
      () => {
        // handle error
      },
    );
  }

  onFileSelected(event) {
    const fileList = (event.target as HTMLInputElement).files;
    const [selectedFile] = Array.from(fileList);
    this.selectedFile = selectedFile;
  }

  userRegister() {
    this.register.IndustryTypeId = Number(this.register.IndustryTypeId);
    this.register.TimezoneId = Number(this.register.TimezoneId);
    if (
      (this.register.MobileNumber === null || this.register.MobileNumber.toString() === '') &&
      this.register.CountryCode !== ''
    ) {
      this.toastr.info(this.translate.instant('TOASTR.REGISTER.VALIDATION.MOBILE_NUMBER_REQUIRED'));
      return;
    }
    if (
      this.register.MobileNumber !== null &&
      this.register.MobileNumber.toString() !== '' &&
      this.register.CountryCode === ''
    ) {
      this.toastr.info(this.translate.instant('TOASTR.REGISTER.VALIDATION.COUNTRY_CODE_REQUIRED'));
      return;
    }
    this.registrationInProgress = true;
    this.registerService.userRegister(this.register).subscribe(
      async (res: { Status: string; Data: { Organisation: Register } }) => {
        if (res.Status === 'Fail' || res.Status === 'Error') {
          this.catchErrors(res);
          return;
        }

        this.register = res.Data.Organisation;
        const token = res.Data.Organisation.Token;
        if (this.selectedFile) {
          await this.uploadOrganisationIcon(this.register.Id, token);
        }
        this.register = res.Data.Organisation;
        this.getOrganisationDetails(
          this.register.OrganisationUid,
          this.register.OrganisationName,
          this.register.Email,
          this.register.IsCloseOrganisation,
          this.register.Id,
        );
      },
      (err) => {
        this.registrationInProgress = false;
        if (err.status === httpErrorCodes.API_CALL_LIMIT_EXCEEDED) {
          this.toastr.error(this.translate.instant('COMMON.API_CALL_LIMIT_EXCEEDED'));
        } else {
          // TODO : Change error message once they are standardised by the backend.
          this.toastr.error(this.translate.instant('TOASTR.PORTAL_USERS.USER_EXISTS'));
        }
      },
    );
  }

  uploadOrganisationIcon(orgId, token) {
    const formData = new FormData();
    formData.append('OrgIcon', this.selectedFile);
    this.registerService.uploadOrganisationIcon(formData, orgId, token).subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          this.catchErrors(res);
        }
      },
      () => {
        this.registrationInProgress = false;
        this.toastr.warning(
          this.translate.instant('TOASTR.REGISTER.ORGANISATION_CREATE_ERROR_ICON'),
        );
      },
    );
  }

  catchErrors(err) {
    if (err.ErrorCode === 'USER_EXISTS' || err.Message?.includes('Duplicate entry')) {
      this.toastr.error(this.translate.instant('TOASTR.REGISTER.USER_EXISTS'));
    } else if (err.ErrorCode === 'USER_HAS_PORTAL_ACCESS') {
      this.toastr.error(this.translate.instant('TOASTR.REGISTER.USER_HAS_PORTAL_ACCESS'));
    } else {
      let errorMessage;
      if (err) {
        errorMessage = err.Message ? err.Message : err.ErrorCode;
      } else {
        errorMessage =
          'There was an uncaught error while processing your request, please try again';
      }
      this.toastr.error(errorMessage);
    }
    this.registrationInProgress = false;
  }

  getOrganisationDetails(uniqueId, organisationName, email, isClosedOrganisation, organisationId) {
    localStorage.setItem(
      constants.REGISTERED_ORG_DETAILS,
      JSON.stringify({
        uniqueId,
        organisationName,
        email,
        isClosedOrganisation,
        organisationId,
      }),
    );

    if (this.auth.buyPackage === null || this.auth.buyPackage.PackageId === 1) {
      this.auth.activeForm = 'successMessageComponent';
      window.location.hash = 'home#registrationSuccess';
    } else {
      localStorage.setItem(constants.BEARER_TOKEN, this.register.Token);
      this.auth.activeForm = 'billingSummaryComponent';
    }
  }

  showTermsAndConditions() {
    this.modalService.show(TermsAndCondtionsComponent);
  }

  showLogin() {
    this.router.navigate(['login']);
  }

  getCountryList() {
    this.registerService.getCountryList().subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          this.catchErrors(res);
          return;
        }
        this.supportedCountryList = res.Data.Countries;
      },
      () => {
        // handle error
      },
    );
  }

  getOrganisationTimezone() {
    this.registerService.getTimezone().subscribe(
      (res: { Status: string; Data }) => {
        if (res.Status === 'Success') {
          this.orgTimezone = res.Data.TimeZones.sort((a, b) =>
            a.TimezoneCode.localeCompare(b.TimezoneCode),
          );
          this.register.TimezoneId = this.orgTimezone[0].Id;
        }
      },
      (err: { Data: { [x: string]: string } }) => {
        this.catchErrors(err);
      },
    );
  }

  initAutocomplete() {
    // Create the search box and link it to the UI element.
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      this.register.Center = {};
      this.register.GeoFence = [];

      if (places.length === 0) {
        this.showGeoCodeNotAvailable = true;
        return;
      }

      const resultPlace = places[0];
      this.register.Address = resultPlace.formatted_address;
      if (!resultPlace.geometry) {
        this.registerService.getLatLngByLocation(resultPlace.formatted_address).subscribe(
          (res: {
            status: string;
            results: { geometry: { location: { lat: number; lng: number } } }[];
          }) => {
            if (res.status === 'OK') {
              this.register.Center = {
                Lat: res.results[0].geometry.location.lat,
                Lng: res.results[0].geometry.location.lng,
              };
              this.register.GeoFence = this.generateGeoJSONCircle(
                new google.maps.LatLng(this.register.Center.Lat, this.register.Center.Lng),
                this.distance,
                this.directions,
              );
            } else {
              this.showGeoCodeNotAvailable = true;
            }
          },
          () => {
            this.showGeoCodeNotAvailable = true;
          },
        );
      } else {
        this.register.Center = {
          Lat: resultPlace.geometry.location.lat(),
          Lng: resultPlace.geometry.location.lng(),
        };
        this.register.GeoFence = this.generateGeoJSONCircle(
          resultPlace.geometry.location,
          this.distance,
          this.directions,
        );
      }
    });
  }

  generateGeoJSONCircle(center, distance, numSides) {
    this.showGeoCodeNotAvailable = false;
    const points: {
      lat: number;
      lng: number;
    }[] = [];
    const degreeStep = 360 / numSides;

    for (let i = 0; i < numSides; i += 1) {
      const gPos = google.maps.geometry.spherical.computeOffset(center, distance, degreeStep * i);
      points.push({ lng: gPos.lng(), lat: gPos.lat() });
    }

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);

    return points;
  }
}
