<div *ngIf="isLandingPage === false" style="margin-bottom: 22px; margin-top: 22px">
  <h3 style="text-align: center">
    {{ 'LABELS.Payment.SUCCESS_MSG' | translate }}
  </h3>
  <p style="text-align: center">
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT5' | translate }}
    <b>{{ purchaseData.CurrencyCode }} {{ purchaseData.AmountPaid | number: '1.2-2' }}</b>
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT6' | translate }} <b>{{ purchaseData.PackageName }}</b>
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT3' | translate }}
    <b>{{ purchaseData.NumberOfMonths }} {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT4' | translate }}</b
    >.
  </p>
  <p style="text-align: center">
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_REFERENCE' | translate }}
    <b>{{ purchaseData.PaymentReferenceNumber }}</b
    >.
  </p>
  <p style="text-align: center">
    {{ 'LABELS.Payment.LOGOUT_INFO' | translate }}
  </p>
  <div class="text-center">
    <button class="btn btn-primary" (click)="logout()" type="button">
      {{ 'LABELS.SUCCESS.BUTTON.PROCEED_TO_LOGOUT' | translate }}
    </button>
  </div>
</div>
<div *ngIf="isLandingPage === true" style="margin-bottom: 22px; margin-top: 22px">
  <h3 style="text-align: center">
    {{ organisationDetails.organisationName }}
    {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.NAME' | translate }}
  </h3>
  <p>
    {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.EMAIL_BEFORE_MSG' | translate }}
    <b>{{ organisationDetails.email }}</b>
    {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.EMAIL_AFTER_MSG' | translate }}
  </p>
  <p style="text-align: center">
    {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_UID_MSG' | translate }}
    <b>{{ organisationDetails.uniqueId }}</b>
  </p>
  <p style="text-align: center" [hidden]="organisationDetails.isClosedOrganisation === 'false'">
    {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_BEFORE_UID' | translate }}
    <b>{{ organisationDetails.uniqueId }}</b
    >{{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_AFTER_UID' | translate }}
  </p>
  <h3 style="text-align: center">
    {{ 'LABELS.Payment.SUCCESS_MSG' | translate }}
  </h3>
  <p style="text-align: center">
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT5' | translate }}
    <b>{{ purchaseData.CurrencyCode }} {{ purchaseData.AmountPaid | number: '1.2-2' }}</b>
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT6' | translate }} <b>{{ purchaseData.PackageName }}</b>
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT3' | translate }}
    <b>{{ purchaseData.NumberOfMonths }} {{ 'LABELS.Payment.PAYPAL_PAYMENT_TEXT4' | translate }}</b
    >.
  </p>
  <p style="text-align: center">
    {{ 'LABELS.Payment.PAYPAL_PAYMENT_REFERENCE' | translate }}
    <b>{{ purchaseData.PaymentReferenceNumber }}</b
    >.
  </p>
  <div class="text-center">
    <button class="btn btn-primary" (click)="showLoginForm()" type="button">
      {{ 'LABELS.SUCCESS.BUTTON.PROCEED_TO_LOGIN' | translate }}
    </button>
  </div>
</div>
