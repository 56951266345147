<div class="col-lg-12 cards-container">
  <div class="card graph-card" (click)="goToGraphPage('monthly_resolution')">
    <div class="hover-overlay icon-div" mdbRipple rippleColor="light">
      <div class="icon-div" (click)="goToGraphPage('monthly_resolution')">
        <i class="fa fa-line-chart"></i>
      </div>
    </div>
    <div class="card-body card-content">
      <h5 class="card-title" style="font-weight: bold">
        {{ 'REPORTS.MONTHLY_REPORTS.PAGE_TITLE' | translate }}
      </h5>
      <p class="card-text">
        {{ 'REPORTS.MONTHLY_REPORTS.DESCRIPTION' | translate }}
      </p>
    </div>
  </div>

  <div class="card graph-card" (click)="goToGraphPage('overall_resolution')">
    <div class="hover-overlay icon-div" mdbRipple rippleColor="light">
      <div class="icon-div" (click)="goToGraphPage('overall_resolution')">
        <i class="fa fa-bar-chart"></i>
      </div>
    </div>
    <div class="card-body card-content">
      <h5 class="card-title" style="font-weight: bold">
        {{ 'REPORTS.OVERALL_REPORTS.PAGE_TITLE' | translate }}
      </h5>
      <p class="card-text">{{ 'REPORTS.OVERALL_REPORTS.DESCRIPTION' | translate }}</p>
    </div>
  </div>

  <div class="card graph-card" (click)="goToGraphPage('fieldstaff_reports')">
    <div class="hover-overlay icon-div" mdbRipple rippleColor="light">
      <div class="icon-div" (click)="goToGraphPage('fieldstaff_reports')">
        <i class="fa fa-pie-chart"></i>
      </div>
    </div>
    <div class="card-body card-content">
      <h5 class="card-title" style="font-weight: bold">
        {{ 'REPORTS.FIELDSTAFF_REPORTS.PAGE_TITLE' | translate }}
      </h5>
      <p class="card-text">{{ 'REPORTS.FIELDSTAFF_REPORTS.DESCRIPTION' | translate }}</p>
    </div>
  </div>
  
  <div class="card graph-card" (click)="goToGraphPage('asset_reports')">
    <div class="hover-overlay icon-div" mdbRipple rippleColor="light">
      <div class="icon-div" (click)="goToGraphPage('asset_reports')">
        <i class="fa fa-area-chart"></i>
      </div>
    </div>
    <div class="card-body card-content">
      <h5 class="card-title" style="font-weight: bold">
        {{ 'REPORTS.ASSET_REPORTS.PAGE_TITLE' | translate }}
      </h5>
      <p class="card-text">{{ 'REPORTS.ASSET_REPORTS.DESCRIPTION' | translate }}</p>
    </div>
  </div>
</div>
