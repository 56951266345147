/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AclService } from 'ng2-acl';
import AclRedirection from '../../app.redirection';

@Injectable()
export default class AclHeatMapResolver implements Resolve<any> {
  constructor(
    private aclService: AclService,
    private aclRedirection: AclRedirection,
  ) {
    // logic goes here
  }

  resolve(): Observable<any> {
    if (this.aclService.can('heatMap')) {
      // Has proper permissions
      return of(true);
    }

    this.aclRedirection.redirectTo('Unauthorized');
    return of(null);
  }
}
