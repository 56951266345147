import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import EnvService from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import OrganisationService from '../../organisations/services/organisation-http.service';
import { AppointmentsApiModel, AppointmentsConfig, ExportApiModel, OpenIncidentsModel, SlotApiModel } from '../appointments.interface';
import { BasicApiModel } from '../../../interfaces/global.interface';

@Injectable({
  providedIn: 'root',
})
export default class AppointmentsService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  static getQueryParams(name, values) {
    let str = '&';
    values.forEach((ele) => {
      str += `${name}=${ele}&`;
    });
    return str.substring(0, str.length - 1);
  }

  static downloadFile(filePath) {
    const a = document.createElement('a');
    a.href = filePath;
    a.download = filePath.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  saveAppointmentsConfig(config) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings`;
    return this.httpClient
      .post(API_URL, config)
      .map((data: AppointmentsConfig) => data)
      .catch((error: AppointmentsConfig) => throwError(error));
  }

  updateAppointmentsConfig(config) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings/${config.Id}`;
    return this.httpClient
      .put(API_URL, config)
      .map((data: AppointmentsConfig) => data)
      .catch((error: AppointmentsConfig) => throwError(error));
  }

  getAppointmentsConfig(locationId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSettings?LocationId=${locationId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: AppointmentsConfig) => data)
      .catch((error: AppointmentsConfig) => throwError(error));
  }

  getSlotsByDate(date, locationId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/AppointmentSlots?Date=${date}&LocationId=${locationId}`;
    return this.httpClient
      .get(API_URL)
      .map((data: SlotApiModel) => data)
      .catch((error: SlotApiModel) => throwError(error));
  }

  createAppointment(appointment) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Appointments`;
    return this.httpClient
      .post(API_URL, appointment)
      .map((data: AppointmentsApiModel) => data)
      .catch((error: AppointmentsApiModel) => throwError(error));
  }

  getAppointmentList(
    params,
    pageNumber,
    dtCallback,
    sites,
    fromDate,
    toDate,
    searchTerm,
    sortingColumn,
    sortingOrder,
  ) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Appointments?PageNumber=${pageNumber}&PageLimit=${params.length}&SortingColumn=${sortingColumn}&SortingOrder=${sortingOrder}&SearchTerm=${searchTerm}&FromDate=${fromDate}&ToDate=${toDate}&OrgId=${orgId}`;
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += AppointmentsService.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: AppointmentsApiModel) => {
        dtCallback({
          recordsTotal: data.Data.TotalNumberOfRecords || 0,
          recordsFiltered: data.Data.TotalNumberOfRecords || 0,
          data: [],
        });
        return data;
      })
      .catch((error: AppointmentsApiModel) => throwError(error));
  }

  updateAppointmentStatus(status, appointmentId) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Appointments/${appointmentId}`;
    return this.httpClient
      .put(API_URL, {
        ApprovalStatus: status,
      })
      .map((data: BasicApiModel) => data)
      .catch((error: BasicApiModel) => throwError(error));
  }

  getOpenIncidents() {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/OpenIncidents?UserId=0`;
    return this.httpClient
      .get(API_URL)
      .map((data: OpenIncidentsModel) => data)
      .catch((error: OpenIncidentsModel) => throwError(error));
  }

  exportAppointments(visibleColumns, fromDate, toDate, sites) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    let API_URL = `${this.env.apiUrl}/Appointments?Export=true&FromDate=${fromDate}&ToDate=${toDate}&OrgId=${orgId}`;
    // TODO: Uncomment the following code when API accepts visible columns
    // if (Array.isArray(visibleColumns) && visibleColumns.length > 0) {
    //   API_URL += this.getQueryParams('Column', visibleColumns);
    // }
    if (Array.isArray(sites) && sites.length > 0) {
      const LocationId = sites.map((x) => x.id);
      API_URL += AppointmentsService.getQueryParams('LocationId', LocationId);
    }
    return this.httpClient
      .get(API_URL)
      .map((data: ExportApiModel) => data)
      .catch((error: ExportApiModel) => throwError(error));
  }
}
