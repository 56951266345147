import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-help-modal-dialog',
  templateUrl: './help-modal-dialog.component.html',
  styleUrls: ['./help-modal-dialog.component.scss'],
})
export default class HelpModalDialogComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef) {
    // logic goes here
  }

  public onClose: Subject<boolean>;

  heading: string;

  description: string;

  content;

  points: string[];

  icon: string;

  title: string;

  subText: string;

  yesText: string;

  noText: string;

  subject: string;

  public ngOnInit(): void {
    this.onClose = new Subject();
    this.bsModalRef.setClass('modal-lg');
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
