import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import NotificationService from '../../services/notifications-http.service';
import IncidentService from '../../../incidents/services/incident-http.service';
import constants from '../../../../common/constants';
import Organisation from '../../../organisations/models/organisation.model';
import OrganisationService from '../../../organisations/services/organisation-http.service';
import { getUserListsForNotificationModal } from '../../../../utils/helper';
import ConfirmNotificationComponent from '../../confirm-notification/confirm-notification.component';
import ConfirmationService from '../../confirmation.service';
import BackgroundService from './background.service';
import IncidentLocation from '../../models/incidentLoc';

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.css'],
})
export default class SendNotificationsComponent implements OnInit {
  public notification;

  public currentDivision;

  public selectedNotificationType: string;

  public selectedSite: Array<number>;

  public petParentDropdownSettings: { singleSelection: boolean; selectAllText: string; unSelectAllText: string; badgeShowLimit: number; text: string; enableSearchFilter: boolean; labelKey: string; searchBy: string[]; position: string; autoPosition: boolean; filterSelectAllText: string; filterUnSelectAllText: string; };

  public appUserList = [];

  public importFile = [];

  public event = new EventEmitter();

  public notificationTypeList = constants.notificationTypeListSendNotifications;

  public userGroupList = constants.userGroupList;

  selectedUserGroup: number;

  public FileTypes = constants.FileTypes;

  selectedFiletype = 0;

  public UsersIncidentSiteList: IncidentLocation[];

  isOrgOwner: boolean | string;

  clinicsList: IncidentLocation[];

  public isError: boolean;

  public ErrorMessage: string;

  apiCallInProgress = false;

  notifiedUsers = [];

  globallySelectedOrg: Organisation;

  maxLengthForDescription = 140;

  public siteDropdownSettings: { singleSelection: boolean; selectAllText: string; unSelectAllText: string; badgeShowLimit: number; text: string; enableSearchFilter: boolean; labelKey: string; searchBy: string[]; position: string; autoPosition: boolean; filterSelectAllText: string; filterUnSelectAllText: string; };

  modalRef: BsModalRef;

  data: {};

  fileList: File;

  selectedItems: { id: number; }[];

  selectedAll: IncidentLocation[];

  selectedUsersGroupTitle: string;

  selectedNotificationName: string;

  constructor(
    private bsModalRef: BsModalRef,
    public backgroundService: BackgroundService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private incidentService: IncidentService,
    private notificationService: NotificationService,
    private modalServise: BsModalService,
    private confirmService: ConfirmationService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.backgroundService.setBackgroundBoolean = false;

    this.selectedItems = [];

    this.globallySelectedOrg = OrganisationService.getCurrentGlobalOrganisation();
    if (this.globallySelectedOrg.AllowSms === true) {
      this.notificationTypeList = constants.notificationTypeList;
    } else {
      this.notificationTypeList = constants.notificationTypeListSendNotifications;
    }
    this.isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    this.UsersIncidentSiteList = JSON.parse(localStorage.getItem(constants.USER_INCIDENT_SITES));
    this.selectedUserGroup = this.userGroupList[1].Id;
    this.selectedUsersGroupTitle = this.userGroupList[1].Title;
    this.loadOrgList();
    this.notification = {
      PortalNotificationType: undefined,
      PetParentId: [],
      LocationId: undefined,
      Description: '',
      FileType: 0,
      VideoType: null,
      Link: '',
      UserGroupId: '',
    };
    this.loadOrgList();

    this.selectedAll = this.clinicsList;

    this.petParentDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select all',
      unSelectAllText: 'Unselect all',
      badgeShowLimit: 1,
      text: 'Select the user',
      enableSearchFilter: true,
      labelKey: 'itemName',
      searchBy: ['itemName'],
      position: 'bottom',
      autoPosition: false,
      filterSelectAllText: 'Select all filtered results',
      filterUnSelectAllText: 'Unselect all filtered results',
    };
    this.siteDropdownSettings = {
      singleSelection: false,
      selectAllText: 'Select all',
      unSelectAllText: 'Unselect all',
      badgeShowLimit: 1,
      text: 'Select the site',
      enableSearchFilter: true,
      labelKey: 'itemName',
      searchBy: ['itemName'],
      position: 'bottom',
      autoPosition: false,
      filterSelectAllText: 'Select all filtered results',
      filterUnSelectAllText: 'Unselect all filtered results',
    };
  }

  clearPetParent() {
    this.notifiedUsers = [];
  }

  clearIncidentSites() {
    this.notification.LocationId = [];
    this.selectedAll = [];
    this.selectedSite = [];
  }

  async getAppUserList(locId: (string | number)[]) {
    try {
      const resp = await this.incidentService.getAppUserList(locId).toPromise();
      const appUserList = resp.Data.Users;

      // For all users group
      if (this.notification.UserGroupId === '1' || this.selectedUserGroup === 1) {
        this.appUserList = appUserList;
      }
      // For only mobile app users group
      if (this.notification.UserGroupId === '2' || this.selectedUserGroup === 2) {
        this.appUserList = appUserList.filter(item => item.IsAppUser === true);
      }
      // // For only non-app users group
      if (this.notification.UserGroupId === '3' || this.selectedUserGroup === 3) {
        this.appUserList = appUserList.filter(item => item.IsAppUser === false);
      }
      this.appUserList = getUserListsForNotificationModal(this.appUserList);
      this.notifiedUsers = this.appUserList.slice();
      return this.appUserList;
    } catch (e) {
      if (e.status !== 403) {
        this.toastr.error(this.translate.instant('TOASTR.USERS.UNCAUGHT_GET_USER_LIST_ERROR'));
      }
    }
    return null;
  }

  async getIncidentLocations() {
    const resp = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
    if (
      this.UsersIncidentSiteList &&
      this.UsersIncidentSiteList.length !== 0 &&
      this.isOrgOwner !== 'true'
    ) {
      this.clinicsList = this.UsersIncidentSiteList.slice();
    } else {
      const optionsForSiteField = resp.map((site: { Id: number; LocationName: string; }) => ({
        id: site.Id,
        itemName: site.LocationName,
      }));
      try {
        this.clinicsList = optionsForSiteField;
        this.notification.LocationId = this.clinicsList.slice();
        return this.clinicsList;
      } catch (e) {
        // error for catch
      }
    }
    this.selectedSite = this.clinicsList.map((x) => x.id);
    if (this.clinicsList.length <= 1) {
      this.notification.LocationId = this.clinicsList[0]?.Id;
      await this.getAppUserList(this.notification.LocationId);
    } else {
      this.getAppUserList(this.selectedSite);
    }

    return null;
  }

  close() {
    this.bsModalRef.hide();
  }

  async loadOrgList() {
    await this.getIncidentLocations();
  }

  onNotificationDropdownChange(notificationTypeId: string) {
    // INFO: selected notification type is 1 for push notifications & 3 for whatsapp
    this.selectedNotificationType = notificationTypeId;
    if (this.selectedNotificationType === '4') {
      this.selectedNotificationName = this.notificationTypeList[3].Title;
    } else if (this.selectedNotificationType === '3') {
      this.selectedNotificationName = this.notificationTypeList[2].Title;
    } else if (this.selectedNotificationType === '1') {
      this.selectedNotificationName = this.notificationTypeList[1].Title;
    } else {
      this.selectedNotificationType = this.notificationTypeList[0].Title;
    }

    if (notificationTypeId === '3') {
      this.maxLengthForDescription = null;
    } else {
      this.maxLengthForDescription = 140;
    }
  }

  async onLocDropdownChange() {
    if (this.notification.LocationId !== undefined && this.notification.LocationId.length > 0) {
      const incidentSiteData = this.notification.LocationId;
      this.selectedSite = incidentSiteData.map((x) => x.id);
    }
    await this.getAppUserList(this.selectedSite);
  }

  onFileSelected(event: { target: { files: [File] }; }) {
    this.importFile = [];
    const [selectedFile] = event.target.files;
    this.fileList = selectedFile;

    const fileType = this.fileList.type.toLowerCase();
    const fileName = this.fileList.name.toLowerCase();

    if (
      this.notification.PortalNotificationType === '3' &&
      ((fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/heic' ||
        fileType === 'image/heif' ||
        fileType === 'image/heic-sequence' ||
        fileType === 'image/JPEG' ||
        fileType === 'image/JPG' ||
        fileType === 'image/PNG' ||
        fileType === 'video/mp4' ||
        fileType === 'video/mov' ||
        fileType === 'video/wmv' ||
        fileType === 'video/avi' ||
        fileType === 'video/mkv' ||
        fileType === 'video/webm' ||
        fileType === 'video/MP4' ||
        fileType === 'video/MOV' ||
        fileType === 'video/WMV' ||
        fileType === 'video/AVI' ||
        fileType === 'video/MKV' ||
        fileType === 'video/WEBM' ||
        fileType === 'application/pdf' ||
        fileType === 'application/PDF' ||
        fileType === 'application/msword' ||
        fileType === 'text/plain') ||
        fileName.endsWith('.heic') ||
        fileName.endsWith('.heif') ||
        fileName.endsWith('.heic-sequence'))
    ) {
      this.importFile.push(this.fileList);
      this.isError = false;
      this.ErrorMessage = '';

      if (
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/JPEG' ||
        fileType === 'image/JPG' ||
        fileType === 'image/heic' ||
        fileType === 'image/heif' ||
        fileType === 'image/PNG'
      ) {
        this.notification.FileType = 1;
        this.notification.VideoType = null;
        this.selectedFiletype = 1;
      } else if (
        fileType === 'application/pdf' ||
        fileType === 'application/PDF' ||
        fileType === 'application/msword' ||
        fileType === 'text/plain'
      ) {
        this.notification.FileType = 2;
        this.notification.VideoType = null;
        this.selectedFiletype = 2;
      } else {
        this.notification.VideoType = 2;
        this.notification.FileType = 0;
        this.selectedFiletype = 2;
      }
    } else if (
      this.notification.PortalNotificationType !== '3' &&
      (fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/JPEG' ||
        fileType === 'image/JPG' ||
        fileType === 'image/heic' ||
        fileType === 'image/HEIC' ||
        fileType === 'image/PNG')
    ) {
      this.importFile.push(this.fileList);
      this.isError = false;
      this.ErrorMessage = '';

      if (
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/JPEG' ||
        fileType === 'image/JPG' ||
        fileType === 'image/heic' ||
        fileType === 'image/HEIC' ||
        fileType === 'image/PNG'
      ) {
        this.notification.FileType = 1;
        this.notification.VideoType = null;
        this.selectedFiletype = 1;
      }
    } else {
      this.isError = true;
      this.selectedFiletype = 2;
      this.ErrorMessage =
        this.notification.PortalNotificationType !== '3'
          ? 'Image file error message for non-3 portal notification type'
          : 'WhatsApp file error message';
    }

    if (!this.fileList) {
      this.importFile = [];
      this.selectedFiletype = 0;
    }
  }


  // onFileTypeChange(): void {
  //   if (this.notification.FileType !== 0) {
  //     this.notification.ImageURL = '';
  //   }
  // }
  onFileTypeChangeFile() {
    if (
      (this.notification.FileType !== 1 && this.notification.FileType !== 2) ||
      this.notification.VideoType !== 2
    ) {
      this.fileList = null;
      this.selectedFiletype = 0;
    }
  }

  async OnUserGroupChange(userGroupId: string) {
    this.notification.UserGroupId = userGroupId;
    this.selectedUsersGroupTitle = this.userGroupList[userGroupId].Title;
    if (Number(this.selectedUserGroup) === 3) {
      this.notification.PortalNotificationType = undefined;
    }
    await this.getAppUserList(this.selectedSite);
  }

  onSelectItems(item: { id: number; }) {
    const index = this.selectedItems.findIndex((selectedItem: { id: number }) => selectedItem.id === item.id);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
  }

  onSelectAll(items) {
    this.selectedAll = items.map((item: { itemName: string; }) => ({ itemName: item.itemName }));
  }

  onConfirm() {
    if (this.selectedFiletype === 2 && !this.fileList) {
      this.toastr.error(this.translate.instant('TOASTR.NOTIFICATIONS.CHOOSE_FILE'));
      return;
    }
    const attachments = this.fileList && this.fileList.name ? this.fileList.name : '';
    const notificationType = this.selectedNotificationName;
    const userType = this.selectedUsersGroupTitle;

    this.data = {
      selectedSite:
        this.selectedAll && this.selectedAll.length === this.clinicsList.length
          ? this.selectedAll
          : this.selectedItems,
      Description: this.notification.Description,
      Link: this.notification.Link,
      Attachments:
        (this.notification.FileType !== 0 && this.notification.FileType !== null) ||
          (this.notification.VideoType !== 0 && this.notification.VideoType !== null)
          ? attachments
          : '',
      // ImageUrl : this.notification.ImageURL,
      NotificationType: notificationType,
      userType,
      notifiedUsers: this.notifiedUsers,
      ...(this.notification.FileType !== 0 &&
        this.notification.FileType !== null && { FileType: this.notification.FileType }),
      ...(this.notification.VideoType !== 0 &&
        this.notification.VideoType !== null && { VideoType: this.notification.VideoType }),
    };
    this.confirmService.formData = this.data;
    setTimeout(() => {
      this.backgroundService.setBackgroundBoolean = true;
    }, 100);
    this.modalRef = this.modalServise.show(ConfirmNotificationComponent, {
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRef.content.event.subscribe(() => {
      this.modalRef.hide();
      this.sendNotification();
    });
    // this.close();
  }

  sendNotification() {
    this.event.emit();
    const notificationPayload = new FormData();
    notificationPayload.append('PortalNotificationType', this.notification.PortalNotificationType);
    if (this.notifiedUsers !== undefined) {
      if (this.notifiedUsers.length > 0) {
        if (Array.isArray(this.notifiedUsers) && this.notifiedUsers.length > 0) {
          const petParentData = this.notifiedUsers;
          const PetParentId = petParentData.map((x) => x.id);
          this.notification.PetParentId = PetParentId;
          notificationPayload.append('petParentId', JSON.stringify(this.notification.PetParentId));
        }
      }
    }
    const petparent = this.notification.PetParentId;
    notificationPayload.append('Description', this.notification.Description);
    if (
      this.notification.FileType &&
      this.notification.FileType !== 0 &&
      this.selectedNotificationType !== '4'
    ) {
      notificationPayload.append('FileType', this.notification.FileType);
    }
    if (
      this.notification.VideoType &&
      this.notification.VideoType !== 0 &&
      this.selectedNotificationType !== '4'
    ) {
      notificationPayload.append('VideoType', this.notification.VideoType);
    }
    if (
      (this.notification.FileType === 1 || this.notification.FileType === 2) &&
      this.importFile.length !== 0 &&
      this.selectedNotificationType !== '4'
    ) {
      for (let i = 0; i < this.importFile.length; i += 1) {
        notificationPayload.append('Files', this.importFile[i]);
      }
    } else if (
      this.notification.VideoType === 2 &&
      this.importFile.length !== 0 &&
      this.selectedNotificationType !== '4'
    ) {
      for (let i = 0; i < this.importFile.length; i += 1) {
        notificationPayload.append('Files', this.importFile[i]);
      }
    }
    if (this.notification.Link !== '') {
      notificationPayload.append('LinkUrl', this.notification.Link);
    }
    this.apiCallInProgress = true;
    this.notificationService
      .sendNotification(notificationPayload)
      .subscribe(
        (res) => {
          if (res.Status === 'Fail' || res.Status === 'Error') {
            this.notification.PetParentId = petparent;
            if (res.ErrorCode === 'REQUESTED_FILETYPE_INVALID') {
              this.toastr.error(this.translate.instant('TOASTR.NOTIFICATIONS.INVALD_IMAGE_TYPE'));
            } else if (res.Message === 'request contains invalid Image Url.') {
              this.toastr.error(
                this.translate.instant('TOASTR.NOTIFICATIONS.WRONG_FORMAT_OF_IMG_URL'),
              );
            } else {
              this.toastr.error(
                this.translate.instant('TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_FAIL'),
              );
            }
            return;
          }
          this.close();
          // this.event.emit();
          this.toastr.success(
            this.translate.instant('TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_SUCCESS'),
          );
        },
        (err) => {
          if (err.status !== 403) {
            this.toastr.error(
              this.translate.instant('TOASTR.NOTIFICATIONS.SEND_NOTIFICATION_FAIL'),
            );
          }
        },
      )
      .add(() => {
        this.apiCallInProgress = false;
      });
    this.close();
  }

  goBack() {
    this.close();
  }
}
