import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export default class AclRedirection {
  constructor(private router: Router) {
    // logic goes here
  }

  redirectTo(type: string) {
    if (type === 'Unauthorized') {
      this.router.navigate(['401']);
    }
  }
}
