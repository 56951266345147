import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import LoginService from '../login/services/login-http.service';

@Component({
  selector: 'app-release-versions',
  templateUrl: 'release-versions.component.html',
  styleUrls: ['./release-versions.component.css'],
})
export default class ReleaseVersionsComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef<HTMLElement>;

  id: string;

  forPortal = true;

  forMobile = true;

  constructor(
    private scroller: ViewportScroller,
    private loginService: LoginService,
    private actRoute: ActivatedRoute,
  ) {
    // logic goes here
  }

  ngOnInit() {
    this.actRoute.queryParams.subscribe((params) => {
      if (params.for === 'mobile') {
        this.forPortal = false;
        this.forMobile = true;
      } else if (params.for === 'portal') {
        this.forPortal = true;
        this.forMobile = false;
      } else {
        this.forPortal = true;
        this.forMobile = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.id = this.actRoute.snapshot.params.id;
    const v = this.id.split('.');
    this.id = v[0] + v[1] + v[2];
    if (this.id) {
      this.onClick(this.id);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  OnToggle() {
    document.getElementById('sidebarMenu').classList.toggle('toggle');
  }

  onClick(id: string) {
    // console.log(id);

    const section = this.container.nativeElement.querySelector(`#${id}`);
    if (section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 500);
    }
    // section?.scrollIntoView({behavior:'smooth'});
  }

  backToHome() {
    this.loginService.backToHome();
  }
}
