import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AclService } from 'ng2-acl';
import LoginService from '../login/services/login-http.service';
import ServerstatusService from './services/serverstatus.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css'],
})
export default class MaintenanceComponent implements OnInit {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private aclService: AclService,
    private serverStatus: ServerstatusService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.getServerStatus();
  }

  getServerStatus() {
    this.serverStatus.getServerStatus().subscribe((res) => {
      if (res.Data.Health === true) {
        const isUserLoggedIn = LoginService.isUserLoggedIn();
        if (isUserLoggedIn) {
          if (this.aclService.can('dashboard')) {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['incidents/list']);
          }
        } else {
          this.router.navigate(['']);
        }
      }
    });
  }
}
