import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import EnvService from '../../env.service';
import constants from '../../common/constants';
import PaymentService from '../billing/services/payment.service';
import AuthService from '../../auth/auth.service';
import User from '../login/models/user.model';
import { CustomWindow } from '../../interfaces/global.interface';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export default class LoginPageComponent implements OnInit, OnDestroy {
  user: User = {};

  public selectedPackagePriceDetails;

  countryList = [];

  languageList = [];

  timeSlots;

  youTubeUrl;

  selectedLang;

  supportedLangs;

  refreshContent = false;

  year: number = moment().get('year');

  deepLink = '';

  customWindow: CustomWindow;

  constructor(
    public auth: AuthService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private env: EnvService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private deviceDetector: DeviceDetectorService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.customWindow = window as any as CustomWindow;
  }

  initLiveChat() {
    let $tawkToAPI = this.customWindow.Tawk_API;
    if ($tawkToAPI) {
      $tawkToAPI.showWidget();
      return;
    }
    (() => {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5f2ac6a55c885a1b7fb6926e/default';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      const interval = setInterval(() => {
        $tawkToAPI = this.customWindow.Tawk_API;
        if ($tawkToAPI) {
          if (localStorage.getItem(constants.BEARER_TOKEN)) {
            if ($tawkToAPI.isChatHidden()) {
              clearInterval(interval);
            } else {
              $tawkToAPI.hideWidget();
            }
          } else {
            clearInterval(interval);
          }
        }
      }, 1000);
    })();
  }

  ngOnInit(): void {
    const isMobile = this.deviceDetector.isMobile();
    if (isMobile) {
      this.deepLink = 'incidentreporter365://';
      window.location.href = this.deepLink.toString();
      this.router.navigate(['downloads']);
    }

    this.youTubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.YouTubeUrl);
    this.timeSlots = this.env.demoSlots;
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    this.selectedLang = userLang || this.env.defaultLang;
    this.customWindow.hideLoader();
    this.initLiveChat();
    this.user.Country = '';
    this.user.Slot = '';
  }

  submitDemoRequest() {
    this.auth.submitDemoRequest(this.user).subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          this.catchLoginErrors(res);
          return;
        }
        this.user = {};
        this.user.Country = '';
        this.user.Slot = '';
        this.toastr.success('Your request has been submitted successfully');
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error('There was an error while submitting your request, please try again');
        }
      },
    );
  }

  onBuyPkg(pkg) {
    if (pkg.NumOfMonths === undefined) {
      this.selectedPackagePriceDetails = null;
      this.router.navigate(['register']);
      this.auth.buyPackage = this.selectedPackagePriceDetails;
      window.scrollTo(0, 0);
      return;
    }
    this.paymentService.getPriceDetails(pkg.Id, pkg.NumOfMonths).subscribe(
      (res) => {
        this.selectedPackagePriceDetails = res.Data.PriceDetails;
        this.selectedPackagePriceDetails.PricePerMonthUSD = pkg.PricePerMonthUSD;
        this.selectedPackagePriceDetails.PricePerMonthINR = pkg.PricePerMonthINR;
        this.router.navigate(['register']);
        this.auth.buyPackage = this.selectedPackagePriceDetails;
        window.scrollTo(0, 0);
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error(
            this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.GET_PRICE_ERROR'),
          );
        }
      },
    );
  }

  catchLoginErrors(res) {
    this.toastr.error(res.Message);
  }

  ngOnDestroy() {
    this.hideWidget();
  }

  hideWidget() {
    const $tawkToAPI = this.customWindow.Tawk_API;
    if ($tawkToAPI) {
      $tawkToAPI.hideWidget();
    }
  }

  onLangChange() {
    this.refreshContent = true;
    this.translate.setDefaultLang(this.selectedLang);
    this.translate.use(this.selectedLang).subscribe(() => {
      localStorage.setItem(constants.APP_LANGUAGE, this.selectedLang);
      setTimeout(() => {
        this.refreshContent = false;
      }, 1000);
    });
  }
}
