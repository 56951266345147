<div class="card">
  <div class="card-body p-4">
    <h3 style="text-align: center">
      {{ organisationDetails.OrganisationName }}
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.NAME' | translate }}
    </h3>
    <p>
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.EMAIL_BEFORE_MSG' | translate }}
      <b>{{ organisationDetails.Email }}</b>
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.EMAIL_AFTER_MSG' | translate }}
    </p>
    <p style="text-align: center">
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_UID_MSG' | translate }}
      <b>{{ organisationDetails.OrganisationUid }}</b>
    </p>
    <p style="text-align: center" [hidden]="organisationDetails.isClosedOrganisation === 'false'">
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_BEFORE_UID' | translate }}
      <b>{{ organisationDetails.OrganisationUid }}</b>
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_AFTER_UID' | translate }}
    </p>
    <p>
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_THE' | translate }}
      <b>{{
        'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_FREE_TRIAL' | translate
      }}</b>
      {{ 'LABELS.SUCCESS.ORGANISATION_SUCCESS_MSG.ORGANISATION_NOTE_DESC' | translate }}
    </p>
    <div class="text-center">
      <button class="btn btn-primary" (click)="showLoginForm()" type="button">
        {{ 'LABELS.SUCCESS.BUTTON.PROCEED_TO_LOGIN' | translate }}
      </button>
    </div>
  </div>
</div>
