/* eslint-disable no-param-reassign */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SPINNER } from 'ngx-ui-loader';
import { AclService } from 'ng2-acl/dist';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import EnvService from '../../env.service';
import LoginService from '../../views/login/services/login-http.service';
import constants from '../../common/constants';
import sideNavItems from '../../_nav';
import OrganisationService from '../../views/organisations/services/organisation-http.service';
import Organisation from '../../views/organisations/models/organisation.model';
import SharedService from '../../services/shared-service/shared.service';
import SetTitleService from './services/set-title.service';
import { CustomWindow } from '../../interfaces/global.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css'],
})
export default class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;

  public navItems = [];

  public spinners = SPINNER;

  public version: string;

  year: number = moment().get('year');

  userData;

  interval: NodeJS.Timeout;

  apiUrl: string;

  userOwnedOrganisations = [];

  selectedOrganisation: Organisation;

  selectedChangedOrganisation: { Id: number; OrganisationName: string; };

  customWindow: CustomWindow;

  constructor(
    private router: Router,
    private aclService: AclService,
    private loginService: LoginService,
    private translate: TranslateService,
    private env: EnvService,
    private toastr: ToastrService,
    private orgService: OrganisationService,
    private sharedService: SharedService,
    private navigation: SetTitleService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.customWindow = window as any as CustomWindow;
  }

  static setChatDetails($tawkToAPI, isLoggedIn) {
    const currentUserDetails =
      JSON.parse(localStorage.getItem(constants.CURRENT_USER_DETAILS)) || {};
    $tawkToAPI.setAttributes({
      name: currentUserDetails.DisplayName || '',
      email: currentUserDetails.Email || '',
      LoginStatus: isLoggedIn ? `Logged in with ${currentUserDetails.Email}` : 'Not logged in',
    });
  }

  static removeLocalStorageDataForOrg() {
    localStorage.removeItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS);
    localStorage.removeItem(constants.STORAGE_KEYS.DIVISIONS);
    localStorage.removeItem(constants.DASHBOARD_FILTER_PREFERENCES);
    localStorage.removeItem(constants.INCIDENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.HEATMAP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.APPOINTMENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.USERS_FILTER_PREFERENCES);
    localStorage.removeItem(constants.ASSET_FILTER_PREFERENCES);
    localStorage.removeItem(constants.BATCH_FILTER_PREFERENCES);
    localStorage.removeItem(constants.SETUP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DATA_TABLE_STATES.INCIDENTS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.USERS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.APPOINTMENTS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.ASSETS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.STAFF);
    localStorage.removeItem(constants.INCIDENT_LIST_FOR_NAV);
    localStorage.removeItem(constants.USER_LIST_FOR_NAV);
    localStorage.removeItem(constants.INCIDENTS_API_CURRENT_FILTER);
    localStorage.removeItem(constants.USERS_API_CURRENT_FILTER);
    localStorage.removeItem(constants.FIELD_STAFF_MAP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.USER_FILTER_PREFERENCES);
    localStorage.removeItem(constants.USER_INCIDENT_SITES);
    localStorage.removeItem(constants.REPORT1_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT2_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT3_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT4_FILTER_PREFERENCES);
    localStorage.removeItem(constants.SELECTED_ASSET_TYPE);
  }

  ngOnInit() {
    this.getOrganisationInfo();
    this.apiUrl = this.env.apiUrl;
    let allNavItemstoShow;

    // INFO : This is important - we can't remove taking data from nav.ts for now as
    // it contains essential elements like dividers, and position of modules
    // eslint-disable-next-line prefer-const
    allNavItemstoShow = JSON.parse(JSON.stringify(sideNavItems)); // DO NOT REMOVE

    this.navigation.getDefaultNavigation().subscribe(
      (res) => {
        if (res.Status === 'Success') {
          const defaultModules = res.Data;
          defaultModules.forEach((module) => {
            const moduleToUpdate = allNavItemstoShow.find(
              (navItem) => Number(navItem.id) === module.Id,
            );

            if (moduleToUpdate) {
              moduleToUpdate.name = module.ModuleName;
              moduleToUpdate.IsVisible = module.IsVisible;
              moduleToUpdate.IsMandatory = module.IsMandatory;
            } else {
              // INFO: This is for any new module not in the UI
              allNavItemstoShow.push({
                id: module.Id,
                name: module.ModuleName,
                // url: '/dashboard',
                icon: 'fa fa-tachometer',
                IsMandatory: module.IsMandatory,
                IsVisible: module.IsVisible,
              });
            }
          });

          this.setModuleVisibility(allNavItemstoShow);
        } else {
          this.setModuleVisibility(allNavItemstoShow);
        }
      },
      (err) => {
        this.catchErrors(err);
      },
    );

    // INFO: This information will be recieved from the APIs
    // this.userData = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION));
    // if (this.userData.Id !== 3) {
    //   const reportIndex = allNavItems.findIndex(x => x.url === '/reports');
    //   if (reportIndex !== -1) {
    //     allNavItems.splice(reportIndex, 1);
    //   }
    // }
    this.version = this.env.version;
    this.sharedService.getOrganisationChange().subscribe(() => {
      this.getOrganisationInfo();
    });
  }

  setModuleVisibility(allNavItemstoShow) {
    let organizationModules;
    const allNavItems = [];
    this.orgService.getOrganizationModules(this.selectedOrganisation.Id).subscribe(
      (res) => {
        organizationModules = res.Data;
        allNavItemstoShow.forEach((navItem: { id: string; name: string; IsVisible: number; IsMandatory: number; }) => {
          const itemFound = organizationModules.find((module) => String(module.Id) === navItem.id);
          navItem.name = itemFound ? itemFound.ModuleName : navItem.name;
          navItem.IsVisible = itemFound ? itemFound.IsVisible : navItem.IsVisible;
          if (navItem.IsVisible === 1 || (itemFound === undefined && navItem.IsMandatory === 1)) {
            allNavItems.push(navItem);
          }
        });
        const supportObj = {
          id: '9',
          name: 'Support',
          url: '/email-support',
          icon: 'fa fa-envelope-o',
          IsMandatory: 1,
          IsVisible: 1,
        };
        if (!allNavItems.find((module) => module.id === '9')) {
          allNavItems.push(supportObj);
        }
        localStorage.setItem('ALL_NAVIGATION', JSON.stringify(allNavItems));
        this.setModuleAccess(allNavItems);
      },
      () => {
        // this.catchErrors(err);
      },
    );
  }

  setModuleAccess(allNavItems) {
    if (!this.aclService.can('dashboard')) {
      const dashboardIndex = allNavItems.findIndex((x) => x.url === '/dashboard');
      if (dashboardIndex !== -1) {
        allNavItems.splice(dashboardIndex, 1);
      }
    }
    if (!this.aclService.can('heatMap')) {
      const heatMapIndex = allNavItems.findIndex((x) => x.url === '/heatmap');
      if (heatMapIndex !== -1) {
        allNavItems.splice(heatMapIndex, 1);
      }
    }
    const isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    if (!isOrgOwner || isOrgOwner === 'false' || isOrgOwner === 'undefined') {
      const billingIndex = allNavItems.findIndex((x) => x.url === '/billing/list');
      if (billingIndex !== -1) {
        allNavItems.splice(billingIndex, 1);
      }
    }
    allNavItems.forEach((navItem: { name: string }) => {
      if (navItem.name) {
        navItem.name = this.translate.instant(navItem.name);
      }
    });
    this.navItems = allNavItems;
    this.checkMenuVisibility();
  }

  getOrganisationInfo() {
    this.userOwnedOrganisations = OrganisationService.getUserOwnedOrganisations();
    this.selectedOrganisation = OrganisationService.getCurrentGlobalOrganisation();
    this.selectedChangedOrganisation = this.userOwnedOrganisations.find(
      (org) => org.Id === this.selectedOrganisation.Id,
    );
    if (!this.selectedChangedOrganisation) {
      return;
    }
    this.userOwnedOrganisations = this.userOwnedOrganisations.filter(
      (org) => org.Id !== this.selectedOrganisation.Id,
    );
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    LoginService.clearLogin();
    if (this.aclService.getRoles().length > 0) {
      this.aclService.detachRole(this.aclService.getRoles()[0]);
    }
    const $tawkToAPI = this.customWindow.Tawk_API;
    if ($tawkToAPI) {
      DefaultLayoutComponent.setChatDetails($tawkToAPI, false);
      $tawkToAPI.showWidget();
    }
    localStorage.removeItem(constants.BATCH_FILTER_PREFERENCES);
    this.router.navigate(['home']);
  }

  downloadData() {
    this.loginService.downloadData().subscribe(
      (res) => {
        if (res.Status === 'Success') {
          const allFilePaths = res.Data.ExportResponse.AllFilePath;
          setTimeout(() => {
            this.downloadFile(allFilePaths);
          });
        } else {
          this.toastr.error(this.translate.instant('COMMON.EXPORT_DATA_NO_DATA'));
        }
        if (res.Status === 'Fail' || res.Status === 'Error') {
          this.toastr.error(this.translate.instant('TOASTR.USERS.DOWNLOAD_USER_ACCOUNT_ERROR'));
          return;
        }
        this.toastr.success(this.translate.instant('TOASTR.USERS.DOWNLOAD_USER_ACCOUNT_SUCCESS'));
      },
      (err) => {
        this.catchErrors(err);
      },
    );
  }

  catchErrors(err) {
    const key = Object.keys(err.Data)[0];
    this.toastr.error(err.Data[key]);
  }

  downloadFile(filePath) {
    for (let i = 0; i < filePath.length; i += 1) {
      const anchorLink = document.createElement('a');
      anchorLink.href = this.apiUrl + filePath[i];
      anchorLink.target = '_blank';
      anchorLink.download = filePath[i].split('/').pop();
      document.body.appendChild(anchorLink);
      anchorLink.click();
      document.body.removeChild(anchorLink);
    }
  }

  async onOrganisationChange(selectedOrg) {
    if (this.selectedOrganisation.Id !== selectedOrg.Id) {
      await DefaultLayoutComponent.removeLocalStorageDataForOrg();
      this.orgService.setCurrentOrganisation(selectedOrg);
      await this.setLocalStorageDataForOrg(selectedOrg.Id);
      window.location.reload();
    }
  }

  async setLocalStorageDataForOrg(orgId) {
    await this.getIncidentSiteByOrg(orgId);
    await this.getDivisionByOrg(orgId);
  }

  async getDivisionByOrg(orgId) {
    const res = await this.orgService.getDivisionByOrg(orgId).toPromise();
    const resp = res.Data.Divisions;
    localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(resp));
  }

  async getIncidentSiteByOrg(orgId) {
    const res = await this.orgService.getIncidentSiteByOrg(orgId).toPromise();
    const resp = res.Data.Locations;
    localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(resp));
  }

  openReleaseHistory() {
    this.router.navigate([]).then(() => {
      window.open(
        `https://incidentreporter365.com/#/release-versions/v${this.env.version}`,
        '_blank',
      );
    });
  }

  checkMenuVisibility() {
    const currentUrl = this.router.url;
    const matchingMenuItem = this.navItems.filter(
      (navItem) => currentUrl.includes(navItem.url) || navItem.url === 'blank',
    )[0];
    if (!matchingMenuItem || matchingMenuItem.IsVisible === 0) {
      this.router.navigate(['/dashboard']);
    }
  }
}
