import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import AuthService from '../../auth/auth.service';
import PaymentService from '../billing/services/payment.service';
import constants from '../../common/constants';
import RegisterService from '../register/services/register-http.service';
import EnvService from '../../env.service';
import ServerstatusService from '../maintenance/services/serverstatus.service';
import { CustomWindow } from '../../interfaces/global.interface';

@Component({
  selector: 'app-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss'],
})
export default class HomeComponent implements OnInit, OnDestroy {
  user = {
    Country: '',
    PhoneNumber: '',
    Name: '',
    Email: '',
    CountryCode: '',
    Message: '',
  };

  public selectedPackagePriceDetails;

  countryList: string[] | number[] = [];

  languageList: string[] = [];

  youTubeUrl: string | Object;

  selectedLang: string;

  supportedLangs: string;

  refreshContent = false;

  year: number = moment().get('year');

  currentPath = '';

  public errorMessage: string;

  customWindow: CustomWindow;

  features = [
    'HOME.FEATURES.FEATURES_1',
    'HOME.FEATURES.FEATURES_2',
    'HOME.FEATURES.FEATURES_3',
    'HOME.FEATURES.FEATURES_4',
    'HOME.FEATURES.FEATURES_5',
    'HOME.FEATURES.FEATURES_6',
  ];

  public countryCodes = constants.COUNTRY_CODES;

  userr = {
    Country: '',
    PhoneNumber: '',
    Name: '',
    Email: '',
    CountryCode: '',
    Message: '',
  };

  constructor(
    public auth: AuthService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    private registerService: RegisterService,
    private serverStatus: ServerstatusService,
    private env: EnvService,
    public sanitizer: DomSanitizer,
    public router: Router,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.customWindow = window as any as CustomWindow;
  }

  getContainerStyle() {
    let returnArg;
    if (this.customWindow.innerWidth > 576) {
      returnArg = 'flex';
    } else {
      returnArg = 'block';
    }
    return returnArg;
  }

  initLiveChat() {
    let $tawkToAPI = this.customWindow.Tawk_API;
    if ($tawkToAPI) {
      $tawkToAPI.showWidget();
      return;
    }
    (() => {
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5f2ac6a55c885a1b7fb6926e/default';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
      const interval = setInterval(() => {
        $tawkToAPI = this.customWindow.Tawk_API;
        if ($tawkToAPI) {
          if (localStorage.getItem(constants.BEARER_TOKEN)) {
            if ($tawkToAPI.isChatHidden()) {
              clearInterval(interval);
            } else {
              $tawkToAPI.hideWidget();
            }
          } else {
            clearInterval(interval);
          }
        }
      }, 1000);
    })();
  }

  ngOnInit(): void {
    this.youTubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.env.YouTubeUrl);
    const userLang = localStorage.getItem(constants.APP_LANGUAGE);
    this.selectedLang = userLang || this.env.defaultLang;
    this.customWindow.hideLoader();
    this.initLiveChat();
    this.getCountryList();
    this.getLanguageList();
    this.user.Country = '';
    if (window.location.href.includes('home#')) {
      const id = window.location.href.replace(/(.*)#(\/home)#(.*)/g, '$3');
      if (id) {
        setTimeout(() => {
          $('html, body').animate(
            {
              scrollTop: $(`#${id}`).offset().top,
            },
            500,
          );
        }, 1000);
      }
    }
    this.currentPath = this.router.url;
  }

  // eslint-disable-next-line class-methods-use-this
  goToHome() {
    if (window.location.href.includes('home#')) {
      window.location.href = window.location.href.replace(/(.*)\/(home)#(.*)/g, '$1/$2');
    }
  }

  submitDemoRequest(form: NgForm) {
    this.userr = this.user;
    this.user = {
      Country: '',
      PhoneNumber: '',
      Name: '',
      Email: '',
      CountryCode: '',
      Message: '',
    };
    this.userr.Message = this.userr.Message.replace(/\n/g, '<br />');
    this.auth.submitDemoRequest(this.userr).subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          this.catchLoginErrors(res);
          return;
        }
        this.toastr.success('Your request has been submitted successfully');
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error('There was an error while submitting your request, please try again');
        }
      },
    );
    form.resetForm();
  }


  onBuyPkg(pkg) {
    if (pkg.NumOfMonths === undefined) {
      this.selectedPackagePriceDetails = null;
      this.router.navigate(['register']);
      this.auth.buyPackage = this.selectedPackagePriceDetails;
      window.scrollTo(0, 0);
      return;
    }
    this.paymentService.getPriceDetails(pkg.Id, pkg.NumOfMonths).subscribe(
      (res) => {
        this.selectedPackagePriceDetails = res.Data.PriceDetails;
        this.selectedPackagePriceDetails.PricePerMonthUSD = pkg.PricePerMonthUSD;
        this.selectedPackagePriceDetails.PricePerMonthINR = pkg.PricePerMonthINR;
        this.router.navigate(['register']);
        this.auth.buyPackage = this.selectedPackagePriceDetails;
        window.scrollTo(0, 0);
      },
      (err) => {
        if (err.status !== 403) {
          this.toastr.error(
            this.translate.instant('LABELS.BILLING_SUMMARY.MESSAGES.GET_PRICE_ERROR'),
          );
        }
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  redirectToExternalURL(url: string) {
    window.open(url, '_blank');
  }

  catchLoginErrors(res) {
    this.toastr.error(res.Message);
  }

  getCountryList() {
    this.registerService.getCountryList().subscribe(
      (res) => {
        if (res.Status === 'Fail' || res.Status === 'Error') {
          this.catchLoginErrors(res);
          return;
        }
        this.countryList = res.Data.Countries;
      },
      () => {
        // handle error
      },
    );
  }

  getLanguageList() {
    this.registerService.getLanguageList().subscribe(
      (res) => {
        if (res.Status === 'Fail' || res.Status === 'Error') {
          this.catchLoginErrors(res);
          return;
        }
        this.languageList = res.Data.Languages;
      },
      () => {
        // handle error
      },
    );
  }

  ngOnDestroy() {
    const $tawkToAPI = this.customWindow.Tawk_API;
    if ($tawkToAPI) {
      try {
        $tawkToAPI.hideWidget();
      } catch {
        // TO-DO Investigate and fix this issue
      }
    }
  }

  onLangChange() {
    this.refreshContent = true;
    this.translate.setDefaultLang(this.selectedLang);
    this.translate.use(this.selectedLang).subscribe(() => {
      localStorage.setItem(constants.APP_LANGUAGE, this.selectedLang);
      setTimeout(() => {
        this.refreshContent = false;
      }, 1000);
    });
  }

  redirectToLogin() {
    this.router.navigate(['login']);
  }

  isValidPhoneNumber(): boolean {
    if (!this.user.PhoneNumber) {
      return true;
    }
    const pattern = /^[1-9][0-9]{8,9}$/;
    const isValid = pattern.test(this.user.PhoneNumber);
    return isValid;
  }

  isValidName(): boolean {
    if (!this.user.Name) {
      return true;
    }
    const pattern = /^[A-Za-z][A-Za-z\s]*$/;
    const isValid = pattern.test(this.user.Name);
    return isValid;
  }

  isValidEmail(): boolean {
    if (!this.user.Email) {
      return true;
    }
    const pattern = /^[A-Za-z0-9]+[A-Za-z0-9._%+-]*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValid = pattern.test(this.user.Email);
    return isValid;
  }
}
