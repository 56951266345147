<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span>{{ 'LABELS.Notifications.SEND_A_NOTIFICATION' | translate }}</span>
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [ngClass]="backgroundService.setBackgroundBoolean ? 'd-none' : 'd-block'">
  <div class="row">
    <div class="col-12">
      <form #sendNotificationForm="ngForm">
        <div class="input-group mb-3" *ngIf="clinicsList?.length >= 1">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-map-marker"></i></span>
          </div>
          <div class="field multi-select-container osm-form-group-pet-parent">
            <angular2-multiselect
              [data]="clinicsList"
              name="location"
              [(ngModel)]="notification.LocationId"
              #location="ngModel"
              [settings]="siteDropdownSettings"
              [ngModelOptions]="{ standalone: true }"
              (onClose)="onLocDropdownChange()"
              (onDeSelectAll)="clearIncidentSites()"
              (onSelect)="onSelectItems($event)"
              (onSelectAll)="onSelectAll(clinicsList)"
              required
            >
            </angular2-multiselect>
          </div>
          <div
            class="alert-danger"
            *ngIf="(location.untouched && location.dirty) || location.invalid"
          >
            <span>{{ 'COMMON.SITE_REQUIRED' | translate }}</span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-users"></i></span>
          </div>
          <div class="field">
            <select
              name="userGroup"
              class="form-control"
              required
              (change)="OnUserGroupChange($event.target.value)"
              [(ngModel)]="selectedUserGroup"
            >
              <option
                *ngFor="let userGroup of this.userGroupList"
                [value]="userGroup.Id"
                [disabled]="userGroup.Id === undefined"
              >
                {{ userGroup.Title }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-bell-o"></i></span>
          </div>
          <div class="field">
            <select
              name="notificationType"
              class="form-control"
              required
              [(ngModel)]="notification.PortalNotificationType"
              #notificationType="ngModel"
              (change)="onNotificationDropdownChange($event.target.value)"
            >
              <option
                *ngFor="let notificationType of this.notificationTypeList"
                [value]="notificationType.Id"
                [disabled]="
                  notificationType.Id === undefined ||
                  (notificationType.Id === 1 && selectedUserGroup === '3')
                "
              >
                {{ notificationType.Title }}
              </option>
            </select>
          </div>
          <div
            class="alert-danger"
            *ngIf="notificationType.invalid && (notificationType.dirty || notificationType.touched)"
          >
            <span *ngIf="notificationType.errors?.required">
              {{ 'LABELS.Notifications.FORM_ERRORS.NOTIFY_TYPE_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-user-o"></i></span>
          </div>
          <div class="field osm-form-group-pet-parent">
            <angular2-multiselect
              [ngModelOptions]="{ standalone: true }"
              [data]="appUserList"
              [(ngModel)]="notifiedUsers"
              [settings]="petParentDropdownSettings"
              required
              #notifiedUsersList="ngModel"
            >
            </angular2-multiselect>
          </div>
          <div
            class="alert-danger"
            *ngIf="
              notifiedUsers.length === 0 &&
              notifiedUsersList.invalid &&
              (notifiedUsersList.dirty || notifiedUsersList.touched)
            "
          >
            <span *ngIf="notifiedUsers.length === 0">
              {{ 'TOASTR.USERS.USERS_REQUIRED' | translate }}
            </span>
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-link"></i></span>
          </div>
          <div class="field">
            <input
              type="url"
              class="form-control"
              name="Link"
              [(ngModel)]="notification.Link"
              placeholder="Link (Optional)"
              #Link="ngModel"
              pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
            />
            <div class="alert-danger" *ngIf="Link.invalid && (Link.dirty || Link.touched)">
              <span *ngIf="Link.errors?.pattern">
                {{ 'LABELS.Notifications.FORM_ERRORS.LINK_INVALID_URL' | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="input-group mb-1" [ngClass]="{ 'mb-3': selectedNotificationType === '3' }">
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-comment-o"></i></span>
          </div>
          <div class="field">
            <textarea
              type="text"
              class="form-control"
              name="description"
              [(ngModel)]="notification.Description"
              placeholder="Type notification content"
              required
              #description="ngModel"
              [maxlength]="maxLengthForDescription"
              style="min-height: 35px"
            ></textarea>
          </div>
          <div
            class="alert-danger"
            *ngIf="description.invalid && (description.dirty || description.touched)"
          >
            <span *ngIf="description.errors?.required">
              {{ 'LABELS.Notifications.FORM_ERRORS.TEXT_REQUIRED' | translate }}
            </span>
            <span *ngIf="selectedNotificationType !== '3' && notification.Description.length > 140">
              {{ 'LABELS.Notifications.FORM_ERRORS.MAX_CHAR' | translate }}
            </span>
          </div>
        </div>
        <small
          class="d-block text-right max-char-message"
          *ngIf="selectedNotificationType !== '3'"
          [ngClass]="{ 'mb-3': selectedNotificationType === '4' }"
        >
          {{ 'LABELS.Notifications.MAX_CHAR_LENGTH' | translate }}
        </small>
        <div class="radio-section mb-3" *ngIf="selectedNotificationType !== '4'">
          <label class="mb-0">{{ 'LABELS.Notifications.ATTACHMENT' | translate }}</label>
          <div *ngFor="let FileType of this.FileTypes">
            <label [for]="FileType.Id" class="radio-label">
              <input
                [id]="FileType.Id"
                type="radio"
                [value]="FileType.Id"
                name="FileType"
                [(ngModel)]="selectedFiletype"
                #FileType="ngModel"
                style="margin-right: 5px !important"
              />
              {{ FileType.text }}
            </label>
          </div>
        </div>
        <div
          class="input-group mb-3"
          *ngIf="selectedNotificationType !== '4' && selectedFiletype !== 0 && importFile"
        >
          <div class="input-group-prepend icon">
            <span class="input-group-text full-image-with"><i class="fa fa-file-image-o"></i></span>
          </div>
          <div class="field">
            <input
              type="file"
              class="form-control"
              name="File"
              #File
              (click)="File.value = null"
              [accept]="
                notification.PortalNotificationType !== '3'
                  ? '.png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .heic, .HEIC'
                  : '.png, .PNG, .jpg, .JPG, .jpeg, .JPEG, .mp4, .MP4, .mov, .MOV, .wmv, .WMV, .avi, .AVI, .mkv, .MKV, .webm, .WEBM, .doc, .DOC, .pdf, .PDF, .txt, .TXT, .heic, .HEIC'
              "
              (change)="onFileSelected($event)"
            />
          </div>
          <div class="alert-danger" *ngIf="File.invalid && (File.dirty || File.touched)">
            <span *ngIf="File.errors?.required">
              {{ 'LABELS.Notifications.FORM_ERRORS.IMAGE_TYPE_REQUIRED' | translate }}
            </span>
          </div>
          <div class="alert-danger" *ngIf="isError">{{ ErrorMessage }}</div>
        </div>
      </form>
    </div>
  </div>
  <div class="row" style="justify-content: flex-end; margin-right: 4px">
    <div>
      <button class="btn btn-danger" type="button" (click)="goBack()">
        <i class="fa fa-close" aria-hidden="true"></i>
        {{ 'LABELS.SETUP.BUTTONS.CANCEL' | translate }}
      </button>
    </div>
    &nbsp;
    <div class="col-sm-12 m-b-xs content-right-align" style="display: contents">
      <button
        type="submit"
        class="btn btn-success"
        (click)="onConfirm()"
        [disabled]="
          apiCallInProgress ||
          sendNotificationForm.invalid ||
          notifiedUsers.length === 0 ||
          notification.LocationId === undefined ||
          notification.LocationId.length === 0 ||
          isError === true ||
          (notification.FileType === 2 && importFile.length === 0)
        "
      >
        {{ 'LABELS.SETUP.BUTTONS.SUBMIT' | translate }}
      </button>
    </div>
  </div>
</div>
