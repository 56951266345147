<div class="col-lg-12 mr-t-2">
  <div *ngIf="isPaymentComplete === false" class="col-md-5" style="margin: auto">
    <h4 style="text-align: left">Summary</h4>
    <table class="table table-borderless content-center-align">
      <tbody>
        <tr class="row">
          <td class="col-8 text-left pt-0 pb-1 pr-0 w-25 my-auto">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.CURRENCY' | translate }}
          </td>
          <td class="col-4 text-right pt-0 pb-1 pl-0 w-25">
            <select
              name="currency"
              class="form-control mb-1"
              [(ngModel)]="preferredCurrency"
              (change)="onCurrencyUpdated()"
              required
            >
              <option *ngFor="let currency of currencies" [value]="currency.value">
                {{ currency.title }}
              </option>
            </select>
          </td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">{{ priceDetails.PackageName }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            {{ priceDetails.PricePerMonth | number: '1.2-2' }}
          </td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.MONTHS' | translate }}
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25"></td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">{{ priceDetails.NoOfMonths }}</td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.SUB_TOTAL' | translate }}
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            {{ priceDetails.Price | number: '1.2-2' }}
          </td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.DISCOUNT' | translate }}
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            <span *ngIf="priceDetails.Discount > 0">-</span
            >{{ priceDetails.Discount | number: '1.2-2' }}
          </td>
        </tr>

        <tr *ngIf="isCouponApplied === true" class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.COUPON_DIS' | translate }} ({{
              couponDiscountPercent
            }}% upto {{ preferredCurrency }} {{ maxDiscount }})
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            -{{ couponDiscount | number: '1.2-2' }}
          </td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.VAT' | translate }}
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            {{ vatPercent }}
          </td>
        </tr>

        <tr class="row">
          <td class="col-6 text-left pt-0 pb-1 pr-0 w-25">
            {{ 'LABELS.BILLING_SUMMARY.TABLE_ITEMS.AMOUNT_PAY' | translate }}
          </td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 pr-0 w-25">{{ preferredCurrency }}</td>
          <td class="col-3 text-right pt-0 pb-1 pl-0 w-25">
            {{ priceDetails.Total | number: '1.2-2' }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="coupon-div">
      <input
        [disabled]="isCouponApplied === true"
        required
        [(ngModel)]="couponCode"
        name="couponCode"
        id="couponCode"
        type="text"
        class="form-control coupon-input"
        placeholder="Enter Coupon Code"
      />
      <button
        *ngIf="isCouponApplied === false"
        [disabled]="couponCode === '' || couponCode === undefined"
        type="button"
        class="col btn btn-primary coupon-apply-btn"
        (click)="validateCoupon()"
      >
        {{ 'LABELS.BILLING_SUMMARY.BUTTONS.APPLY' | translate }}
      </button>
      <button
        *ngIf="isCouponApplied === true"
        type="button"
        class="col btn btn-danger coupon-apply-btn"
        (click)="removeCoupon()"
      >
        {{ 'LABELS.BILLING_SUMMARY.BUTTONS.REMOVE' | translate }}
      </button>
    </div>
    <div class="content-center-align">
      <ngx-paypal [config]="paypalConfig"></ngx-paypal>
    </div>
  </div>
  <app-payment-success
    *ngIf="isPaymentComplete === true && isPaymentSuccess === true"
    [purchaseData]="purchaseData"
    [isLandingPage]="isLandingPage"
  ></app-payment-success>
  <app-payment-failure
    *ngIf="isPaymentComplete === true && isPaymentSuccess === false"
    [purchaseData]="purchaseData"
    [isLandingPage]="isLandingPage"
  ></app-payment-failure>
</div>
