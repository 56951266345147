<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <span style="color: #3f6097">{{
        'LABELS.ORGANISATIONS.TABLE_HEADERS.CHANGE_OWNER' | translate
      }}</span>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <select
          class="form-control"
          id="owner-select"
          [(ngModel)]="selectedOwner"
          (change)="onOwnerSelect($event.target.value)"
        >
          <option [value]="null" disabled>Select the portal users</option>
          <option *ngFor="let user of portalUsers" [value]="user.Id">
            {{ user.FirstName }} {{ user.LastName }}
          </option>
        </select>
        <small class="d-block mt-2 ml-1 mb-4">
          {{ 'LABELS.ORGANISATIONS.CHANGE_OWNER_NOTE' | translate }}
        </small>
        <div class="row" style="justify-content: flex-end; margin-right: 4px">
          <div>
            <button class="btn btn-danger" type="button" (click)="close()">
              <i class="fa fa-close" aria-hidden="true"></i>
              {{ 'LABELS.SETUP.BUTTONS.CANCEL' | translate }}
            </button>
          </div>
          &nbsp;
          <div class="col-sm-12 m-b-xs content-right-align" style="display: contents">
            <button type="submit" class="btn btn-success" (click)="submit()">
              {{ 'LABELS.SETUP.BUTTONS.SUBMIT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
