<div class="d-flex background m-auto appbody">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="col-md-12">
        <div class="card p-3">
          <div class="card-body">
            <form #confirmForm="ngForm">
              <fieldset>
                <div class="row form-group">
                  <div>
                    {{ 'TOASTR.CONFIRMATION.CONFIRMATION' | translate }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <button class="btn btn-success" type="submit" (click)="goBack()">
                      {{ 'LABELS.Confirmation.GO_BACK' | translate }}
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
