import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import OrganisationService from '../../organisations/services/organisation-http.service';
import Package from '../models/package.model';

@Injectable({
  providedIn: 'root',
})
export default class PaymentService {
  constructor(
    private env: EnvService,
    private httpClient: HttpClient,
    private orgService: OrganisationService,
  ) {
    // logic goes here
  }

  getPricingData(lang) {
    const API_URL = `${this.env.apiUrl}/Resources/package-features-${lang}`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { Resource: Array<{ Packages: Array<Package>, Coupons: [] }> } }) => data)
      .catch((error) => Observable.throw(error));
  }

  getPriceDetails(packageId, numMonths) {
    const API_URL = `${this.env.apiUrl}/Packages/${packageId}/PriceDetails?NoOfMonths=${numMonths}`;
    return this.httpClient
      .get(API_URL)
      .map((data: { Data: { PriceDetails: Object } }) => data)
      .catch((error) => Observable.throw(error));
  }

  validateCoupon(couponCode) {
    const API_URL = `${this.env.apiUrl}/Coupons`;
    return this.httpClient
      .post(API_URL, { CouponCode: couponCode })
      .map((data: { Data: { Coupon: { DiscountPercentage: number, MaxDiscountINR: number, MaxDiscountUSD: number, MaxDiscountGBP: number; } }, Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }

  purchaseLicense(reqData) {
    const orgId = OrganisationService.getCurrentGlobalOrganisation().Id;
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Invoices`;
    return this.httpClient
      .post(API_URL, reqData)
      .map((data: { Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }
}
