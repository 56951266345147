import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css'],
})
export default class DownloadComponent implements OnInit {
  installFrom = '';

  deviceType = 'ok';

  year: number = moment().get('year');

  constructor(
    private deviceDetector: DeviceDetectorService,
    private loc: Location,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.deviceType = this.deviceDetector.getDeviceInfo().device;
    if (this.deviceType === 'Android') {
      this.installFrom = 'Play Store';
    } else {
      this.installFrom = 'App Store';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  openApp() {
    const deepLink = 'incidentreporter365://';
    window.location.href = deepLink.toString();
  }

  openPlayStore() {
    this.openApp();
    setTimeout(() => {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.osmosys.incidentreporter&hl=en';
    }, 3000);
  }

  openAppStore() {
    this.openApp();
    setTimeout(() => {
      window.location.href = 'https://apps.apple.com/in/app/incident-reporter-365/id1513567560';
    }, 3000);
  }

  goBack() {
    this.loc.back();
  }
}
