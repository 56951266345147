import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SPINNER } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { AclService } from 'ng2-acl/dist';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import LoginService from './services/login-http.service';
import User from './models/user.model';
import constants from '../../common/constants';
import AuthService from '../../auth/auth.service';
import httpErrorCodes from '../../common/http_error_codes';
import OrganisationService from '../organisations/services/organisation-http.service';
import EnvService from '../../env.service';
import CommonModalDialogComponent from '../../components/modal-dialog/common-modal-dialog.component';

@Component({
  selector: 'app-login-component',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export default class LoginComponent implements OnInit {
  public user: User;

  public spinners = SPINNER;

  public validationInProgress = false;

  activeVisibleForm = 'login';

  @Output() buyPackage = new EventEmitter();

  private modalRef: BsModalRef;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private auth: AuthService,
    private aclService: AclService,
    private orgService: OrganisationService,
    public env: EnvService,
    private modalService: BsModalService,
  ) {
    // logic goes here
  }

  ngOnInit() {
    this.user = new User();
    if (localStorage.getItem(constants.PAYMENT_IN_PROGRESS_DETAILS)) {
      this.auth.activeForm = 'billingSummaryComponent';
      return;
    }
    if (localStorage.getItem(constants.BEARER_TOKEN)) {
      if (this.aclService.can('dashboard')) {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['incidents/list']);
      }
    }
  }

  userLogin() {
    this.validationInProgress = true;
    this.loginService.usreLogin(this.user).subscribe(
      async (res) => {
        if (res.Status === 'Fail') {
          if (res.ErrorCode === 'INVALID_CREDENTIALS') {
            this.toastr.error(this.translate.instant('TOASTR.LOGIN.INVALID_CREDENTIALS'));
            return;
          }
          if (res.ErrorCode === 'NO_PORTAL_ACCESS') {
            this.toastr.error(this.translate.instant('TOASTR.LOGIN.NO_PORTAL_ACCESS'));
            return;
          }
          if (res.Status) {
            this.toastr.error(this.translate.instant('TOASTR.LOGIN.UNCAUGHT_LOGIN_ERROR'));
          } else {
            this.toastr.error(this.translate.instant('TOASTR.TIMEOUT'));
          }
          return;
        }
        if (res.Data.Package.IsExpired) {
          this.showPackageExpiredModal();
          return;
        }
        localStorage.setItem(constants.BEARER_TOKEN, res.Data.Login.Token);
        localStorage.setItem(constants.LOGGED_IN_USER_ID, res.Data.Login.UserId);
        localStorage.setItem(constants.IS_ORGANISATION_OWNER, res.Data.Login.IsOwner);
        localStorage.setItem(
          constants.USER_INCIDENT_SITES,
          JSON.stringify(res.Data.Login.IncidentSites),
        );
        localStorage.setItem(
          constants.CURRENT_USER_DETAILS,
          JSON.stringify({
            DisplayName: res.Data.Login.DisplayName,
            Email: res.Data.Login.Email,
            OrganisationId: res.Data.Login.OrganisationId,
          }),
        );
        const packageName = res.Data.Package.PackageName.replace(/ /g, '_');
        localStorage.setItem(
          constants.CURRENT_SUBSCRIPTION,
          JSON.stringify({
            PackageId: res.Data.Package.Id,
            PackageName: packageName,
            IsExpired: res.Data.Package.IsExpired,
          }),
        );
        localStorage.setItem(
          constants.HIGHEST_SUBSCRIPTION,
          JSON.stringify({
            PackageId: res.Data.Package.HighestPackageId,
          }),
        );
        await this.getOrganisationList();
        if (!res.Data.Package.IsExpired) {
          await this.getDivisionByOrg();
        }
        await this.getIncidentSiteByOrg();
        if (this.aclService.getRoles().length > 0) {
          this.aclService.detachRole(this.aclService.getRoles()[0]);
        }
        this.aclService.attachRole(packageName);
        if (this.auth.requestedUrl) {
          this.router.navigateByUrl(this.auth.requestedUrl);
          this.auth.requestedUrl = '';
        } else if (this.aclService.can('dashboard')) {
          const org = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
          const div = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.DIVISIONS));
          const loc = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS));
          if (org !== null || div !== null || loc !== null) {
            this.router.navigate(['dashboard']);
          }
        } else {
          this.router.navigate(['incidents/list']);
        }
      },
      (err) => {
        this.validationInProgress = false;
        if (err.status === httpErrorCodes.API_CALL_LIMIT_EXCEEDED) {
          this.toastr.error(this.translate.instant('COMMON.API_CALL_LIMIT_EXCEEDED'));
        } else {
          // TODO : Change error message once they are standardised by the backend.
          const errorMessage =
            err.status && err.status !== 0
              ? this.translate.instant('TOASTR.LOGIN.UNCAUGHT_LOGIN_ERROR')
              : this.translate.instant('TOASTR.TIMEOUT');

          this.toastr.error(errorMessage);
        }
      },
    );
  }

  async getDivisionByOrg() {
    const res = await this.orgService.getDivisionByOrg().toPromise();
    const resp = res.Data.Divisions;
    localStorage.setItem(constants.STORAGE_KEYS.DIVISIONS, JSON.stringify(resp));
  }

  async getIncidentSiteByOrg() {
    const res = await this.orgService.getIncidentSiteByOrg().toPromise();
    const resp = res.Data.Locations;
    localStorage.setItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS, JSON.stringify(resp));
  }

  async getOrganisationList() {
    const res = await this.orgService.getOrganisationsList().toPromise();
    const resp = res.Data.Organisations;
    OrganisationService.setGlobalOrganisations(resp);
  }

  catchLoginErrors(res) {
    this.validationInProgress = false;
    // ToDo : Toast the error messages
    this.toastr.error(res.Message);
  }

  goToRegistration() {
    this.router.navigate(['register']);
  }

  startFreeTrial() {
    this.buyPackage.emit({});
  }

  showForgotPasswordForm() {
    this.activeVisibleForm = 'forgot-password';
  }

  showLoginForm() {
    this.activeVisibleForm = 'login';
  }

  forgotPassword() {
    const requestObj = {
      Email: this.user.Email,
    };
    this.loginService.forgotPassword(requestObj).subscribe(
      (res) => {
        if (res.Status === 'Fail') {
          if (res.ErrorCode === 'USER_NOT_EXISTS') {
            this.toastr.error(this.translate.instant('TOASTR.LOGIN.USER_NOT_EXISTS'));
          } else if (res.ErrorCode === 'NO_PORTAL_ACCESS') {
            this.toastr.error(this.translate.instant('TOASTR.LOGIN.NO_PORTAL_ACCESS'));
          }
          return;
        }
        this.toastr.success(this.translate.instant('TOASTR.LOGIN.RESET_PASSWORD_SUCCESS'));
        this.showLoginForm();
      },
      (err) => {
        if (err.status === httpErrorCodes.API_CALL_LIMIT_EXCEEDED) {
          this.toastr.error(this.translate.instant('COMMON.API_CALL_LIMIT_EXCEEDED'));
        } else {
          // TODO : Change error message once they are standardized by the backend.
          this.toastr.error(this.translate.instant('TOASTR.LOGIN.INVALID_CREDENTIALS'));
        }
      },
    );
  }

  catchErrors(err) {
    const key = Object.keys(err.Data)[0];
    this.toastr.error(err.Data[key]);
  }

  showPackageExpiredModal() {
    const initialSate = {
      icon: 'fa fa-warning strong-warning',
      title: this.translate.instant('LABELS.BILLING.PACKAGE_EXPIRED_MODAL_TITLE'),
      subText: this.translate.instant('LABELS.BILLING.PACKAGE_EXPIRED_MODAL_SUBTEXT'),
      yesText: this.translate.instant('COMMON.OK'),
    };

    this.modalRef = this.modalService.show(CommonModalDialogComponent, {
      initialState: initialSate,
    });
    this.modalRef.content.onClose.subscribe((result) => {
      if (result) {
        // handle result
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  togglePasswordVisibility(): void {
    const passwordInput = document.querySelector('input[name="userPassword"]');
    if (passwordInput) {
      if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
      } else {
        passwordInput.setAttribute('type', 'password');
      }
    }
  }
}
