import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import DefaultLayoutComponent from './containers/default-layout/default-layout.component';

import P404Component from './views/error/404.component';
import P500Component from './views/error/500.component';
import AuthGaurd from './auth/auth-gaurd.service';
import SuccessComponent from './views/success/success.component';
import P401Component from './views/error/401.component';
import HomeComponent from './views/home/home.component';
import ConfirmationComponent from './views/home/confirmation/confirmation.component';
import LoginPageComponent from './views/login-page/login-page.component';
import DownloadComponent from './views/download/download.component';
import RegisterComponent from './views/register/register.component';
import MaintenanceComponent from './views/maintenance/maintenance.component';
import ReleaseVersionsComponent from './views/release-versions/release-versions.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: '401',
    component: P401Component,
    data: {
      title: 'Page 401',
    },
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Home page',
    },
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    data: {
      title: 'Confirm page',
    },
  },
  {
    path: '#demo',
    component: HomeComponent,
    data: {
      title: 'Home page',
    },
  },
  {
    path: '#pricing',
    component: HomeComponent,
    data: {
      title: 'Home page',
    },
  },
  {
    path: '#benefits',
    component: HomeComponent,
    data: {
      title: 'Home page',
    },
  },
  {
    path: '#about',
    component: HomeComponent,
    data: {
      title: 'Home page',
    },
  },
  {
    path: 'login',
    component: LoginPageComponent,
    data: {
      title: 'LogIn',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register',
    },
  },
  {
    path: 'testimonials',
    component: LoginPageComponent,
    data: {
      title: 'testimonials',
    },
  },
  {
    path: 'success',
    component: SuccessComponent,
    data: {
      title: 'Success Page',
    },
  },
  {
    path: 'downloads',
    component: DownloadComponent,
    data: {
      title: 'downloads page',
    },
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    data: {
      title: 'maintainence page',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'organisations',
        loadChildren: () =>
          import('./views/organisations/organisations.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'sites',
        loadChildren: () => import('./views/sites/sites.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: '',
        loadChildren: () => import('./views/charts/charts.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'appointments',
        loadChildren: () =>
          import('./views/appointments/appointments.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'assets',
        loadChildren: () => import('./views/asset/asset.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'incidents',
        loadChildren: () => import('./views/incidents/incidents.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'heatmap',
        loadChildren: () => import('./views/heatmap/heatmap.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'setup',
        loadChildren: () => import('./views/setup/setup.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'courses',
        loadChildren: () => import('./views/courses/courses.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      {
        path: 'batches',
        loadChildren: () => import('./views/batches/batches.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      // {
      //   path: 'integrations',
      //   loadChildren: () => import('./views/integrations/integrations.module').then(m => m.IntegrationsModule),
      //   canActivate: [AuthGaurd]
      // },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then((m) => m.default),
        canActivate: [AuthGaurd],
      },
      // {
      //   path: 'billing',
      //   loadChildren: () => import('./views/billing/billing.module').then(m => m.BillingModule)
      // },
      {
        path: 'changepassword',
        loadChildren: () =>
          import('./views/change-password/change-password.module').then((m) => m.default),
      },
      {
        path: 'delete',
        loadChildren: () =>
          import('./views/delete-account/delete-account.module').then((m) => m.default),
      },
      {
        path: 'email-support',
        loadChildren: () =>
          import('./views/email-support/email-support.module').then((m) => m.default),
      },
    ],
  },
  {
    path: 'release-versions/:id',
    component: ReleaseVersionsComponent,
  },
  {
    path: 'release-versions',
    component: ReleaseVersionsComponent,
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
