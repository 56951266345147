/* eslint-disable no-param-reassign */
import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { tap, catchError, timeout } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import httpErrorCodes from '../common/http_error_codes';
import LoginService from '../views/login/services/login-http.service';
import constants from '../common/constants';
import { CustomWindow } from '../interfaces/global.interface';

@Injectable()
export default class HttpConfigInterceptor implements HttpInterceptor {
  stopThisRequest = false;

  customWindow: CustomWindow;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private loginService: LoginService,
    private deviceDetector: DeviceDetectorService,
  ) {
    this.customWindow = window as unknown as CustomWindow;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('Login')) this.stopThisRequest = false;

    if (this.stopThisRequest === true && !request.url.includes('Login')) {
      this.toastr.clear();
      this.ngxService.stopAll();
      this.stopThisRequest = false;
      this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'), '', {
        positionClass: 'toast-top-right',
      });
      setTimeout(() => {
        this.stopThisRequest = false;
      }, 3000);

      // return Observable.create(empty);
      return EMPTY;
    }

    const newUrl = { url: request.url.replace(/([^:]\/)\/+/g, '$1') };
    request = Object.assign(request, newUrl);

    const token: string = localStorage.getItem(constants.BEARER_TOKEN);

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', `BEARER ${token}`) });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
      tap(() => {
        // handle
      }),
      timeout(60000),
      catchError((error) => {
        this.ngxService.stop();
        if (error.status === httpErrorCodes.TOKEN_EXPIRED) {
          // ... (existing code for handling TOKEN_EXPIRED)
          this.stopThisRequest = true;
          this.ngxService.stopAll();
          this.router.navigate(['login']);
          this.toastr.clear();
          LoginService.clearLogin();
          setTimeout(() => {
            this.stopThisRequest = false;
          }, 5000);
          const $tawkToAPI = this.customWindow.Tawk_API;
          if ($tawkToAPI) {
            const currentUserDetails =
              JSON.parse(localStorage.getItem(constants.CURRENT_USER_DETAILS)) || {};
            $tawkToAPI.setAttributes({
              name: currentUserDetails.DisplayName || '',
              email: currentUserDetails.Email || '',
              LoginStatus: 'Not logged in',
            });
            $tawkToAPI.showWidget();
          }
          if (this.toastr.toasts.length === 0) {
            this.toastr.clear();
            this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'), '', {
              positionClass: 'toast-top-right',
            });
          } else {
            this.toastr.clear();
            this.toastr.warning(this.translate.instant('TOASTR.TOKEN_EXPIRED'));
          }

          return EMPTY;
        }

        if (error.status === httpErrorCodes.INTERNAL_SERVER_ERROR) {
          this.router.navigate(['maintenance']);
        } else if (error.name === 'TimeoutError') {
          this.toastr.error(this.translate.instant('TOASTR.TIMEOUT'));
          return EMPTY;
        }

        return throwError(error);
      }),
    );
  }
}
