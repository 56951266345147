import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AclService } from 'ng2-acl';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';
import constants from '../../../common/constants';
import { DownloadDataAPI, UserLogin } from '../../../interfaces/global.interface';

@Injectable({
  providedIn: 'root',
})
export default class LoginService {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private env: EnvService,
    private aclService: AclService,
  ) {
    // logic goes here
  }

  loggedIn = false;

  static clearLogin() {
    localStorage.removeItem(constants.BEARER_TOKEN);
    localStorage.removeItem(constants.LOGGED_IN_USER_ID);
    localStorage.removeItem(constants.CURRENT_SUBSCRIPTION);
    localStorage.removeItem(constants.CURRENT_USER_DETAILS);
    localStorage.removeItem(constants.REGISTERED_ORG_DETAILS);
    localStorage.removeItem(constants.IS_ORGANISATION_OWNER);
    localStorage.removeItem(constants.INCIDENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.HEATMAP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DASHBOARD_FILTER_PREFERENCES);
    localStorage.removeItem(constants.APPOINTMENT_FILTER_PREFERENCES);
    localStorage.removeItem(constants.USERS_FILTER_PREFERENCES);
    localStorage.removeItem(constants.ASSET_FILTER_PREFERENCES);
    localStorage.removeItem(constants.SETUP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DATA_TABLE_STATES.INCIDENTS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.USERS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.APPOINTMENTS);
    localStorage.removeItem(constants.INCIDENT_LIST_FOR_NAV);
    localStorage.removeItem(constants.USER_LIST_FOR_NAV);
    localStorage.removeItem(constants.INCIDENTS_API_CURRENT_FILTER);
    localStorage.removeItem(constants.USERS_API_CURRENT_FILTER);
    localStorage.removeItem(constants.FIELD_STAFF_MAP_FILTER_PREFERENCES);
    localStorage.removeItem(constants.USER_FILTER_PREFERENCES);
    localStorage.removeItem(constants.DATA_TABLE_STATES.ASSETS);
    localStorage.removeItem(constants.DATA_TABLE_STATES.STAFF);
    localStorage.removeItem(constants.USER_INCIDENT_SITES);
    localStorage.removeItem(constants.STORAGE_KEYS.ORGANISATIONS);
    localStorage.removeItem(constants.STORAGE_KEYS.INCIDENT_LOCATIONS);
    localStorage.removeItem(constants.STORAGE_KEYS.DIVISIONS);
    localStorage.removeItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION);
    localStorage.removeItem(constants.REPORT1_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT2_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT3_FILTER_PREFERENCES);
    localStorage.removeItem(constants.REPORT4_FILTER_PREFERENCES);
    localStorage.removeItem(constants.SELECTED_ASSET_TYPE);
  }

  static isUserLoggedIn() {
    if (localStorage.getItem(constants.CURRENT_USER_DETAILS)) {
      return true;
    }
    return false;
  }

  usreLogin(user) {
    const API_URL = `${this.env.apiUrl}/Login`;
    return this.httpClient
      .post(API_URL, user)
      .map(
        (data: UserLogin) =>
          // if()
          data,
      )
      .catch((error) => Observable.throw(error));
  }

  forgotPassword(user) {
    const API_URL = `${this.env.apiUrl}/ResetPassword`;
    return this.httpClient
      .put(API_URL, user)
      .map((data: {
        Status: string;
        ErrorCode: string;
      }) => data)
      .catch((error) => Observable.throw(error));
  }

  backToHome() {
    const isUserLoggedIn = LoginService.isUserLoggedIn();
    if (isUserLoggedIn) {
      if (this.aclService.can('dashboard')) {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['incidents/list']);
      }
    } else {
      this.router.navigate(['']);
    }
  }

  downloadData() {
    const userId = JSON.parse(localStorage.getItem(constants.LOGGED_IN_USER_ID));
    const API_URL = `${this.env.apiUrl}/Users/${userId}/ExportAll?Export=true`;
    return this.httpClient
      .get(API_URL)
      .map((data: DownloadDataAPI) => data)
      .catch((error) => Observable.throw(error));
  }
}
