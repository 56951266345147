<!-- eslint-disable max-len -->
<div>
  <div class="modal-header" style="width: 100%">
    <h4 class="modal-title pull-left">
      <span>{{ 'LABELS.Notifications.TABLE_HEADERS.CONFIRMATION_TITLE' | translate }}</span>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div>
        <table>
          <tr>
            <th class="label">{{ 'LABELS.Notifications.TABLE_HEADERS.SITE' | translate }}:</th>
            <td>
              <div
                [ngStyle]="{
                  'max-height': '70px',
                  'overflow-y': notificationData.selectedSite.length > 5 ? 'scroll' : 'auto'
                }"
              >
                <ng-container
                  *ngFor="let location of notificationData.selectedSite; let last = last"
                >
                  {{ location.itemName }}{{ last ? '' : ', ' }}
                </ng-container>
              </div>
            </td>
          </tr>
          <tr class="mt-1">
            <th class="label">
              {{ 'LABELS.Notifications.TABLE_HEADERS.NOTIFICATION_TYPE' | translate }}:
            </th>
            <td>{{ notificationData.NotificationType }}</td>
          </tr>
          <tr>
            <th class="label">{{ 'LABELS.Notifications.TABLE_HEADERS.USER_TYPE' | translate }}:</th>
            <td>{{ notificationData.userType }}</td>
          </tr>
          <tr>
            <th class="label">
              {{ 'LABELS.Notifications.TABLE_HEADERS.NOTIFIED_USERS' | translate }}:
            </th>
            <td>
              <div
                [ngStyle]="{
                  'max-height': '70px',
                  'overflow-y': notificationData.notifiedUsers.length > 15 ? 'scroll' : 'auto'
                }"
              >
                <ng-container *ngFor="let user of notificationData.notifiedUsers; let last = last">
                  {{ user.itemName }}{{ last ? '' : ', ' }}
                </ng-container>
              </div>
            </td>
          </tr>
          <tr *ngIf="notificationData.Link">
            <th class="label">{{ 'LABELS.Notifications.TABLE_HEADERS.LINK' | translate }}:</th>
            <td style="line-break: anywhere">
              <a href="{{ notificationData.Link }}" target="_blank">{{ notificationData.Link }}</a>
            </td>
          </tr>
          <tr>
            <th class="label">
              {{ 'LABELS.Notifications.TABLE_HEADERS.DESCRIPTION' | translate }}:
            </th>
            <td>
              <div
                [ngStyle]="{
                  'max-height': '70px',
                  'overflow-y': notificationData.Description.length > 140 ? 'scroll' : 'auto'
                }"
              >
                <ng-container *ngIf="notificationData.Description.length > 140; else description">{{
                  notificationData.Description
                }}</ng-container>
                <ng-template #description>
                  <span>{{ notificationData.Description }}</span>
                </ng-template>
              </div>
            </td>
          </tr>
          <tr *ngIf="notificationData.ImageUrl || notificationData.Attachments !== ''">
            <th class="label">
              {{ 'LABELS.Notifications.TABLE_HEADERS.ATTACHMENTS' | translate }}:
            </th>
            <td style="line-break: anywhere; padding-left: 0">
              {{ notificationData.ImageUrl || notificationData.Attachments }}
            </td>
          </tr>
        </table>
        <div class="btn">
          <button type="submit" class="btn btn-danger btn-lg btn-block" (click)="onCancel()">
            {{ 'LABELS.Notifications.BUTTONS.BUTTON_CANCEL' | translate }}
          </button>
          <button type="submit" class="btn btn-success btn-lg btn-block" (click)="confirm()">
            {{ 'LABELS.Notifications.BUTTONS.BUTTON_CONFIRM' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
