import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export default class BackgroundService {
  private setBackgroundBooleanValue = false;

  get setBackgroundBoolean(): boolean {
    return this.setBackgroundBooleanValue;
  }

  set setBackgroundBoolean(value: boolean) {
    this.setBackgroundBooleanValue = value;
  }
}
