import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-card',
  templateUrl: './attachment-card.component.html',
  styleUrls: ['./attachment-card.component.scss'],
})
export default class AttachmentCardComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() file: File | any;

  @Input() attachmentType = 'uploaded';

  maxFileNameLength = 20;

  fileSliceLimit = 7;

  fileType = 'file';

  ngOnInit(): void {
    if (this.attachmentType === 'existingIncidentAttachment') {
      // eslint-disable-next-line prefer-destructuring
      this.file.MediaUrl = this.file.MediaUrl.split('files\\')[1];
    }
    if (this.attachmentType !== 'uploaded' && this.file?.AttachmentTypeName === 'Audio')
      this.fileType = 'audio';
    if (this.attachmentType === 'uploaded') {
      const fileTypeName = this.file.type.split('/')[0];
      switch (fileTypeName) {
        case 'video':
          this.fileType = 'video';
          break;
        case 'audio':
          this.fileType = 'audio';
          break;

        default:
          this.fileType = 'file';
          break;
      }
    }
  }
}
