/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import constants from '../common/constants';
import EnvService from '../env.service';
import sideNavItems from '../_nav';
import OrganisationService from '../views/organisations/services/organisation-http.service';
import SetTitleService from '../containers/default-layout/services/set-title.service';

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  visibleNav;

  public requestedUrl = '';

  public activeForm = 'login';

  public buyPackage = null;

  constructor(
    private env: EnvService,
    private httpClient: HttpClient,
    private orgService: OrganisationService,
    private navigation: SetTitleService,
  ) {
    // logic goes here
  }

  static isSubscriptionExpired() {
    const currentSubscription = JSON.parse(localStorage.getItem(constants.CURRENT_SUBSCRIPTION));
    if (currentSubscription && currentSubscription.IsExpired === true) {
      return true;
    }
    return false;
  }

  static isAuthenticated(): boolean {
    // TODO: Local storage should be replaced with Angular local storage

    const token = localStorage.getItem(constants.BEARER_TOKEN);
    return !!token;
  }

  // public isValidToRedirectReports(): boolean {
  //   const userData = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION));
  //   return userData.Id === 3 ? true : false;
  // }

  // eslint-disable-next-line consistent-return
  public setVisibleNavItem() {
    let notAccessibleModules = [];
    let organizationModules;
    const currentOrgId = JSON.parse(
      localStorage.getItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION),
    ).Id;
    const allModules = JSON.parse(JSON.stringify(sideNavItems));
    this.visibleNav = JSON.parse(localStorage.getItem('ALL_NAVIGATION'));

    this.navigation.getDefaultNavigation().subscribe(
      (res) => {
        if (res.Status === 'Success') {
          const defaultModules = res.Data;
          defaultModules.forEach((module) => {
            const moduleToUpdate = this.visibleNav.find(
              (navItem) => Number(navItem.Id) === module.Id,
            );

            if (moduleToUpdate) {
              moduleToUpdate.name = module.ModuleName;
              moduleToUpdate.IsVisible = module.IsVisible;
              moduleToUpdate.IsMandatory = module.IsMandatory;
            } else {
              // INFO: This is for any new module not in the UI
              this.visibleNav.push({
                id: module.Id,
                name: module.ModuleName,
                // url: '/dashboard',
                icon: 'fa fa-tachometer',
                IsMandatory: module.IsMandatory,
                IsVisible: module.IsVisible,
              });
            }
          });

          if (!this.visibleNav) {
            this.orgService.getOrganizationModules(currentOrgId).subscribe(
              (orgRes) => {
                organizationModules = orgRes.Data;
                notAccessibleModules = this.addVisibleModules(allModules, organizationModules);

                return notAccessibleModules;
              },
              () =>
                // this.catchErrors(err);
                notAccessibleModules,
            );
          } else {
            return notAccessibleModules;
          }
        }

        return null;
      },
      () => {
        // this.catchErrors(err);
      },
    );

    if (!this.visibleNav) {
      this.orgService.getOrganizationModules(currentOrgId).subscribe(
        (res) => {
          organizationModules = res.Data;
          notAccessibleModules = this.addVisibleModules(allModules, organizationModules);

          return notAccessibleModules;
        },
        () =>
          // this.catchErrors(err);
          notAccessibleModules,
      );
    } else {
      return notAccessibleModules;
    }
  }

  addVisibleModules(allModules, organizationModules) {
    const modules = allModules;
    const allNavItems = [];
    const notAccessibleModules = [];

    modules.forEach((navItem, index) => {
      const itemFound = organizationModules?.find((module) => module.Id === navItem.id);
      modules[index].name = itemFound ? itemFound.ModuleName : navItem.name;
      modules[index].IsVisible = itemFound ? itemFound.IsVisible : navItem.IsVisible;
      if (navItem.IsVisible || itemFound === undefined) {
        allNavItems.push(navItem);
      }
    });

    if (!allNavItems[allNavItems.length - 1].IsVisible) {
      allNavItems.splice(-1);
    }

    this.visibleNav = allNavItems;

    modules.forEach((module) => {
      if (!this.visibleNav?.find((navItem) => navItem.id === module.Id)) {
        notAccessibleModules.push(module.url?.split('/')[1]);
      }
    });

    return notAccessibleModules;
  }

  // addVisibleModules(allModules) {
  //   let allNavItems = [];

  //   let organizationModules = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.CURRENT_GLOBAL_ORGANISATION))['Modules'];
  //   allModules.forEach((navItem, index) => {
  //     let itemFound = organizationModules.find(module => module.Id == navItem.id);
  //     navItem.name = itemFound?.ModuleName;
  //     navItem.isVisible = itemFound?.IsVisible;
  //     if (navItem.isVisible || navItem.divider) {
  //       allNavItems.push(navItem);
  //     }
  //   });

  //   if (!allNavItems[allNavItems.length - 1].isVisible) {
  //     allNavItems.splice(-1);
  //   }

  //   this.visibleNav = allNavItems;
  // }

  public submitDemoRequest(requestObj) {
    const API_URL = `${this.env.apiUrl}/Demo`;
    return this.httpClient
      .post(API_URL, requestObj)
      .map((data: { Status: string }) => data)
      .catch((error) => Observable.throw(error));
  }
}
