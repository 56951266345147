import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import EnvService from '../../../env.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import constants from '../../../common/constants';
import { AutoCompleteLoc } from '../../organisations/models/organisation.model';
import { Country } from '../../../interfaces/global.interface';
import Register from '../models/register.model';

@Injectable({
  providedIn: 'root',
})
export default class RegisterService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  userRegister(register) {
    const API_URL = `${this.env.apiUrl}/Register`;
    return this.httpClient
      .post(API_URL, register)
      .map((data: {
        Status: string;
        Data: {
          Organisation: Register
        };
      }) => data)
      .catch((error) => Observable.throw(error));
  }

  getTermsConditions(lang) {
    const API_URL = `${this.env.apiUrl}/Resources/terms-and-conditions-${lang}`;
    return this.httpClient
      .get(API_URL)
      .map((data: {
        Data: {
          Resource: {
            TermsAndConditions: string;
          }
        }
      }) => data.Data.Resource.TermsAndConditions)
      .catch((error) => Observable.throw(error));
  }

  uploadOrganisationIcon(formData, id, token) {
    const httpHeaders = new HttpHeaders().append('Authorization', `BEARER ${token}`);
    const options = {
      headers: httpHeaders,
    };
    const API_URL = `${this.env.apiUrl}/Organisations/${id}/UpdatePicture`;
    return this.httpClient
      .post(API_URL, formData, options)
      .map((data: {
        Data?: {
          Status?: string;
        };
      }) => data.Data)
      .catch((error) => Observable.throw(error));
  }

  getCountryList() {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient
      .get(API_URL)
      .map((data: Country) => data)
      .catch((error) => Observable.throw(error));
  }

  getLatLngByLocation(location) {
    const Url = `${constants.GEOCODING_URL + location}&key=${constants.MAPS_API_KEY}`;
    return this.httpClient
      .get(Url)
      .map((data: AutoCompleteLoc) => data)
      .catch((error) => Observable.throw(error));
  }

  getLanguageList() {
    const API_URL = `${this.env.apiUrl}/Languages`;
    return this.httpClient
      .get(API_URL)
      .map((data: {
        Status: string;
        Data: {
          Languages: []
        }
      }) => data)
      .catch((error) => Observable.throw(error));
  }

  getIndustryType() {
    const API_URL = `${this.env.apiUrl}/IndustryTypes`;
    return this.httpClient
      .get(API_URL)
      .map((data: {
        Status: string;
        Data: {
          industryTypes: {
            Id: number;
            IndustryType: string;
          }[];
        };
      }) => data)
      .catch((error) => Observable.throw(error));
  }

  getTimezone() {
    const API_URL = `${this.env.apiUrl}/Dashboard/Timezones`;
    return this.httpClient
      .get(API_URL)
      .map((data) => data)
      .catch((error) => Observable.throw(error));
  }
}
