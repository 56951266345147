<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <img src="assets/img/404.svg" alt="404" style="max-width: 300px" class="mb-3" />
        <h4 class="pt-3">{{ 'ERROR_PAGES.404_TITLE' | translate }}</h4>
        <p class="text-muted">{{ 'ERROR_PAGES.404_SUB_TITLE' | translate }}</p>
        <button class="btn btn-primary" (click)="OnClick()">
          {{ 'ERROR_PAGES.BACK_TO_HOME' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
