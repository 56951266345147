import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, LOCATION_INITIALIZED } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChartsModule } from 'ng2-charts';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DataTablesModule } from 'angular-datatables';
import { AclService } from 'ng2-acl';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { MatTableModule } from '@angular/material/table';
import OrganisationService from './views/organisations/services/organisation-http.service';

import AppComponent from './app.component';

import { HeicToJpegDirective } from './heic-to-jpeg.directive';

// Import containers
import DefaultLayoutComponent from './containers/default-layout/default-layout.component';

import P404Component from './views/error/404.component';
import P500Component from './views/error/500.component';
import LoginComponent from './views/login/login.component';
import RegisterComponent from './views/register/register.component';
import SuccessComponent from './views/success/success.component';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components

import HeaderComponent from './containers/header/header.component';

import AclRedirection from './app.redirection';
import AclDashboardResolver from './views/dashboard/dashboard.resolve';
import AclBillingResolver from './views/billing/billing.resolve';
import AclHeatMapResolver from './views/heatmap/incidents.heatmap.resolve';

// RECOMMENDED
import SendNotificationsComponent from './views/notifications/modals/send-notifications/send-notifications.component';
import AppointmentsConfigurationComponent from './views/appointments/modals/appointments-configuration/appointments-configuration.component';
import CreateAppointmentComponent from './views/appointments/modals/create-appointment/create-appointment.component';
import { EnvServiceProvider } from './env.service.provider';
import HttpConfigInterceptor from './interceptor/http-config.interceptor';
import AuthGuardService from './auth/auth-gaurd.service';
import TermsAndCondtionsComponent from './views/register/modals/terms-and-condtions/terms-and-condtions.component';
import P401Component from './views/error/401.component';
import CommonModalDialogComponent from './components/modal-dialog/common-modal-dialog.component';
import HomeComponent from './views/home/home.component';
import SharedModule from './views/shared-module/shared-module.module';
import CreateEditIntegrationComponent from './views/integrations/modals/create-edit-integration/create-edit-integration.component';
import constants from './common/constants';
import ConfirmationComponent from './views/home/confirmation/confirmation.component';
import FooterComponent from './components/footer/footer.component';
import LoginPageComponent from './views/login-page/login-page.component';
import DownloadComponent from './views/download/download.component';
import ReleaseVersionsComponent from './views/release-versions/release-versions.component';
import ReportsComponent from './views/reports/reports.component';
import ChangeOwnerComponent from './change-owner/change-owner.component';
import SharedService from './services/shared-service/shared.service';
import ConfirmNotificationComponent from './views/notifications/confirm-notification/confirm-notification.component';
import CustomTranslateLoader from './custom-translate-loader';
import HelpModalDialogComponent from './components/help-modal-dialog/help-modal-dialog.component';

const APP_CONTAINERS = [DefaultLayoutComponent];

// export  function  HttpLoaderFactory(http:  HttpClient) {
//   return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Promise<unknown>((resolve: any): void => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const userLang = localStorage.getItem(constants.APP_LANGUAGE);
        const langToSet = userLang || 'eng';
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(
          () => {
            // handle subscription
          },
          () => {
            // handle error
          },
          () => {
            resolve(null);
          },
        );
      });
    });
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMultiSelectModule,
    AppRoutingModule,
    DataTablesModule,
    MatTableModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgxUiLoaderModule,
    AutocompleteLibModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      excludeRegexp: [
        'Organisations$',
        'Divisions$',
        'Users\\?OrgId',
        'IncidentCountReport',
        'IncidentLocations$',
        'OrganisationStats$',
        'EscalatedIncidents$',
      ],
      showForeground: true,
    }),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ChartsModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    NgbModule,
    LoadingBarHttpClientModule,
    MatTooltipModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    SendNotificationsComponent,
    SuccessComponent,
    TermsAndCondtionsComponent,
    P401Component,
    CommonModalDialogComponent,
    HomeComponent,
    AppointmentsConfigurationComponent,
    CreateAppointmentComponent,
    CreateEditIntegrationComponent,
    ConfirmationComponent,
    FooterComponent,
    LoginPageComponent,
    DownloadComponent,
    ReleaseVersionsComponent,
    ReportsComponent,
    ChangeOwnerComponent,
    ConfirmNotificationComponent,
    HelpModalDialogComponent,
    HeicToJpegDirective
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: AuthGuardService,
      useClass: AuthGuardService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    EnvServiceProvider,
    BsModalRef,
    AclService,
    AclRedirection,
    AclDashboardResolver,
    AclBillingResolver,
    AclHeatMapResolver,
    OrganisationService,
    SharedService,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule, ConfirmNotificationComponent],
})
export class AppModule { }
