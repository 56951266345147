import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-common-modal-dialog',
  templateUrl: './common-modal-dialog.component.html',
  styleUrls: ['./common-modal-dialog.component.scss'],
})
export default class CommonModalDialogComponent implements OnInit {
  constructor(private bsModalRef: BsModalRef) {
    // logic goes here
  }

  public onClose: Subject<boolean>;

  icon: string;

  title: string;

  subText: string;

  yesText: string;

  noText: string;

  subject: string;

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
