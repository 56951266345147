/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import AclRedirection from '../../app.redirection';
import constants from '../../common/constants';

@Injectable()
export default class AclBillingResolver implements Resolve<any> {
  constructor(private aclRedirection: AclRedirection) {
    // logic goes here
  }

  resolve(): Observable<any> {
    const isOrgOwner = localStorage.getItem(constants.IS_ORGANISATION_OWNER);
    if (isOrgOwner === 'true') {
      // Has proper permissions
      return of(true);
    }
    // Does not have permission
    this.aclRedirection.redirectTo('Unauthorized');
    return of(null);
  }
}
