import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import AuthService from './auth.service';

@Injectable()
export default class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
  ) {
    // logic goes here
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (AuthService.isAuthenticated() === false) {
      this.router.navigate(['home']);
      this.auth.requestedUrl = state.url;
      return false;
    }
    if (AuthService.isSubscriptionExpired() === true) {
      this.router.navigate(['billing/list']);
      return false;
    }

    const invalidModules = this.auth.setVisibleNavItem();

    if (invalidModules?.includes(state.url.split('/')[1])) {
      this.router.navigate(['404']);
    }

    // INFO: Old implementation for reports
    // if (this.auth.isValidToRedirectReports() === false && (state.url === '/reports' || state.url === '/reports/monthly_resolution' || state.url === '/reports/average_resolution' || state.url === '/reports/overall_resolution')) {
    //   this.router.navigate(['404']);
    // }
    return true;
  }
}
