<div class="modal-body">
  <button
    type="button"
    class="btn-close close pull-right"
    aria-label="Close"
    (click)="bsModalRef?.hide()"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
  <div class="row">
    <div class="col-12">
      <div class="dialog" style="text-align: center">
        <h3>{{ heading }}</h3>
      </div>
      <div class="col-md-12" *ngIf="description">
        <p>{{ description }}</p>
      </div>
      <div class="col-md-12">
        <h4 class="mt-2">Notes:</h4>
        <ol class="note-list" *ngIf="points">
          <li class="field-note checklist" *ngFor="let point of points">
            <span>{{ point }}</span>
          </li>
        </ol>
        <ol class="note-list" *ngIf="content">
          <li class="field-note checklist mt-2" *ngFor="let data of content">
            <h5>{{ data.subHead }}</h5>
            <ol style="list-style-type: lower-alpha">
              <li *ngFor="let point of data.points">{{ point }}</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <div class="row" style="justify-content: flex-end">
    <div class="col-sm-2 m-b-xs content-right-align">
      <button type="submit" class="btn btn-danger" (click)="onCancel()">
        <i class="fa fa-close" aria-hidden="true"></i>
        Close
      </button>
    </div>
  </div>
</div>
