import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export default class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
    // logic goes here
  }

  getTranslation(lang: string): Observable<unknown> {
    const params = new HttpParams().set('v', new Date().getTime().toString());
    const url = `/assets/i18n/${lang}.json`;
    return this.http.get(url, { params });
  }
}
