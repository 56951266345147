const sideNavItems = [
  {
    id: '1',
    name: 'DASHBOARD_MENU.DASHBOARD',
    url: '/dashboard',
    icon: 'fa fa-tachometer',
    IsMandatory: 1,
    IsVisible: 1,
  },
  {
    id: '12',
    name: 'DASHBOARD_MENU.INCIDENT',
    url: '/incidents',
    icon: 'fa fa-exclamation-triangle',
    IsMandatory: 1,
    IsVisible: 1,
  },
  {
    id: '10',
    name: 'DASHBOARD_MENU.HEATMAP',
    url: '/heatmap',
    icon: 'fa fa-map-o',
    IsMandatory: 0,
    IsVisible: 0,
  },
  {
    id: '5',
    name: 'DASHBOARD_MENU.REPORTS',
    url: '/reports',
    icon: 'fa fa-area-chart',
    IsMandatory: 0,
    IsVisible: 0,
  },

  {
    divider: true,
  },
  {
    id: '2',
    name: 'DASHBOARD_MENU.ORGANISATION',
    url: '/organisations',
    icon: 'cui-globe',
    IsMandatory: 1,
    IsVisible: 1,
  },
  // {
  //   name: 'DASHBOARD_MENU.APP_USERS',
  //   url: '/users/list',
  //   icon: 'fa fa-tablet'
  // },
  // {
  //   name: 'DASHBOARD_MENU.SITES',
  //   url: '/sites/list',
  //   icon: 'fa fa-globe'
  // },
  {
    id: '4',
    name: 'DASHBOARD_MENU.SITES',
    url: '/sites/',
    icon: 'fa fa-globe',
    IsMandatory: 0,
    IsVisible: 0,
  },
  // {
  //   name: 'DASHBOARD_MENU.APP_USERS',
  //   url: '/users/list',
  //   icon: 'fa fa-tablet'
  // },
  {
    id: '6',
    name: 'Users',
    url: '/users',
    icon: 'cui-people',
    IsMandatory: 1,
    IsVisible: 1,
  },
  {
    id: '3',
    name: 'Assets',
    url: '/assets',
    icon: 'fa fa-laptop',
    IsMandatory: 0,
    IsVisible: 0,
  },
  {
    id: '13',
    name: 'DASHBOARD_MENU.TRAINING',
    icon: 'fa fa-graduation-cap',
    IsMandatory: 0,
    IsVisible: 0,
    url: 'blank', // this url parameter can be anything, but it's mandatory to have a value to keep the option collapsed by default
    children: [
      {
        name: 'Courses',
        url: '/courses',
        icon: 'fa fa-book',
      },
      {
        name: 'Batches',
        url: '/batches',
        icon: 'fa fa-group',
      },
    ],
  },
  {
    id: '7',
    name: 'DASHBOARD_MENU.SETUP',
    url: '/setup',
    icon: 'cui-wrench',
    IsMandatory: 1,
    IsVisible: 1,
  },
  {
    divider: true,
  },
  {
    id: '11',
    name: 'DASHBOARD_MENU.APPOINTMENT',
    url: '/appointments',
    icon: 'fa fa-calendar',
    IsMandatory: 0,
    IsVisible: 0,
  },
  {
    id: '8',
    name: 'DASHBOARD_MENU.NOTIFICATIONS',
    url: '/notifications',
    icon: 'fa fa-bell-o',
    IsMandatory: 0,
    IsVisible: 0,
  },
  // {
  //   name: 'DASHBOARD_MENU.INTEGRATIONS',
  //   url: '/integrations',
  //   icon: 'fa fa-external-link'
  // },
  // {
  //   name: 'DASHBOARD_MENU.BILLING',
  //   url: '/billing',
  //   icon: 'fa fa-usd'
  // },
  {
    id: '9',
    name: 'DASHBOARD_MENU.SUPPORT',
    url: '/email-support',
    icon: 'fa fa-envelope-o',
    IsMandatory: 0,
    IsVisible: 0,
  },
];

export default sideNavItems;
