<div class="download-page-wrapper">
  <img src="assets/img/brand/incident_reporter_logo.png" alt="incident-reporter-365" class="logo" />
  <div class="content">
    <h1>Download our App</h1>
    <p>Experience hassle-free incident reporting with our mobile app!</p>
    <div>
      <a (click)="openPlayStore()"
        ><img
          src="assets/img/googleplay.png"
          alt="Download IR365 from playstore"
          class="download-option"
      /></a>
      <a (click)="openAppStore()"
        ><img
          src="assets/img/appstore.png"
          alt="Download IR365 from app store"
          class="download-option"
      /></a>
    </div>
    <footer>
      <small
        >Copyright &copy; {{ year }}
        <a href="https://osmosys.co/" target="_blank" rel="noopener noreferrer"
          >Osmosys Software Solutions Pvt. Ltd</a
        >
        ALl rights reserved</small
      >
    </footer>
  </div>
</div>
