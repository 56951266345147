<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ 'LABELS.TERMS_CONDITIONS.PAGE_TITLE' | translate }}
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="closeTermsAndConditions()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div [innerHTML]="termsAndConditions"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 m-b-xs content-center-align">
      <button class="btn btn-secondary" (click)="closeTermsAndConditions()">
        {{ 'LABELS.TERMS_CONDITIONS.BUTTONS.CLOSE' | translate }}
      </button>
    </div>
  </div>
</div>
