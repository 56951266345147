<!-- eslint-disable max-len -->
<div class="registration-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="col-md-12 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <form #registerForm="ngForm">
              <fieldset>
                <legend class="form-legend">
                  {{ 'LABELS.REGISTER.PAGE_TITLE' | translate }}
                </legend>
                <div class="row form-group">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_NAME' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-md-6">
                    <input
                      class="form-control"
                      type="text"
                      required
                      [(ngModel)]="register.OrganisationName"
                      name="userOrganisation"
                      placeholder="{{
                        'LABELS.REGISTER.PLACE_HOLDERS.ORGANISATION_NAME' | translate
                      }}"
                      #userOrganisation="ngModel"
                      required
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="
                        userOrganisation.invalid &&
                        userOrganisation.dirty &&
                        userOrganisation.touched
                      "
                    >
                      <span *ngIf="userOrganisation.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.ORGANISATION_NAME_REQUIRED'
                          | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-3 align-center">
                    <label for="organisation-type">{{
                      'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TYPE' | translate
                    }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-5" style="margin-top: 0px">
                    <label class="custom-tooltip">
                      <input
                        type="radio"
                        name="is_close_organisation"
                        value="false"
                        id="is-close-organisation-open"
                        style="margin: 10px"
                        [(ngModel)]="register.IsClosedOrganisation"
                        #IsClosedOrganisation="ngModel"
                        [value]="false"
                      />{{ 'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TYPE_OPEN' | translate }}
                      <span class="custom-tooltip-text mobile-hidden">{{
                        'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TOOLTIP_OPEN' | translate
                      }}</span>
                    </label>
                    <label class="custom-tooltip">
                      <input
                        type="radio"
                        name="is_close_organisation"
                        value="true"
                        id="is-close-organisation-private"
                        style="margin: 10px"
                        [(ngModel)]="register.IsClosedOrganisation"
                        #IsClosedOrganisation="ngModel"
                        [value]="true"
                      />{{ 'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TYPE_PRIVATE' | translate }}
                      <span class="custom-tooltip-text mobile-hidden">{{
                        'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TOOLTIP_PRIVATE' | translate
                      }}</span>
                    </label>
                    <div class="flex-break"></div>
                  </div>
                </div>
                <div class="row form-group">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_ICON' | translate }}</label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="file"
                      name="photo"
                      (change)="onFileSelected($event)"
                      class="form-control"
                      id="photo"
                    />
                  </div>
                  <div class="col-md-3">
                    <label style="font-size: 12px"
                      >{{ 'LABELS.REGISTER.INPUT_LABEL.ICON_RESOLUTION' | translate }}<br />{{
                        'LABELS.REGISTER.INPUT_LABEL.ICON_FILE_SIZE' | translate
                      }}</label
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{
                      'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_ADDRESS' | translate
                    }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      required
                      [(ngModel)]="register.Address"
                      id="pac-input"
                      class="form-control"
                      maxlength="100"
                      name="orgAddress"
                      #orgAddress="ngModel"
                      placeholder="{{
                        'LABELS.REGISTER.PLACE_HOLDERS.ORGANISATION_ADDRESS' | translate
                      }}"
                      required
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="orgAddress.invalid && orgAddress.dirty && orgAddress.touched"
                    >
                      <span *ngIf="orgAddress.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.ORGANISATION_ADDRESS_REQUIRED'
                          | translate
                      }}</span>
                      <span *ngIf="orgAddress.errors?.maxlength">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.ORGANISATION_MAX_LENGTH'
                          | translate
                      }}</span>
                    </div>
                    <div class="alert-danger" *ngIf="orgAddress.valid && showGeoCodeNotAvailable">
                      <span>{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.ORGANISATION_ADDRESS_NOT_FOUND'
                          | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.INDUSTRY' | translate }}</label>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="form-control"
                      [(ngModel)]="register.IndustryTypeId"
                      name="Industry"
                    >
                      <option value="null" disabled selected>
                        {{ 'LABELS.REGISTER.PLACE_HOLDERS.INDUSTRY' | translate }}
                      </option>
                      <option *ngFor="let industry of IndustryTypes" [value]="industry.Id">
                        {{ industry.IndustryType }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.FIRST_NAME' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      required
                      [(ngModel)]="register.FirstName"
                      class="form-control"
                      maxlength="100"
                      name="userFirstName"
                      placeholder="{{ 'LABELS.REGISTER.PLACE_HOLDERS.FIRST_NAME' | translate }}"
                      #userFirstName="ngModel"
                      required
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="userFirstName.invalid && userFirstName.dirty && userFirstName.touched"
                    >
                      <span *ngIf="userFirstName.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.FIRST_NAME__REQUIRED' | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.LAST_NAME' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      name="userLastName"
                      placeholder="{{ 'LABELS.REGISTER.PLACE_HOLDERS.LAST_NAME' | translate }}"
                      required
                      [(ngModel)]="register.LastName"
                      class="form-control"
                      #userLastName="ngModel"
                      required
                      maxlength="100"
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="userLastName.invalid && userLastName.dirty && userLastName.touched"
                    >
                      <span *ngIf="userLastName.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.LAST_NAME_REQUIRED' | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.EMAIL' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      name="userEmail"
                      placeholder="{{ 'LABELS.REGISTER.PLACE_HOLDERS.EMAIL' | translate }}"
                      required
                      [(ngModel)]="register.Email"
                      class="form-control"
                      #userEmail="ngModel"
                      required
                      pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                      maxlength="100"
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="userEmail.invalid && userEmail.dirty && userEmail.touched"
                    >
                      <span *ngIf="userEmail.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.EMAIL_REQUIRED' | translate
                      }}</span>
                      <span *ngIf="userEmail.errors?.pattern">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.INVALID_REQUIRED' | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{
                      'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TIMEZONE' | translate
                    }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <select
                      class="form-control"
                      #userTimezone="ngModel"
                      [(ngModel)]="register.TimezoneId"
                      name="timezone"
                      required
                    >
                      <option value="null" disabled selected>
                        {{ 'LABELS.REGISTER.INPUT_LABEL.SELECT_TIMEZONE' | translate }}
                      </option>
                      <option [value]="dimension.Id" *ngFor="let dimension of orgTimezone">
                        {{ dimension.TimezoneCode }} {{ dimension.TimezoneRegion }}
                      </option>
                    </select>
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="userTimezone.invalid && userTimezone.dirty && userTimezone.touched"
                    >
                      <span *ngIf="userTimezone.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.ORGANISATION_TIMEZONE_REQUIRED'
                          | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.COUNTRY_CODE' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <select
                      name="userCountryCode"
                      class="form-control"
                      id="country-code"
                      #userCountryCode="ngModel"
                      required
                      [(ngModel)]="register.CountryCode"
                    >
                      <option value="">
                        {{ 'LABELS.REGISTER.INPUT_LABEL.SELECT_COUNTRY_CODE' | translate }}
                      </option>
                      <option
                        *ngFor="let country of supportedCountryList"
                        value="{{ country.PhoneCode }}"
                      >
                        {{ country.CountryName }} ({{ country.PhoneCode }})
                      </option>
                    </select>
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="
                        userCountryCode.invalid && userCountryCode.dirty && userCountryCode.touched
                      "
                    >
                      <span *ngIf="userCountryCode.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.COUNTRY_CODE_REQUIRED' | translate
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3 align-center">
                    <label>{{ 'LABELS.REGISTER.INPUT_LABEL.MOBILE_NUMBER' | translate }}</label>
                    <span class="text-danger">*</span>
                  </div>
                  <div class="col-sm-6">
                    <input
                      type="text"
                      name="userMobileNumber"
                      placeholder="{{ 'LABELS.REGISTER.PLACE_HOLDERS.MOBILE_NUMBER' | translate }}"
                      class="form-control"
                      [(ngModel)]="register.MobileNumber"
                      #userMobileNumber="ngModel"
                      required
                      pattern="^(?!0+$)(?!0)[0-9]{7,}$"
                      minlength="7"
                      maxlength="15"
                    />
                    <div class="flex-break"></div>
                    <div
                      class="alert-danger"
                      *ngIf="
                        userMobileNumber.invalid &&
                        userMobileNumber.dirty &&
                        userMobileNumber.touched
                      "
                    >
                      <span *ngIf="userMobileNumber.errors?.required">{{
                        'LABELS.REGISTER.INPUT_LABEL.FORM_ERRORS.MOBILE_NUMBER_REQUIRED' | translate
                      }}</span>
                      <span
                        *ngIf="
                          userMobileNumber.errors?.pattern ||
                          userMobileNumber.value.charAt(0) === '0'
                        "
                      >
                        {{ 'LABELS.PortalUsers.VALIDATION.MOBILE_NUMBER_PATTERN' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-3"></div>
                  <div class="col-sm-6">
                    <label>
                      <input
                        type="checkbox"
                        name="terms&condition"
                        required
                        [(ngModel)]="isAgreed"
                      />&nbsp;&nbsp;{{
                        'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TERMS_CONDITIONS_ACCEPT'
                          | translate
                      }}</label
                    >&nbsp;<a class="anchor" (click)="showTermsAndConditions()">
                      {{ 'LABELS.REGISTER.INPUT_LABEL.ORGANISATION_TERMS_CONDITIONS' | translate }}
                    </a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-sm-5">
                    <div
                      class="g-recaptcha"
                      data-theme="light"
                      style="
                        transform: scale(1) !important;
                        transform-origin: 0 0 !important;
                        font-size: 13px !important;
                        margin-bottom: 20px;
                      "
                    >
                      <re-captcha
                        [(ngModel)]="registerForm.captcha"
                        name="captcha"
                        required
                        siteKey="6LcHBtIZAAAAABOhAosarmLCINL4JMjGs5RuTAv8"
                        #captchaControl="ngModel"
                        class="re-captcha"
                      >
                      </re-captcha>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9 content-right-align">
                    <button class="btn btn-danger" type="button" (click)="showLogin()">
                      <i class="fa fa-close" aria-hidden="true"></i>
                      {{ 'LABELS.REGISTER.INPUT_LABEL.BUTTONS.CANCEL' | translate }}</button
                    >&nbsp;
                    <button
                      class="btn btn-success"
                      type="submit"
                      (click)="userRegister()"
                      [disabled]="!isAgreed || registerForm.invalid || registrationInProgress"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                      {{ 'LABELS.REGISTER.INPUT_LABEL.BUTTONS.SUBMIT' | translate }}
                    </button>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
