import { Component } from '@angular/core';
import { Router } from '@angular/router';
import LoginService from '../login/services/login-http.service';

@Component({
  templateUrl: '500.component.html',
})
export default class P500Component {
  constructor(
    private loginService: LoginService,
    private router: Router,
  ) {
    // logic goes here
  }

  OnClick() {
    const isUserLoggedIn = LoginService.isUserLoggedIn();
    if (isUserLoggedIn) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['']);
    }
  }
}
