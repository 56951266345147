<div class="nav">
  <div class="nav-header">
    <div class="nav-brand">
      <img src="assets/img/brand/incident_reporter_logo.png" alt="" />
    </div>
  </div>
  <div class="nav-links">
    <a [routerLink]="['/login']">Login</a>
    <a [routerLink]="['/register']">Registration</a>
  </div>
</div>
