import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import EnvService from '../../../env.service';
import { ServerStatusAPI } from '../../../interfaces/global.interface';

@Injectable({
  providedIn: 'root',
})
export default class ServerstatusService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  getServerStatus() {
    const API_URL = `${this.env.apiUrl}/Health`;
    return this.httpClient
      .get(API_URL)
      .map((data: ServerStatusAPI) => data)
      .catch((error) => Observable.throw(error));
  }
}
