<div id="main-wrapper landing-page-container">
  <div id="content" role="main">
    <section
      id="home"
      class="single-slideshow bg-white d-flex fullscreen-with-header"
      data-autoplay="false"
      data-loop="false"
      data-nav="false"
      data-items="1"
    >
      <div class="item w-100">
        <div class="hero-wrap bg-white">
          <div class="hero-mask opacity-1 bg-primary"></div>
          <div
            class="hero-bg hero-bg-mobile"
            style="background-image: url('assets/landing-page/images/bg/IR365_Web_Banner.jpg')"
          ></div>
          <div class="hero-content d-flex fullscreen-with-header">
            <div class="container my-auto text-center">
              <div class="row" no-gutters *ngIf="auth.activeForm === 'login'">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <app-login-component
                    class="text-lg-left"
                    (buyPackage)="onBuyPkg($event)"
                  ></app-login-component>
                </div>
              </div>
              <div class="row" no-gutters *ngIf="auth.activeForm === 'successMessageComponent'">
                <app-success-component class="col col-md-12"></app-success-component>
              </div>
              <div class="row" no-gutters *ngIf="auth.activeForm === 'billingSummaryComponent'">
                <div class="card" style="margin: auto; width: 100%">
                  <div class="card-body p-4">
                    <app-billing-summary-component
                      class="col col-md-12"
                      [priceDetails]="selectedPackagePriceDetails"
                      [isLandingPage]="true"
                    ></app-billing-summary-component>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
