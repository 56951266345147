<div class="heading d-flex justify-content-center" style="text-align: center">
  <h2
    class="d-inline-block text-center color-blue underline-small text-11 font-weight-600 mb-4 padng-top montserrat-bold color-blue"
  >
    {{ 'HOME.MENU.PRICING' | translate }}
  </h2>
  <select
    [(ngModel)]="selectedCurrency"
    name="currency"
    [ngClass]="{ sm: isLandingPage === false }"
    class="currency-selector d-inline-block text-11 font-weight-600 mb-4 ml-3 padng-top"
  >
    <option [value]="'USD'">USD</option>
    <option [value]="'GBP'">GBP</option>
    <option [value]="'INR'">INR</option>
  </select>
</div>
<table class="pricing-table mobile-hidden">
  <tr>
    <td class="discount">
      <!-- <select [(ngModel)]="selectedCurrency" name="currency" [ngClass]="{ 'sm': isLandingPage === false}" class="currency-selector">
        <option [value]="'USD'">USD</option>
        <option [value]="'GBP'">GBP</option>
        <option [value]="'INR'">INR</option>
      </select> -->
      <section *ngIf="couponData.length > 0">
        Subscribe now and get {{ couponData[0].DiscountPercentage }}% discount with coupon code
        <span class="text-bold">{{ couponData[0].CouponCode }}</span>
      </section>
    </td>
    <td style="text-align: center" *ngFor="let pkg of packages">
      <div
        class="montserrat-medium"
        [ngClass]="{
          'package-offer': true,
          'freetrial-pack': pkg.Id === 1,
          'grey-out': isLandingPage === false && currentPackage.PackageId > pkg.Id
        }"
      >
        <div>
          <strong class="montserrat-medium">{{ pkg.PackageName }}</strong>
        </div>
        <div class="price" *ngIf="pkg.PricePerMonthGBP > 0 && selectedCurrency === 'GBP'">
          £{{ pkg.PricePerMonthGBP }}
        </div>
        <div class="price" *ngIf="pkg.PricePerMonthUSD > 0 && selectedCurrency === 'USD'">
          ${{ pkg.PricePerMonthUSD }}
        </div>
        <div class="price" *ngIf="pkg.PricePerMonthINR > 0 && selectedCurrency === 'INR'">
          ₹{{ pkg.PricePerMonthINR }}
        </div>
        <div *ngIf="pkg.PricePerMonthUSD > 0">per month</div>
        <div class="price" *ngIf="pkg.PricePerMonthUSD === 0">Free</div>
        <div *ngIf="pkg.Id === 1">Available for 15 days</div>
      </div>
    </td>
  </tr>
  <tr *ngFor="let row of pricingData">
    <td class="feature-name montserrat">{{ row.featureName }}</td>
    <td
      [ngClass]="{
        'feature-values': true,
        'grey-out': isLandingPage === false && currentPackage.PackageId > package.Id
      }"
      class="feature-values montserrat"
      *ngFor="let package of packages"
    >
      <i *ngIf="row[package.PackageName] === true" class="fa fa-check"></i>
      <i *ngIf="row[package.PackageName] === false" class="fa fa-times"></i>
      <span
        class="montserrat montserrat-regular"
        *ngIf="row[package.PackageName] !== true && row[package.PackageName] !== false"
        >{{ row[package.PackageName] }}</span
      >
    </td>
  </tr>
  <tr *ngIf="isLandingPage === false">
    <td></td>
    <td
      [ngClass]="{ 'free-trial-buy': pkg.Id === 1 }"
      style="text-align: center"
      *ngFor="let pkg of packages"
    >
      <div class="buy-div">
        <div class="buy-pack">
          <select
            [disabled]="isLandingPage === false && currentPackage.PackageId > pkg.Id"
            *ngIf="pkg.Id !== 1"
            class="months-dropdown form-control"
          >
            <option value="month.value" *ngFor="let month of months">{{ month.name }}</option>
          </select>
          <button
            [disabled]="isLandingPage === false && currentPackage.PackageId > pkg.Id"
            *ngIf="pkg.Id !== 1"
            class="btn btn-primary"
            style="width: 70%"
            (click)="onBuyClick($event, pkg)"
          >
            Buy now
          </button>
          <button
            *ngIf="pkg.Id === 1"
            class="btn btn-primary"
            style="width: 70%"
            (click)="onBuyClick($event, pkg)"
          >
            Start free trial
          </button>
        </div>
      </div>
    </td>
  </tr>
</table>

<!-- For Mobile View Only -->

<section class="desktop-hidden">
  <div class="container">
    <div class="row text-center align-items-end montserrat-medium">
      <!-- Pricing Table-->
      <div class="discount">
        <section *ngIf="couponData.length > 0" class="montserrat-medium">
          Subscribe now and get {{ couponData[0].DiscountPercentage }}% discount with coupon code
          <span class="text-bold montserrat-medium">{{ couponData[0].CouponCode }}</span>
        </section>
      </div>
      <div class="col-lg-4 mb-5 mb-lg-0" *ngFor="let pkg of packages">
        <div class="bg-white p-3 rounded-lg shadow montserrat-medium">
          <h1 class="h5 text-uppercase font-weight-bold mb-4 montserrat-medium">
            {{ pkg.PackageName }}
          </h1>
          <h2
            class="h1 font-weight-bold montserrat-medium"
            [ngClass]="{
              'package-offer': true,
              'freetrial-pack': pkg.Id === 1,
              'grey-out': isLandingPage === false && currentPackage.PackageId > pkg.Id
            }"
          >
            <span
              class="price montserrat-medium"
              *ngIf="pkg.PricePerMonthGBP > 0 && selectedCurrency === 'GBP'"
              >£{{ pkg.PricePerMonthGBP }}
            </span>
            <span
              class="price montserrat-medium"
              *ngIf="pkg.PricePerMonthUSD > 0 && selectedCurrency === 'USD'"
              >${{ pkg.PricePerMonthUSD }}
            </span>
            <span
              class="price montserrat-medium"
              *ngIf="pkg.PricePerMonthINR > 0 && selectedCurrency === 'INR'"
              >₹{{ pkg.PricePerMonthINR }}
            </span>
            <span class="text-small font-weight-normal ml-2 montserrat-medium">/ month</span>
            <!-- <div *ngIf="pkg.PricePerMonthUSD > 0">per month</div> -->
            <div class="price montserrat-medium" *ngIf="pkg.PricePerMonthUSD === 0">Free</div>
            <div *ngIf="pkg.Id === 1" class="montserrat-medium">Available for 15 days</div>
          </h2>

          <div class="custom-separator my-4 mx-auto"></div>

          <div class="list-unstyled text-small text-left pb-2 pt-2" *ngFor="let row of pricingData">
            <div
              [ngClass]="{
                'feature-values': true,
                'grey-out':
                  isLandingPage === false && currentPackage.PackageId > package && package.Id
              }"
              class="feature-values text-left montserrat-medium"
            >
              <!-- <i class="fa fa-check mr-2 text-primary"></i> -->
              <i *ngIf="row[pkg.PackageName] === true" class="fa fa-check"></i>
              <i *ngIf="row[pkg.PackageName] === false" class="fa fa-times"></i>
              <span class="montserrat-medium">
                {{ row.featureName }}
              </span>
              <!-- <span class=" font-weight-bold"> {{row[pkg.PackageName]}}</span> -->
              <span
                *ngIf="row[pkg.PackageName] !== true && row[pkg.PackageName] !== false"
                class="font-weight-bold montserrat-medium"
              >
                - {{ row[pkg.PackageName] }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- END -->
    </div>
  </div>
</section>

<div *ngIf="isLandingPage === true" class="text-center mt-5">
  <p class="mb-5 free-trial-text">
    {{ 'HOME.PRICING_TAGS.TRY_FREE' | translate }}&nbsp;
    <button class="btn btn-primary" (click)="startFreeTrial($event, pkg)">
      {{ 'HOME.PRICING_TAGS.START_FREE_TRIAL' | translate }}
    </button>
  </p>

  <p class="free-trial-text">
    {{ 'HOME.PRICING_TAGS.UPGRADE' | translate }}&nbsp;
    <a style="color: #e11c22" href="mailto:sales.uk@osmosys.co">{{
      'HOME.PRICING_TAGS.CONTACT_US' | translate
    }}</a>
  </p>
</div>
