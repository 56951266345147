import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import ConfirmationService from '../confirmation.service';
import BackgroundService from '../modals/send-notifications/background.service';

@Component({
  selector: 'app-confirm-notification',
  templateUrl: './confirm-notification.component.html',
  styleUrls: ['./confirm-notification.component.scss'],
})
export default class ConfirmNotificationComponent implements OnInit {
  notificationData = {
    selectedSite: '',
    NotificationType: '',
    userType: '',
    notifiedUsers: '',
    Description: '',
    ImageUrl: '',
    Attachments: '',
    Link: '',
  };

  public event = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
    private backgroundService: BackgroundService,
    private confirmService: ConfirmationService,
  ) {
    // logic goes here
  }

  ngOnInit(): void {
    this.notificationData = this.confirmService.formData;
  }

  confirm(): void {
    this.onCancel();
    this.event.emit();
  }

  onCancel() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this.backgroundService.setBackgroundBoolean = false;
    }, 400);
  }
}
