import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class ConfirmationService {
  private sendNotificationSource = new Subject<void>();

  sendNotification$ = this.sendNotificationSource.asObservable();

  public formData;
}
