<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'assets/img/brand/incident_reporter_logo.png',
    width: '100%',
    alt: 'Incident Reporter Banner'
  }"
  [navbarBrandMinimized]="{
    src: 'assets/img/brand/incident_reporter_icon.png',
    width: 30,
    height: 30,
    alt: 'Incident Reporter Icon'
  }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
>
  <h1 class="heading-title"></h1>
  <ul class="nav navbar-nav ml-auto">
    <div class="nav-item mr-2">
      <a
        type="button"
        class="nav-link"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <button class="custom-org-btn">
          <i class="cui-globe"></i> <span>
            {{ selectedChangedOrganisation.OrganisationName }}</span>
          <i
            class="fa fa-caret-down"
            aria-hidden="true"
            *ngIf="userOwnedOrganisations.length > 0"
          ></i>
        </button>
      </a>
      <div class="dropdown-menu dropdown-menu-right"
       *ngIf="userOwnedOrganisations.length > 0">
        <div
          class="dropdown-item pointer-cursor"
          *ngFor="let organisation of userOwnedOrganisations"
          (click)="onOrganisationChange(organisation)"
        >
          {{ organisation.OrganisationName }}
        </div>
      </div>
    </div>
    <div class="btn-group nav-item">
      <a
        type="button"
        class="nav-link"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="#" routerLink="/changepassword"
          ><span class="cui-lock-unlocked pd-r-10"></span>
          {{ 'LABELS.CHANGE_PASSWORD.PAGE_TITLE' | translate }}</a
        >
        <a class="dropdown-item" href="#" (click)="logout()"
          ><span class="cui-account-logout pd-r-10"></span> {{ 'HOME.MENU.LOGOUT' | translate }}</a
        >
        <a class="dropdown-item downld" id="dl" download="images.zip" (click)="downloadData()"
          ><span class="fa fa-download pd-r-10"></span>
          {{ 'HOME.MENU.DOWNLOAD-DATA' | translate }}</a
        >
        <a class="dropdown-item" href="#" routerLink="/delete"
          ><span class="fa fa-trash-o pd-r-10"></span>
          {{ 'HOME.MENU.DELETE_ACCOUNT' | translate }}</a
        >
      </div>
    </div>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
    style="background: #3d5b90"
  >
    <app-sidebar-nav [navItems]="navItems" 
    class="app-sidebar-layout"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <ngx-loading-bar color="#6d9cbd" height="2px"></ngx-loading-bar>
      <div class="outlet-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
</div>
<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'RELEASE_NOTES.TITLE' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" [(ngModel)]="tooltip">
    <div [innerHTML]="'RELEASE_NOTES.RELEASE_NOTES' | translate"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>

<app-footer>
  <span class="mx-auto" style="font-size: 12px"
    >&copy; {{ year }} | {{ 'HOME.COPYWRITE.COPYWRITE_TITLE' | translate }}
    <a href="https://osmosys.co/">{{ 'HOME.COPYWRITE.COMPANY_NAME' | translate }}</a></span
  >
  <span style="font-size: 12px; cursor: pointer; margin-right: 63px" (click)="openReleaseHistory()">
    Version:&nbsp;{{ version }}
  </span>
</app-footer>
