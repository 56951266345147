<div class="modal-header">
  <h4 class="modal-title pull-left">
    <span>{{ title | translate }}</span>
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <form #integrationForm="ngForm">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="cui-globe"></i></span>
          </div>
          <select name="Organisation" class="form-control" required [(ngModel)]="organisationId">
            <option [value]="null" disabled selected>
              {{ 'LABELS.INTEGRATIONS.SELECT_ORGANISATION_TYPE' | translate }}
            </option>
            <option *ngFor="let organisation of organisationsList" [value]="organisation.Id">
              {{ organisation.OrganisationName }}
            </option>
          </select>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="cui-globe"></i></span>
          </div>
          <select
            name="integrationType"
            class="form-control"
            required
            [(ngModel)]="integration.IntegrationType"
          >
            <option [value]="null" disabled selected>
              {{ 'LABELS.INTEGRATIONS.SELECT_INTEGRATION_TYPE' | translate }}
            </option>
            <option *ngFor="let integration of integrationTypes" [value]="integration.name">
              {{ integration.name }}
            </option>
          </select>
        </div>
        <section *ngIf="integration.IntegrationType === 'Dynamics'">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-link"></i></span>
            </div>
            <input
              type="text"
              class="form-control"
              name="crmUrl"
              [(ngModel)]="keyValues.crmUrl"
              placeholder="CRM URL"
              required
              #crmUrl="ngModel"
            />
            <div class="alert-danger" *ngIf="crmUrl.invalid && (crmUrl.dirty || crmUrl.touched)">
              <span *ngIf="crmUrl.errors?.required">
                {{ 'LABELS.INTEGRATIONS.FORM_ERRORS.CRM_URL_REQUIRED' | translate }}
              </span>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-user-o"></i></span>
            </div>
            <input
              type="text"
              class="form-control"
              name="crmUserName"
              [(ngModel)]="keyValues.crmUserName"
              placeholder="Username"
              required
              #crmUserName="ngModel"
            />
            <div
              class="alert-danger"
              *ngIf="crmUserName.invalid && (crmUserName.dirty || crmUserName.touched)"
            >
              <span *ngIf="crmUserName.errors?.required">
                {{ 'LABELS.INTEGRATIONS.FORM_ERRORS.CRM_USERNAME_REQUIRED' | translate }}
              </span>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-key"></i></span>
            </div>
            <input
              type="password"
              class="form-control"
              name="crmPassword"
              [(ngModel)]="keyValues.crmPassword"
              placeholder="Password"
              required
              #crmPassword="ngModel"
            />
            <div
              class="alert-danger"
              *ngIf="crmPassword.invalid && (crmPassword.dirty || crmPassword.touched)"
            >
              <span *ngIf="crmPassword.errors?.required">
                {{ 'LABELS.INTEGRATIONS.FORM_ERRORS.CRM_PASSWORD_REQUIRED' | translate }}
              </span>
            </div>
          </div>
        </section>
        <label class="switch-container" *ngIf="currentIntegration">
          <input type="checkbox" name="Status" [(ngModel)]="integration.Status" />&nbsp;&nbsp;{{
            'LABELS.INTEGRATIONS.STATUS' | translate
          }}
          <span class="slider round"></span>
        </label>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 m-b-xs content-right-align">
      <button
        type="submit"
        *ngIf="!currentIntegration"
        class="btn btn-success"
        (click)="createIntegration()"
        [disabled]="integrationForm.invalid"
      >
        {{ 'LABELS.INTEGRATIONS.SUBMIT' | translate }}
      </button>
      <button
        type="submit"
        *ngIf="currentIntegration"
        class="btn btn-success"
        (click)="updateIntegration()"
        [disabled]="integrationForm.invalid"
      >
        {{ 'LABELS.INTEGRATIONS.UPDATE' | translate }}
      </button>
    </div>
  </div>
</div>
