import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import EnvService from '../../env.service';
import { BasicApiModel } from '../../interfaces/global.interface';

interface CountryApiModel extends BasicApiModel {
  Data?: {
    Countries: {
      Id: 1, CountryName: string, CountryCode: string, PhoneCode: string
    }[]
  }
}
@Injectable({
  providedIn: 'root',
})
export default class SharedService {
  currentUser;

  constructor(
    private httpClient: HttpClient,
    private env: EnvService,
  ) {
    // logic goes here
  }

  private organisationUpdateSubject = new Subject();

  organisationUpdated() {
    this.organisationUpdateSubject.next();
  }

  getOrganisationChange() {
    return this.organisationUpdateSubject.asObservable();
  }

  getCountries() {
    const API_URL = `${this.env.apiUrl}/Countries`;
    return this.httpClient
      .get(API_URL)
      .map((data: CountryApiModel) => data)
      .catch((error: CountryApiModel) => Observable.throw(error));
  }
}
