export default class Feature {
  public Id: number;

  public FeatureName?: string;

  public FeatureThreshold?: string;

  public Enabled: boolean;

  get IsQuantitative(): boolean {
    return this.FeatureThreshold !== undefined;
  }
}
