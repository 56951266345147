export default class User {
  public Email?: string;

  public Password?: string;

  public Country?: string;

  public Slot?: string;

  constructor() {
    this.Email = '';
    this.Password = '';
    this.Country = '';
    this.Slot = '';
  }
}
